import KatPhoto from "../assets/images/creators/kat-portrait.jpg";
import JordanaPhoto from "../assets/images/creators/jordana-portrait.jpg";
import ShannonPhoto from "../assets/images/creators/shannon-portrait.jpg";
import TaisiaPhoto from "../assets/images/creators/tk-portrait.JPG"

export const creatorsData = {
    0: {
        name: "Jordana Siegel",
        role: "The Business Side",
        summary: "Jordana grew up playing competitive rounds of Boggle with her grandparents and mother, and will never forget when her grandfather usurped her victory with the word “Milt”(which means frog sperm) during one epic game. Her life, and her family’s lives, were changed when she learned how to play LetterHeist during the Covid pandemic. ",
        photo: JordanaPhoto,
        linkedIn: "https://www.linkedin.com/in/jordanasiegel/",
        email: "jordanamsiegel@gmail.com",
        personalWebsite: null
    },
    1: {
        name: "Shannon Hong",
        role: "The Business Side",
        summary: "Shannon honed her board game prowess in games of familial Monopoly. Fast forward to high school, Shannon played card games almost every single day after school, and she can take you in everything from Bridge to Mahjong, from Tractor to Texas Hold’em. It wasn’t until she lived with Jordana that she was inducted into the literary land of Letterheist.",
        photo: ShannonPhoto,
        linkedIn: "https://www.linkedin.com/in/shannonyhong/",
        email: "shannonyrshong@gmail.com",
        personalWebsite: "https://www.shannonhong.com"
    },
    2: {
        name: "Kat Vu",
        role: "Frontend Engineer & Designer",
        summary: "You can find Kat playing all types of games, from online games to physical board games. She enjoys the competitiveness aspect of all games and learning new things in general. Playing LetterHeist not only challenged her quick thinking abilities but also provided an opportunity to learn new and (interesting…) words from her friends! ",
        photo: KatPhoto,
        linkedIn: "https://www.linkedin.com/in/kat-vu-57b50411b/",
        email: "vu.kathy98@gmail.com",
        personalWebsite: "https://katpvu.github.io/"
    },
    3: {
        name: "Taisia Karaseva",
        role: "Backend Engineer",
        summary: "Taisia grew up playing soviet card games and dominoes. Any bike trip to the lake always included a deck of cards, and her grandmother’s house always had a table set up with wicker chairs, games, and roasted sunflower seeds. When she first played Letterheist, she got thoroughly defeated but loved the challenge, skill, and speed. She wanted to bring the thrill to more people!",
        photo: TaisiaPhoto,
        linkedIn: "https://www.linkedin.com/in/taisiakaraseva/",
        email: "taisiat@gmail.com",
        personalWebsite: "https://www.taisiat.com/"
    },
}

export const faqData = {
    0: {
        question: "What happens when two people claim the same word?",
        answer: "Fastest word wins."
    },
    1: {
        question: "What happens when two people claim different words?",
        answer: "Longest word wins."
    },
    2: {
        question: "What happens when two people claim the same word?",
        answer: "That word is off limits until another word is claimed, at which point, that word can be said again"
    },
    3: {
        question: "Can I play LetterHeist on my phone?",
        answer: "Currently, LetterHeist is only available to play on desktop, but we are working on developing the mobile version soon! "
    },
    4: {
        question: "Can I add my own words to the dictionary?",
        answer: "At this time, you cannot customize words in the game’s settings. Special supporters of LetterHeist on Kickstarter added their own Easter Egg words that have become a part of the LetterHeist dictionary! *Easter Egg Challenge: Find the hidden words on our website to use and surprise your opponents! "
    },
    5: {
        question: "How did the Letterheist team determine what words are counted as legitimate in the game?",
        answer: "We used the Merriam-Webster Dictionary for which words count in the game. Special supporters of LetterHeist on Kickstarter added their own Easter Egg words that have become a part of the LetterHeist dictionary! There may be words contained in the dictionary that some may find offensive or inappropriate. Please set guidelines with your friends before playing, based on your preferences."
    },
    6: {
        question: "Can I remove any words from the game?",
        answer: "At this time, no. There may be words contained in the dictionary that some may find offensive or inappropriate. Please discuss guidelines with your friends before playing, based on your preferences."
    }
}

// export const backersData = [
//     "Ruth-Anne Siegel",
//     "Justin",
//     "Gary",
//     "Kobi Weinberg",
//     "Maggie",
//     "Nick Platoff",
//     "Jen",
//     "Kyle Guzik",
//     "Shay Kothari",
//     "Jake Martin",
//     "Jen Dat Pham",
//     "Danielle Mitgang",
//     "Savannah Bossert",
//     "Mike Bird",
//     "Jenn",
//     "Hongbo Tian",
//     "Ariele",
//     "Ziqi Zou",
//     "Isabel Callahan",
//     "Erika Siao",
//     "Andrew Alexander",
//     "Vick Liu",
//     "Jeff Anders",
//     "Danny DeBare",
//     "Devin Kim",
//     "Aashika Balaji",
//     "Christine Pham",
//     "Ancilla Bay",
//     "Hayley Krolik",
//     "May Cui",
//     "Courtney Jeung",
//     "Kazem Kutob",
//     "David",
//     "Jared Stolove",
//     "Carly Valancy",
//     "Gregory Hosono",
//     "Alicia",
//     "Eric Johnson",
//     "Tori Quinter",
//     "Tyler Shibata",
//     "Mike Danello",
//     "Ted Catlin",
//     "Pia",
//     "Maia Rosenfeld",
//   "Maya Matthews",
//   "Yash Vardhan Goenka",
//   "j",
//   "Noah",
//   "Sai Atmakuri",
//   "Caroline Gao",
//   "Sam",
//   "Aarti",
//   "Oliver Hsu",
//   "Anna Pasnau",
//   "Steven Hao",
//   "Jenna",
//   "Elena",
//   "Rebecca",
//   "Annie",
//   "Duncan McManus",
//   "Nicolas Granato",
//   "Sadie Dorf",
//   "Sritej",
// ]

export const backersData = [
    { name: 'Ruth-Anne Siegel', easterEgg: "ergy" },
    { name: 'Justin', easterEgg: null },
    { name: 'Gary', easterEgg: null },
    { name: 'Kobi Weinberg', easterEgg: null },
    { name: 'Maggie', easterEgg: null },
    { name: 'Nick Platoff', easterEgg: "spleeyah" },
    { name: 'Jen', easterEgg: null },
    { name: 'Kyle Guzik', easterEgg: null },
    { name: 'Shay Kothari', easterEgg: null },
    { name: 'Jake Martin', easterEgg: null },
    { name: 'Jen Dat Pham', easterEgg: null },
    { name: 'Danielle Mitgang', easterEgg: null },
    { name: 'Savannah Bossert', easterEgg: null },
    { name: 'Mike Bird', easterEgg: "figvapor" },
    { name: 'Jenn', easterEgg: null },
    { name: 'Hongbo Tian', easterEgg: null },
    { name: 'Ariele', easterEgg: null },
    { name: 'Ziqi Zou', easterEgg: "grubglobe" },
    { name: 'Isabel Callahan', easterEgg: null },
    { name: 'Erika Siao', easterEgg: null },
    { name: 'Andrew Alexander', easterEgg: null },
    { name: 'Vick Liu', easterEgg: null },
    { name: 'Jeff Anders', easterEgg: null },
    { name: 'Danny DeBare', easterEgg: null },
    { name: 'Devin Kim', easterEgg: null },
    { name: 'Aashika Balaji', easterEgg: null },
    { name: 'Christine Pham', easterEgg: null },
    { name: 'Ancilla Bay', easterEgg: null },
    { name: 'Hayley Krolik', easterEgg: null },
    { name: 'May Cui', easterEgg: null },
    { name: 'Courtney Jeung', easterEgg: null },
    { name: 'Kazem Kutob', easterEgg: null },
    { name: 'David', easterEgg: null },
    { name: 'Jared Stolove', easterEgg: null },
    { name: 'Carly Valancy', easterEgg: null },
    { name: 'Gregory Hosono', easterEgg: null },
    { name: 'Alicia', easterEgg: null },
    { name: 'Eric Johnson', easterEgg: null },
    { name: 'Tori Quinter', easterEgg: null },
    { name: 'Tyler Shibata', easterEgg: null },
    { name: 'Mike Danello', easterEgg: null },
    { name: 'Ted Catlin', easterEgg: null },
    { name: 'Pia', easterEgg: null },
    { name: 'Maia Rosenfeld', easterEgg: null },
    { name: 'Maya Matthews', easterEgg: null },
    { name: 'Yash Vardhan Goenka', easterEgg: null },
    { name: 'j', easterEgg: null },
    { name: 'Noah', easterEgg: null },
    { name: 'Sai Atmakuri', easterEgg: null },
    { name: 'Caroline Gao', easterEgg: null },
    { name: 'Sam', easterEgg: null },
    { name: 'Aarti', easterEgg: null },
    { name: 'Oliver Hsu', easterEgg: null },
    { name: 'Anna Pasnau', easterEgg: null },
    { name: 'Steven Hao', easterEgg: null },
    { name: 'Jenna', easterEgg: null },
    { name: 'Elena', easterEgg: null },
    { name: 'Rebecca', easterEgg: null },
    { name: 'Annie', easterEgg: null },
    { name: 'Duncan McManus', easterEgg: null },
    { name: 'Nicolas Granato', easterEgg: null },
    { name: 'Sadie Dorf', easterEgg: null },
    { name: 'Sritej', easterEgg: null }
]

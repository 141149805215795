import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden; // needed in order to show active item only
    width: 80%;
    margin: 0 auto;
    // display: flex;
    // gap: 10px;
    // align-items: center;
    // justify-content: center;
`

export const Item = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    height: auto;
    // background-color: green;
    // color: white;
`

export const Inner = styled.div`
    white-space: nowrap;
    transition: transform 0.3s;
`

export const StyledArrowButton = styled.div`
    cursor: pointer;
`
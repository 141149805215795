import { useSelector } from "react-redux";
import { GoXCircle, GoSignOut } from "react-icons/go";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import ToggleButton from "../../ToggleButton";
import { useSettingsContext } from "../../../contexts/GameSettingsContext";
import { socketLeaveRoomGame } from "../../../lib/socket/socketConnection";
import { getRoomCode } from "../../../store/room";
import { 
    Container, 
    HeaderContainer, 
    Button, 
    Subheader, 
    Title, 
    GameConfigOptions, 
    GameConfigItem,
    KeyCap,
    ShortcutDescription,
    KeyboardShortcutsItem,
    KeyboardShortcutsContainer,
    KeyboardShortcutsItemContainer
 } from "./StyledSettings";
import { getGameStatus } from "../../../store/game";
import RetroWindowA from "../../Retro/RetroWindowA/RetroWindowA";

export default function GameSettingsModal({onClose}) {
    const gameStatus = useSelector(getGameStatus)
    return (
        <RetroWindowA content={{
            title: "",
            content: 
                <Container>
                    <HeaderContainer>
                        <Title>Settings</Title>
                        {/* <ExitIcon onClick={onClose}><GoXCircle /></ExitIcon> */}
                    </HeaderContainer>
                    <GameSettingsModal.KeyboardShortcutsLegend />
                    <GameSettingsModal.GameConfig />
                    
                    { gameStatus === "playing" && <GameSettingsModal.LeaveGameButton closeModal={onClose} />}
                </Container>
        }}
        styleOptions={{
            "dot1": "#F0F262",
            "dot2": "#F5F6C6",
            "titleBackground": "#A7CFCF",
            "contentBackground": "#D4FAFA"
        }}
        />
    );
};

GameSettingsModal.KeyboardShortcutsLegend = function KeyboardShortcutsLegend () {
    const shortcuts = [
        {
            keyBind: "shift",
            description: "Add new letter"
        },
        {
            keyBind: "esc",
            description: "Cancel steal/upgrade select"
        }
    ]
    return (
        <>
            <KeyboardShortcutsContainer>
                <Subheader>keyboard shortcuts</Subheader>
                <KeyboardShortcutsItemContainer>
                    { shortcuts.map((shortcut, i) => (
                        <KeyboardShortcutsItem key={i}>
                            <KeyCap>{shortcut.keyBind}</KeyCap>
                            <ShortcutDescription>{shortcut.description}</ShortcutDescription>
                        </KeyboardShortcutsItem>
                    ))}
                </KeyboardShortcutsItemContainer>
            </KeyboardShortcutsContainer>
        </>

    )
}

GameSettingsModal.GameConfig = function GameConfigurations() {
    // const [ toggleDefinitions, setToggleDefinitions ] = useState(true);
    const { showDefinitions, setShowDefinitions } = useSettingsContext();

    const handleToggleDefinitions = () => {
        setShowDefinitions(prev => !prev)
    };

    return (
        <GameConfigOptions>
            <Subheader>in-game configurations</Subheader>
            <GameConfigItem>
                <p>definitions</p>
                <ToggleButton show={showDefinitions} onClick={handleToggleDefinitions} />
            </GameConfigItem>
            {/* <GameConfigItem>opt2</GameConfigItem>
            <GameConfigItem>opt3</GameConfigItem> */}
        </GameConfigOptions>
    );
};

GameSettingsModal.LeaveGameButton = function LeaveGameButton({closeModal}) {
    const { roomCode: roomGameCode } = useParams(); 
    const roomCode = useSelector(getRoomCode);
      
    const handleLeaveRoomGame = () => {
        socketLeaveRoomGame({ 
            room_code: roomCode,
            room_game_code: roomGameCode
        });
        closeModal();
    };

    return (
        <Button onClick={handleLeaveRoomGame}><GoSignOut /> Leave Game</Button>
    );
};

// {/* <ThemeContainer>
//                 <Subheader>Themes</Subheader>
//                 <div style={{display: "flex", gap: "8px", width: "100%"}}>
//                     <ThemeItem></ThemeItem>
//                     <ThemeItem></ThemeItem>
//                     <ThemeItem></ThemeItem>
//                 </div>
//             </ThemeContainer> */}
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { createStructuredSelector } from "reselect";

import { Container, LogItemContainer } from "./styles/StyledLogs";
import { ChatForm, ChatMessage } from "./Chat";
import { createGameNotification } from "./GameNotifications";
import { createRoomNotification } from "./RoomNotifications";
import { selectRoomPlayers } from "../../store/room";
import { getLogs, restoreLogs } from "../../store/logs";
import GameSummaryNotification from "./GameSummaryNotification";

export default function Logs() {
  const dispatch = useDispatch();
  const { logs, players } = useSelector(createStructuredSelector({
    logs: getLogs,
    players: selectRoomPlayers
  }));

  useEffect(() => {
    dispatch(restoreLogs())
  }, [dispatch])

  const createLog = (log, players) => {
    switch (log.logType) {
      case "game_notification":
        return createGameNotification(log, players);
      case "chat_message":
        return ChatMessage(log, players); 
      case "room_notification":
        return createRoomNotification(log, players);
      case "game_summary_notification_type":
        return GameSummaryNotification(log);
      // break;
      default:
        return null;
    }
  };

  return (
    <Container>
      <LogItemContainer>
        {logs.length &&
          logs.map((log, idx) => <div key={idx}>{createLog(log, players)}</div>)}
        <AlwaysScrollToBottom />
      </LogItemContainer>
      <ChatForm />
    </Container>
  );
}

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};



// Resets for every new room

// Storage: localStorage, Array of components

// Chat
// Message

// Game Notifications
// Successful steal
// Failed steal attempt
// Successful new word
// Failed word attempt
// Successfully upgraded own word
// Failed upgrade attempt
// No more words left

// Room Notifications
// Player joined room
// Player left room
// Player disconnected (still in room)
// Player reconnected

import styled from 'styled-components';

const defaultStyleOptions = {
    "box1": "#FFFFFF",
    "box2": "#FFFFFF",
    "titleBackground": "#E3D0E4",
    "contentBackground": "#EEDEDE"
}

const defaultContent = {
    title: "title",
    content: "content"
}

export default function RetroWindowB ({ 
    content={ ...defaultContent },
    styleOptions={ ...defaultStyleOptions }
}) {
    return (
        <Container style={{backgroundColor: `${styleOptions["contentBackground"]}`}}>
            <RetroWindowB.Title title={ content.title } styleOptions={ styleOptions }/>
            <RetroWindowB.Content content={ content.content }/>
        </Container>
    );
};

RetroWindowB.Title = function RetroWindowATitle({ title, styleOptions }) {
    return (
        <TitleContainer style={{backgroundColor: `${styleOptions["titleBackground"]}`}}>
            <DotsContainer>
                <Dot style={{backgroundColor: `${styleOptions["box1"]}`}}/>
                <Dot style={{backgroundColor: `${styleOptions["box2"]}`}}/>
            </DotsContainer>
            { title }
        </TitleContainer>
    )
}

RetroWindowB.Content = function RetroWindowAContent({ content }) {
    return (
        <>
        { content }
        </>
    );
};

const Container = styled.div`
    width: 100%;
    min-width: 320px;
    // background-color: ${props => props.backgroundColor};
    border: 3px solid black;
    border-radius: 24px;
`

const TitleContainer = styled.div`
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    border-radius: 21px 21px 0px 0px;
    border-bottom: 3px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    // background-color: ${props => props.backgroundColor};
`

const DotsContainer = styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    top: 15px;
    right: 16px;
`

const Dot = styled.div`
    border-radius: 8px;
    width: 27px;
    height: 27px;
    border: 3px solid black;
    // background-color: ${props => props.backgroundColor};
`



import { useState } from "react"
import { Container, TabBarOptions, StyledTabBarOptionsItem } from "./StyledTabBar";

export default function TabBar ({ options }) {
    // options = { option1: content<component> }
    const availableOptions = Object.keys(options);
    const [ selectedOption, setSelectedOption ] = useState(availableOptions[0]);
    // console.log(options)
    return (
        <Container>
            <TabBar.DisplayOptions 
                availableOptions={availableOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            <TabBar.DisplayContent 
                selectedOption={selectedOption} 
                options={options}
            />
        </Container>
    );
};

TabBar.DisplayOptions = function TabBarDisplayOptions ({
    availableOptions,
    selectedOption,
    setSelectedOption
}) {
    // console.log(setSelectedOption)
    const isSelected = (currentOption) => {
        return selectedOption === currentOption
    }
    return (
        <TabBarOptions>
            {availableOptions.map((optionKey, i) => (
                <StyledTabBarOptionsItem 
                    key={i}
                    // currentOption={optionKey}
                    // selectedOption={selectedOption}
                    $isSelected={isSelected(optionKey)}
                    onClick={() => setSelectedOption(optionKey)}
                >
                    {optionKey}
                </StyledTabBarOptionsItem>
            ))}
        </TabBarOptions>
    );
};

TabBar.DisplayContent = function TabBarDisplayContent ({ options, selectedOption }) {
    return (
        <>
            {options[selectedOption]}
        </>
    );
};
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { login, register } from '../../store/session';
import { errorMessage } from '../../utils/errorsUtil';
import styled from 'styled-components';

const AuthForm = ({type, handleSwitchAuthType}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);

    const handleAuthSubmit = (e) => {
        e.preventDefault();
        const userDetails = {
            'display_name': username,
            password
        };

        if (type === 'login') {
            // console.log('logging in: ', userDetails);
            dispatch(login(userDetails))
                .then((res) => {
                    // console.log([res])
                    if (res) {
                        setErrors([res]);
                    } else {
                        history.push('/join-room');
                    };
                });
        } else {
            // console.log('registering: ', userDetails);
            dispatch(register(userDetails))
                .then((res) => {
                    if (res) {
                        setErrors([res]);
                    } else {
                        history.push('/join-room');
                    };
                });
        };
    };



    return (
    <>
    <LoginForm onSubmit={handleAuthSubmit}  >
        <label>username</label>
        <input
            type="text" 
            required
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            placeholder="enter username" />
        <label>password</label>
        <input
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="enter password"/>
        <SubmitButton>{type === "login" ? "login" : "register"}</SubmitButton>
        <div style={{
                    fontSize: "16px", 
                    textDecoration: "underline", 
                    padding: "16px", 
                    cursor: "pointer"}}
                    onClick={() => handleSwitchAuthType()}
                    >
                        { type === "login" ? "create an account" : "login with my account" }
                </div>
    </LoginForm>
    {errors && errorMessage(errors)}
    </>
    )
};

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-top: 1px solid #A59B9B;
    margin-top: 16px;
    padding-top: 16px;


    input {
        width: 90%;
        padding: 0.5em 1em;
        border-radius: 12px;
        margin-bottom: 12px;
        margin-top: 4px;
        transition: box-shadow 0.3s ease;
        background-color: #E4DCDC;
        font-size: 0.7em;
    }

    input:focus {
        outline: none;
        box-shadow: 0 0 0 3px #979090;
    }

    input::placeholder {
        color: #A59B9B;
    }

    @media (max-width: 500px) {
        width: 95%;
    }
`
const SubmitButton = styled.button`
    border-radius: 12px;
    background-color: #55A659;
    text-transform: lowercase;
    text-align: center;
    padding: 0.5em 1em;
    font-size: 0.7em;
    width: 90%;
    cursor: pointer;
    transition: 0.3s all ease;

    &:hover {
        background-color: #84D98C;
    }
`

export default AuthForm;
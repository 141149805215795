import { createContext, useEffect, useState } from "react";
import { 
    Container,
    Left,
    Right
} from "./styles/StyledGameContent.js"
import PlayerList from "./PlayerList";
import WordBank from "./WordBank";
import LetterBank from "./LetterBank";
import WordSteal from "./WordSteal";
import WordEntry from "./WordEntry";
import Logs from "../../Logs/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getGamePlayers, restoreRoomGame } from "../../../store/game.js";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { socketGetGameState, socketReconnectToRoomGame } from "../../../lib/socket/socketConnection.js";
import { getRoomCode } from "../../../store/room.js";
import { createStructuredSelector } from "reselect";

export const ToggleWordBankContext = createContext();

export default function GameContent({ isSpectator}) {
    const dispatch = useDispatch();
    const { roomCode: roomGameCode } = useParams();
    const {roomCode, players} = useSelector(createStructuredSelector({
        roomCode: getRoomCode,
        players: getGamePlayers,
    }))

    // set up filter functionality
    const [wordBankFilter, setWordBankFilter] = useState({displayName: "view all"});
    const [steal, setSteal] = useState("");


    useEffect(() => {
        dispatch(restoreRoomGame()) 
            .then((res) => {
                if (res && res.roomGameCode) {
                    socketGetGameState({
                        room_game_code: res.roomGameCode
                    })
                    socketReconnectToRoomGame({
                        room_game_code: res.roomGameCode
                    })
                } else {
                    <Redirect to={`room/${roomCode}`} />
                }
            })
    }, [dispatch, roomCode])

    return (
        <Container>
            <ToggleWordBankContext.Provider value={{ wordBankFilter, setWordBankFilter }}>
                <PlayerList players={Object.values(players)}/>
                <div style={{display: "flex", gap: "8px", flexGrow: 1}}>
                    <Left>
                        <WordBank setSteal={setSteal} steal={steal} isSpectator={isSpectator} players={Object.values(players)}/>
                        <LetterBank roomGameCode={roomGameCode}/>
                        <WordSteal steal={steal}/>
                        <WordEntry steal={steal} setSteal={setSteal} roomGameCode={roomGameCode} isSpectator={isSpectator}/>
                    </Left>
                    <Right>
                        <Logs />
                    </Right>
                </div>
            </ToggleWordBankContext.Provider>
        </Container>
    )
}

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import FinalScoreResults from "../GameContent/FinalScoreResults";
import Logs from "../../Logs";
import { Container, Left, Right } from "./StyledGameResults";
import { restoreRoom } from "../../../store/room";

const GameResults = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(restoreRoom())
    })

    return (
        <Container>
            <div style={{display: "flex", gap: "20px", height: "100%"}}>
                    <Left> 
                        <FinalScoreResults />
                    </Left>
                    <Right>
                        <Logs />
                    </Right>
                </div>
        </Container>
    )
}

export default GameResults;
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    // border: 1px solid red;
`

const RetroTabContainer = ({className, children, ...restProps}) => {
    return (
        <div className={className} {...restProps}>
            {children}
        </div>
    )
}

export const TabContainer = styled(RetroTabContainer)`
    display: flex;
`

const RetroTabItem = ({className, children, ...restProps}) => {
    return (
        <div className={className} {...restProps}>
            {children}
        </div>
    )
}

export const TabItem = styled(RetroTabItem)`
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 24px 24px 0px 0px;
    padding: 4px 16px;
    border-top: 2px solid ${props => props.$borderColor};
    border-left: 2px solid ${props => props.$borderColor};
    border-right: 2px solid ${props => props.$borderColor};
    color: ${props => props.$inactiveTabTextColor};
    translate: 0px 2px;
    cursor: pointer;
    background-color: ${props => props.$inactiveTabBgColor};
    border-bottom: 2px solid ${props => props.$borderColor};
    
    ${props => props.$activeTab && css`
        color: ${props.$activeTabTextColor};
        cursor: default;
        background-color: ${props.$activeTabBgColor};
        border-bottom: none;
    `}
`

const RetroNavContent = ({ className, children }) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export const ContentContainer = styled(RetroNavContent)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: 100%;

    border: 2px solid ${props => props.borderColor};

    flex-grow: 1;
    border-radius: 0px 0px 40px 40px;
    
    background-color: ${props => props.contentBgColor};
`

export const ContentHeader = styled.div`
    width: 100%;
    border-radius: 24px;
    border: 2px solid black;
    padding: 4px 16px;
    background-color: #FFF8F3;
    font-size: 1.3em;

`
export const Content = styled.div`
    border-radius: 24px;
    width: 100%;
    background-color: #FFF8F3;
    flex-grow: 1;
    border: 2px solid black;
    padding: 8px 16px;
`

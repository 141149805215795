import { useEffect } from "react"

export const useKeyboardShortcut = (keyEventHandler, keyCodes) => {

    const handler = (e) => {
        // console.log(keyCodes)
        // console.log("document key event", e)
        if (keyCodes.includes(e.code)) {
            keyEventHandler();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handler);

        return () => {
            document.removeEventListener("keydown", handler);
        }
    })

}


import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk'
import sessionReducer from "./session";
import gameReducer from "./game";
import roomReducer from "./room";
import errorsReducer from "./errors";
import logsReducer from "./logs";

export const rootReducer = combineReducers({
    session: sessionReducer,
    game: gameReducer,
    room: roomReducer,
    logs: logsReducer,
    errors: errorsReducer
});

// ENHANCER
let enhancer;

// in production, enhancer should only apply thunk middleware
if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
} else {
    // add logger middleware and Redux DevTools compose enhancer
    const logger = require('redux-logger').default;
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
};

// CONFIGURE STORE 
const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
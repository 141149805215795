import { useContext } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  CurrentTurnBorder,
  Item,
} from "./styles/StyledPlayerList.js";
import { ToggleWordBankContext } from "./index.js";
import { PlayerStyles } from "../../../data/styleTypes.js";

PlayerList.Item = function PlayerListItem({ player, ...restProps }) {
  // console.log(player)
  const { wordBankFilter, setWordBankFilter } = useContext(
    ToggleWordBankContext
  );
  const currentPlayerTurn = useSelector((state) => state.game.currentPlayerId);

  const selected = () => {
    return wordBankFilter.id === player.id;
  };

  const currentTurn = () => {
    return currentPlayerTurn === player.id;
  };

  return (
    <CurrentTurnBorder $currentTurn={currentTurn()}>
      <Item
        {...restProps}
        onClick={() => setWordBankFilter(player)}
        selected={selected()}
      >
        {player.style && 
          <div className="player-list_avatar" >
            <img src={PlayerStyles[player.style]["sm-icon"]} alt="player avatar" />
          </div>
        }
        <p>{player.displayName}</p>
      </Item>
    </CurrentTurnBorder>
  );
};

export default function PlayerList({ players, children, ...restProps }) {
  // const players = useSelector((state) =>
  //   state.game.players ? Object.values(state.game.players) : []
  // );

  return (
    <Container>
      <PlayerList.Item
        key={"view all"}
        color={"view-all"}
        player={{ displayName: "view all" }}
      >
        view all
      </PlayerList.Item>

      {/* {players.map((player, index) => (
        <PlayerList.Item key={index} color={player.color} player={player} />
      ))}
    </Container>
  );
} */}
      {players.map((player, index) => (
        <PlayerList.Item key={index} color={player.style} player={player} />
      ))}
    </Container>
  );
}

import styled, { css } from "styled-components";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Home, Users, HelpCircle, BookOpen, Linkedin, Mail, Globe } from 'react-feather';
import 'animate.css';
import { Tooltip } from 'react-tooltip'

import RetroWindowA from "../../components/Retro/RetroWindowA/RetroWindowA";
import RetroWindowB from "../../components/Retro/RetroWindowB/RetroWindowB";
import previewImage1 from "../../assets/images/previews/preview-game.png";
import previewImage2 from "../../assets/images/previews/preview-lobby.png";
import { RxDiscordLogo } from "react-icons/rx";
import { allAvatars } from "../../data/avatars";
import GameInstructions from "../../components/GameInstructions";
import { creatorsData, faqData, backersData } from "../../data/splashData";
import Octopus from "../../assets/images/avatar-icons/animals/octopus.png"
import GroupPic from "../../assets/images/creators/group.jpg"
import "./Splash.css";
import "../../styles/TextStyles.css"
import FixedFeedbackLink from "../../components/FixedFeedbackLink";
import Logo from "../../components/generalComponents/Logo";
import useWindowDimensions from "../../hooks/useWindowDimensions"
import HamburgerSideNavigation from "../../components/Navigation/HamburgerSideNagivation";
import PreviewGallery from "./components/PreviewGallery";
import CreatorsPanel from "./components/CreatorsPanel";
import Footer from "../../components/Footer";

const Splash = () => {
    const history = useHistory();
    const { width, height } = useWindowDimensions();
    const [ activeTab, setActiveTab ] = useState(0);

    const handleClickPlayGame = (e, type="playWithAccount") => {
        e.preventDefault();
        return history.push("/login");
    }

    const tabs = {
        0: {
            icon: <Home size={ width < 520 ? 24 : 36}/>, 
            name: "home",
            title: "welcome",
            content: <Splash.HomeSection handleClickPlayGame={handleClickPlayGame} width={width}/>
            }, 
        1: {
            icon: <BookOpen size={width < 520 ? 24 : 36} />,
            name: "gameplay",
            title: "How To Play",
            content: <Splash.GameInstructionsSection />,
            styleOptions: {
                "dot1": "#F248D7",
                "dot2": "#F4AD6B",
                "titleBackground": "#B6D5B8",
                "contentBackground": "#FDF6CF"}
            },
            2: {
            icon: <Users size={width < 520 ? 24 : 36} />, 
            name: "creators",
            title: "Meet the creators",
            content: <Splash.CreatorsSection />,
            styleOptions: {
                "dot1": "#46DB43",
                "dot2": "#F46BDE",
                "titleBackground": "#D5C4FA",
                "contentBackground": "#C3E6FF"}
        },
        3: {
            icon: <HelpCircle size={width < 520 ? 24 : 36} />,
            name: "faq",
            title: "FAQ",
            content: <Splash.FaqSection />,
            styleOptions: {
                "dot1": "#48F2D4",
                "dot2": "#F46B6B",
                "titleBackground": "#74C3FC",
                "contentBackground": "#FBE1E1"}
        }
    }
    return (
        <div id="splash-wrapper" className="">
            <StyledHeader>
                <Splash.Logo />
                <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
                    {width > 1050 && <Splash.Nav tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>}
                    <Splash.PlayNowButton handleClick={handleClickPlayGame}/>
                    {width <= 1050 && <HamburgerSideNavigation tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>}
                </div>
                {/* {width <= 1050 && <Splash.Nav tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>} */}
            </StyledHeader>
            <div style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px"}}>
            <RetroWindowA 
                content={{
                    title: tabs[activeTab].title, 
                    content: tabs[activeTab].content}} 
                styleOptions={tabs[activeTab]?.styleOptions}
            />
            </div>
            <Footer />
            <FixedFeedbackLink />
        </div>
    );
};

Splash.Header = function SplashHeader ({children, ...restProps}) {
    return (
        <StyledHeader>{children}</StyledHeader>
    )
}

const StyledHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
`

Splash.Logo = function SplashLogo () {
    return (
        <Logo disabled={true}/>
    )
}


Splash.Nav = function SplashNav ({ tabs, activeTab, setActiveTab }) {
    return (
        <StyledNavBar>
            {Object.values(tabs).map((tab, i) => (
                <StyledNavBarItem 
                    onClick={() => setActiveTab(i)} 
                    key={i} 
                    id={i}
                    $activeTab={activeTab === i}>
                        <div>{tab.icon}</div>
                        <div>{tab.name}</div>
                </StyledNavBarItem>
            ))}
        </StyledNavBar>
    )
}

const StyledNavBar = styled.div`
    display: flex;
    gap: 4px;

    @media (max-width: 1050px) {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px;
        width: 100%;
        justify-content: center;
        background-color: rgba(164, 190, 164, 0.9);
        z-index: 50;
    }
`

const StyledNavBarItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    ${ props => props.$activeTab && css`
        background-color: #D6D6CC;
    `}
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: rgba(214, 214, 204, 0.46);
    }
`

Splash.PlayNowButton = function SplashPlayNowButton ({handleClick}) {
    return (
        <StyledPlayNowButton onClick={handleClick}>Play now</StyledPlayNowButton>
    )
}

const StyledPlayNowButton = styled.div`
    width: max-content;
    padding: 8px 20px;
    border-radius: 8px;
    border: 2px solid black;
    text-transform: uppercase;
    font-size: 1.3em;
    cursor: pointer;

    @media (max-width: 520px) {
        font-size: 1em;
        padding: 0.4em 0.7em;
    }
`

Splash.HomeSection = function SplashHomeSection ({handleClickPlayGame, width}) {

    return (
        <HomeContainer>
            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "24px", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                <Mascots>
                    {
                        width > 700 ? 
                        allAvatars.map((avatar, i) => (
                        <div key={i} style={{width: "80%", height: "100%", maxWidth: "130px"} } >
                            <img src={avatar} alt="letter heist mascot" />
                        </div>
                        ))
                        : 
                        <div style={{width: "80%", maxWidth: "200px"}}>
                            <img src={Octopus} alt="letter heist mascot" />
                        </div>
                    }
                </Mascots>
                <h4>Face-off with your friends in an online battle of vocabulary prowess. Create and steal words to show your unmatched skills.</h4>
                <p>LetterHeist comes from a long history of word making and taking, dating back to the 1800s. LetterHeist is often played with a variation of Scrabble tiles or Bananagrams tiles. We are proud to make a game with this illustrious history of physical play. </p>
                <FunPlayNowButton onClick={handleClickPlayGame}>play now</FunPlayNowButton>
                
                <a href="https://discord.gg/hnnK8Ha29g" target="_blank" rel="noopener noreferrer" > <RxDiscordLogo size={"2em"}/> Join our Discord!</a>
                {/* <PreviewGallery /> */}
            </div>
            {/* <Splash.HomeSection.Previews>
                <Splash.HomeSection.PreviewImage>
                    <img src={previewImage1} alt="preview game" />
                </Splash.HomeSection.PreviewImage>
            </Splash.HomeSection.Previews> */}
        </HomeContainer>
    )
}

const Mascots = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 20px;
`

Splash.HomeSection.Previews = function ({children}) {
    return (
        <StyledPreviewContainer>
            {children}
        </StyledPreviewContainer>
    )
}

const StyledPreviewContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`

Splash.HomeSection.PreviewImage = function ({children}) {
    return (
        <StyledPreviewImage>
            {/* <img src={previewImage1} alt="preview"/> */}
            {children}
        </StyledPreviewImage>
    )
}

const StyledPreviewImage = styled.div`
    // border: 3px solid black;
    // border-radius: 24px;
    max-width: 700px;
    width: 100%;
    min-width: 300px;
    // min-height: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    img {
        width: 100%;
        // height: 100%;
        // min-height: 343px;
        border-radius: inherit;
        object-fit: cover;
    }

`
const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;

    h4 {
        font-size: 1.3em;
        font-weight: 500;
        // text-align: center;
        width: 80%;
    }

    p {
        width: 80%;
    }

    a{
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #5856F2;
        color: #E5E4E2;
        padding: 0.5em 1em;
        border-radius: 999px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    @media (max-width: 1050px) {
        h4, p {
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        h4 {
            font-size: 1.1em;
        }
    }
`

const FunPlayNowButton = styled.div`
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 0.7em 1em;
  background-color: #FF7FB5;
  font-size: 2em;
  border: 3px solid black;
  box-shadow: 0px 3px;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover {
    background-color: #FFAED0;
  }

  &:active {
    transform: translateY(3px); 
    box-shadow: 0px 0px;
  }
  @media (max-width: 1050px) {
       
       padding: 0.3em 0.7em;
       font-size: 1.7em;
    }

    @media (max-width: 669px) {
        width: 100%;
    }
`

Splash.CreatorsSection = function SplashCreatorsSection () {
    const { width } = useWindowDimensions();
    return (
        <StyledCreatorsContainer>
            <h2>We’re a team of women who love word games, based in San Francisco, California. LetterHeist is a project to bring the game of Anagrams to the online realm and has become a way of inspiring people to gather in online communities around word games.</h2>
            {width > 1080 
            ?
            <CreatorsList>
                {Object.values(creatorsData).map((creator, i) => (
                    <CreatorsItem key={i}>
                        <div className="creator-image">
                            <img src={creator.photo} alt={`${creator.name}'s portrait`} />
                        </div>
                            <h3 className="creator-name">{creator.name}</h3>
                            <h4 className="creator-role">{creator.role}</h4>
                        <div className="creator-summary">{creator.summary}</div>
                        <div className="social-links" style={{ display: "flex", gap: "8px", alignItems: "flex-end", justifyContent: "center", marginTop: "auto", marginBottom: "12px"}}>
                            <a className="social-link_linked-in" href={creator.linkedIn} target="__blank" rel="noopener noreferrer" ><Linkedin /></a>
                            <a href={`mailto:${creator.email}`} target="_blank" rel="noopener noreferrer" ><Mail /></a>
                            {creator.personalWebsite && 
                                <a className="social-link_personal-website" href={creator.personalWebsite} target="_blank" rel="noopener noreferrer" ><Globe /></a>
                            }
                        </div>
                    </CreatorsItem>
                ))}
            </CreatorsList>
            :  <CreatorsPanel />
            }
            
            <h2 style={{padding: "4px 16px", borderRadius: "999px", backgroundColor: "#85C9FA"}}>Special thanks to the following people for helping us launch this game!</h2>
            <BackersList>
                {backersData.map((backer, i) => (
                    <li key={i} title={backer.easterEgg}>{backer.name}</li>
                ))}
            </BackersList>
            <div>
                & special thanks to the <a style={{color: "darkblue", fontWeight: "700"}} href="https://www.kickstarter.com/creative-capital-x-skoll-creator-fund" target="_blank" rel="noopener noreferrer">Creative capital creator fund</a>
            </div>
            <h2>- LetterHeist Team</h2>
            <StyledPreviewImage> 
            <div style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"}}>
                <img src={GroupPic} alt="group selfie" />
            </div>
            </StyledPreviewImage>
                <h2>want to say hi?</h2>
                <p>contact us at team@letterheist.com :)</p>
        </StyledCreatorsContainer>
    )
}

const BackersList = styled.ul`
    -moz-column-count: 6;
    -moz-column-gap: 1em;
    -webkit-column-count: 6;
    -webkit-column-gap: 1em;
    column-count: 6;
    column-gap: 1em;
    font-size: 0.875em;
    text-align: left;
    text-transform: lowercase;

    @media (max-width: 830px) {
        -moz-column-count: 4;
        -moz-column-gap: 1em;
        -webkit-column-count: 4;
        -webkit-column-gap: 1em;
        column-count: 4;
        column-gap: 1em;
    }

    @media (max-width: 600px) {
        -moz-column-count: 3;
        -moz-column-gap: 1em;
        -webkit-column-count: 3;
        -webkit-column-gap: 1em;
        column-count: 3;
        column-gap: 1em;
    }

    @media (max-width: 400px) {
        -moz-column-count: 2;
        -moz-column-gap: 1em;
        -webkit-column-count: 2;
        -webkit-column-gap: 1em;
        column-count: 2;
        column-gap: 1em;
    }
`

const StyledCreatorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    gap: 30px;
    align-items: center;
    padding-bottom: 40px;
    text-align: center;
    // width: 100%;

    h2 {
        width: 80%;
        font-size: 1.3em;
        transition: 0.3s all linear;
    }

    @media (max-width: 600px) {
        h2 {
            font-size: 1em;
            font-weight: 600;
        }
    }
`

const CreatorsList = styled.div`
    display: flex;
    gap: 20px;
    // flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
`

const CreatorsItem = styled.div`
    display: flex;
    flex-direction: column;
    // text-align: center;
    // align-items: center;
    width: 100%;
    min-width: 150px;
    max-width: 300px;
    border: 2px solid black;
    border-radius: 24px;
    background-color: #F4F6DC;
    box-shadow: 3px 3px;
    position: relative;
    transition: 0.3s all ease;
    height: auto;
    overflow: hidden;
    text-align: left;

    .creator-image {
        width: 100%;
        background: gray;
        overflow: hidden;

        margin-bottom: 8px;
        img {
            aspect-ratio : 1 / 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .creator-name {
        font-size: 1.3em;
        font-weight: 500;
        text-align: left;
        padding: 0px 16px;
        line-height: 0.8;
        margin-top: 12px;
    }

    .creator-role {
        font-size: 0.875em;
        font-weight: 300;
        padding: 2px 16px;
        line-height: 1;
    }

    .creator-name-role {
        position: absolute;
        left: 0;
        top: 200px;
        width: 100%;
        background-color: rgba(244, 246, 220, .6);
    }

    .creator-summary {
        font-size: 0.9em;
        line-height: 1.2;
        padding: 16px;
        font-weight: 300;
    }


    @media (max-width: 1333px) {
        width: 48%;
    }

    @media (max-width: 703px) {
        flex-direction: column;
        width: 90%;
    }
`

Splash.GameInstructionsSection = function SplashGameInstructionsSection () {
    return (
        <GameInstructions />
    )
}

Splash.FaqSection = function SplashFaqSection () {
    return (
        <StyledFaqContainer>
            {Object.values(faqData).map((faq, i) => (
                <StyledFaqItem key={i}>
                    <p className="faq-question">{faq.question}</p>
                    <p className="faq-answer">{faq.answer}</p>
                </StyledFaqItem>
            ))}
        </StyledFaqContainer>
    );
};

const StyledFaqContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    // width: 100%;
    margin: 10px 20px;

`

const StyledFaqItem = styled.div`
    background-color: #FAEFEF;
    padding: 19px 26px;
    text-align: center;
    border-radius: 24px;

    .faq-question {
        font-weight: 700;
        color: #51131E;
    }

    .faq-answer {
        // font-style: italic;
        color: #175398;
    }
`

export default Splash;
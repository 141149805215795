import { useSelector } from "react-redux";
import { StyledReturnToLobbyButton } from "./StyledButtons";
import { getRoomCode } from "../../store/room";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCurrentUser } from "../../store/session";
import { socketReturnToLobby } from "../../lib/socket/socketConnection";
import { createStructuredSelector } from "reselect";

export default function ReturnToLobbyButton({
  styles = { bgColor: "inherit" },
}) {
  const {roomCode, currentUser} = useSelector(createStructuredSelector({
    roomCode: getRoomCode,
    currentUser: getCurrentUser
  }));
  
  const history = useHistory();


  const handleReturnToLobby = () => {
    const data = {
      room_code: roomCode,
      player_id: currentUser.id,
    };
    socketReturnToLobby(data);
    // history.push(`/room/${roomCode}`);
  };

  return (
    <StyledReturnToLobbyButton onClick={handleReturnToLobby}>
      Return To Lobby
    </StyledReturnToLobbyButton>
  );
}

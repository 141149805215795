import { useSelector } from "react-redux";
import EndGameButton from "../../Buttons/EndGameButton";
import { Text, WarningText } from "./styles/StyledFinalContemplation";
import { getIsCurrentPlayerSpectator } from "../../../store/game";
import { Tooltip } from 'react-tooltip'

export default function FinalContemplation() {
    const isSpectator = useSelector(getIsCurrentPlayerSpectator)

    if (isSpectator) {
        return (
            <>
            <Text>
            The game is currently in final contemplation.
            </Text>
            </>
        )
    }

    return (
        <>
            <EndGameButton />
            <Tooltip anchorSelect=".end-game-button" place="top">
                <Text>There are no more letters left to reveal. If everyone agrees that no more words can be formed, anyone can click the button below to end the game. <WarningText>Warning: clicking this will end the game for all players.</WarningText></Text>
            </Tooltip>
        </>
    );
};

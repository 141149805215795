import { StyledEndGameButton } from "./StyledButtons";
import { socketGameOver } from "../../lib/socket/socketConnection";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


export default function EndGameButton() {
  // const gameRoomCode = useSelector(getRoomGameCode());
  const {roomCode: gameRoomCode} = useParams();

  const handleEndGame = () => {
    // console.log(gameRoomCode, "gameroomcode");
    socketGameOver({
      room_game_code: gameRoomCode,
    });
  };

  return (
    <>
      <StyledEndGameButton 
        onClick={handleEndGame}
        className="end-game-button"
        >
          End Game
      </StyledEndGameButton>
      
    </>

  );
}

import { useSelector } from "react-redux";
import {
  ButtonsContainer,
  Container,
  LongestEvolutionListItem,
  LongestEvolutionsList,
  LongestWordsList,
  PlayerScoresList,
  PlayerScoresListItem,
  Subheader,
  Title,
} from "./styles/StyledFinalScoreResults";
import { FaArrowRightLong } from "react-icons/fa6";
import ReturnToLobbyButton from "../../Buttons/ReturnToLobbyButton";
import { FaCrown } from "react-icons/fa";
import TabBar from "../../TabBar/TabBar";
import RetroNavigation from "../../Retro/RetroNavigation/RetroNavigation";
import { FINAL_GAME_RESULTS_COLORS } from "../../../styles/Colors";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getCurrentGameScorecard } from "../../../store/room";
import { useState } from "react";
import { useEffect } from "react";
import { ProgressBar } from "react-loader-spinner";
import ExitGameButton from "../../Buttons/ExitGameButton";


const finalScoresStyleOptions = {
  victory: {
    activeTabTextColor: FINAL_GAME_RESULTS_COLORS.victory["green-900"],
    inactiveTabTextColor: FINAL_GAME_RESULTS_COLORS.victory["green-400"],
    activeTabBgColor: FINAL_GAME_RESULTS_COLORS.victory["green-400"],
    inactiveTabBgColor: FINAL_GAME_RESULTS_COLORS.victory["green-700"],
    contentBgColor: FINAL_GAME_RESULTS_COLORS.victory["green-400"],
    borderColor: FINAL_GAME_RESULTS_COLORS.victory["green-900"],
  },
  defeat: {
    activeTabTextColor: FINAL_GAME_RESULTS_COLORS.grays["gray-100"],
    inactiveTabTextColor: FINAL_GAME_RESULTS_COLORS.defeat["red-400"],
    activeTabBgColor: FINAL_GAME_RESULTS_COLORS.defeat["red-400"],
    inactiveTabBgColor: FINAL_GAME_RESULTS_COLORS.defeat["red-700"],
    contentBgColor: FINAL_GAME_RESULTS_COLORS.defeat["red-400"],
    borderColor: FINAL_GAME_RESULTS_COLORS.defeat["red-900"],
  },
  spectator: {
    activeTabTextColor: "#C76C18",
    inactiveTabTextColor: "#F7CEA8",
    activeTabBgColor: "#FFB783",
    inactiveTabBgColor: "#EF7D2A",
    contentBgColor: "#FFB783",
    borderColor: "#5C1A06",
  }
};

const FinalScoreResults = () => {
  const [loading, setLoading] = useState(true);
  const { roomGameCode } = useParams();
  const { scorecard, currentUser, currentUserVictoryStatus } = useSelector(state => getCurrentGameScorecard(state, roomGameCode))

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  // console.log("this is our scorecard", scorecard, "current user victory status", currentUserVictoryStatus)

  const createFinalScoreResultsTabs = () => {
    return {
      Rankings: {
        tabName: "Rankings",
        tabContentHeader: "FINAL SCORES & WINNER(S)",
        tabContent: (
          <FinalScoreResults.PlayerScores finalResults={scorecard} />
        ),
      },
      "Fun Stats": {
        tabName: "Fun Stats",
        tabContentHeader: "LONGEST...",
        tabContent: <FinalScoreResults.FunStats finalResults={scorecard} />,
      },
      "Failed Stats": {
        tabName: "Failed Stats",
        tabContentHeader: "INVALID WORD ENTRIES",
        tabContent: <FinalScoreResults.FailedStats finalResults={scorecard} />

      }
    };
  };

  const generateStyleOptions = () => {
    if (currentUserVictoryStatus === undefined) {
      return finalScoresStyleOptions.spectator;
    } else if (currentUserVictoryStatus) {
      return finalScoresStyleOptions.victory;
    } else {
      return finalScoresStyleOptions.defeat;
    }
  };


  return (
    <>
    { loading ?
      <ResultsLoading />
      : 
      <Container
        $isWinner={scorecard?.finalRankingAndPlayerScores[currentUser.id]?.is_winner}
      >
        <FinalScoreResults.Title>
          {currentUserVictoryStatus === undefined ? "GAME OVER" : currentUserVictoryStatus ? "VICTORY" : "DEFEAT"}
        </FinalScoreResults.Title>
        <RetroNavigation
          tabs={createFinalScoreResultsTabs()}
          styleOptions={generateStyleOptions()}
        />
        <FinalScoreResults.Buttons />
      </Container>

    }
    </>
  );
};

export default FinalScoreResults;

FinalScoreResults.Title = function ({ children }) {
  return (
    <Title>
      {children}
    </Title>
  );
};

// Sorted list of players and their scores (list all words, and total letters)
FinalScoreResults.PlayerScores = function ({ finalResults }) {
  const playerScores = finalResults?.finalRankingAndPlayerScores ? Object.values(finalResults?.finalRankingAndPlayerScores) : [];
  // console.log(playerScores, "beep");
  const createStringOfWords = (words) => {
    return words.join(", ");
  };
  return (
    // <LeftContent>
    <>
      <PlayerScoresList>
        {playerScores.map((playerScore, i) => (
          <PlayerScoresListItem key={i}>
            <div>
              {playerScore?.is_winner ? 
                <FaCrown style={{ color: "orange" }} />
                : <FaCrown style={{ visibility: "hidden"}} />
              }
            </div>
            <section>
              <p style={{ fontWeight: "700", fontSize: "1em" }}>
                {playerScore?.player_display_name}:{" "}
                {playerScore?.player_total_score} letters
              </p>
              <p className="word-list">
                {createStringOfWords(playerScore?.player_words)}
              </p>
            </section>
          </PlayerScoresListItem>
        ))}
      </PlayerScoresList>
    </>
    // </LeftContent>
  );
};

FinalScoreResults.FunStats = function ({ finalResults }) {
  return (
    <>
      <FinalScoreResults.LongestEvolution finalResults={finalResults} />
      <FinalScoreResults.LongestWord finalResults={finalResults} />
    </>
  );
};

FinalScoreResults.LongestEvolution = function ({ finalResults }) {
  const longestEvolutions = finalResults?.wordsWithMostEvolutions;
  // console.log(longestEvolutions);

  const renderEvolutions = (evolutions) => {
    return (
      <>
        {evolutions.map((evolution, i) => (
          <div key={i} style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>{evolution?.word}</p>
              <p>{evolution?.owner_display_name}</p>
            </div>
            <div className="evo-arrow">
              <FaArrowRightLong />
            </div>
          </div>
        ))}
      </>
    );
  };

  const evolutionTabContentComponent = (evolution) => {
    return (
      <LongestEvolutionListItem>
        {renderEvolutions(evolution?.evolutions)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 12px",
          }}
        >
          <p>{evolution.word}</p>
          <p>{evolution.owner_display_name}</p>
        </div>
      </LongestEvolutionListItem>
    );
  };

  const createLongestEvolutionsTabOptions = () => {
    // key = evolution.word
    // value = component
    let options = {};
    for (let evolution of longestEvolutions) {
      options[evolution?.word] = evolutionTabContentComponent(evolution);
    }
    return options;
  };

  if (longestEvolutions[0]?.evolutions_count > 0) return (
    <>
      <Subheader>Longest Evolution(s)</Subheader>
      <LongestEvolutionsList>
        <TabBar options={createLongestEvolutionsTabOptions()} />
      </LongestEvolutionsList>
    </>
  );
};

FinalScoreResults.LongestWord = function ({ finalResults }) {
  const { longestWordsDeets } = finalResults;
  // console.log(longestWordsDeets);
  const numLetters = () => {
    return longestWordsDeets[0]?.score;
  };

  const createStringOfWords = () => {
    let words = [];
    for (let wordDeet of longestWordsDeets) {
      // console.log(wordDeet);
      words.push(wordDeet.word);
    }
    return words.join(", ");
  };
  return (
    <>
      <Subheader>Longest Word(s)</Subheader>
      <LongestWordsList>
        <p>
          {numLetters()} {numLetters() ? "letters" : ""}
        </p>
        {createStringOfWords()}
      </LongestWordsList>
    </>
  );
};

FinalScoreResults.FailedStats = function FailedStats({finalResults}) {
  const {incorrectWordAttempts} = finalResults;
  // console.log("incorrect word attempts", incorrectWordAttempts);

  const mapArrayIntoString = (words) => {
    return words.join(", ");
  }
  return (
    <>
      {Object.values(incorrectWordAttempts).map((data,i) => (
        <div key={i} style={{ fontWeight: "600"}}>
          {data.player_display_name}: <span style={{ color: "red", fontWeight: "400"}}>{mapArrayIntoString(data.not_a_word_words)}</span>
        </div>
      ))}
    </>
  )
}

FinalScoreResults.Buttons = function () {
  return (
    <>
      <ButtonsContainer>
        <ExitGameButton />
        <ReturnToLobbyButton
          styles={{ bgColor: FINAL_GAME_RESULTS_COLORS.victory["green-700"] }}
        />
      </ButtonsContainer>
    </>
  );
};

const ResultsLoading = () => {
  return (
    <div className="game-loading">
      <p> loading results </p>
      <ProgressBar
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
    </div>
  )
}
import React from "react";
import {
  GameSummaryItemContainer,
  GameSummaryLongestEvoList,
  GameSummaryLongestEvoListItem,
  GameSummaryLongestEvolutions,
  GameSummaryLongestWords,
  GameSummaryViewFullStatsButton,
  GameSummaryWinnerContainer,
  GameSummaryWinnerItem,
  GameSummaryWinnersList,
} from "./styles/StyledGameSummaryNotifications";
import TabBar from "../TabBar/TabBar";

const GameSummaryNotification = (log) => {
  // console.log(log, "loggggggggg");
  const { longestEvolutionsDeets, playerScores, longestWordsDeets } = log;
  const winners = Object.values(playerScores).filter(
    (player) => player.is_winner
  );

  const longestWords = () => {
    let words = [];
    for (let word of longestWordsDeets) {
      words.push(word.word);
    }
    return words.join(", ");
  };

  const renderEvolutions = (evolutions) => {
    return (
      <>
        {evolutions.map((evolution, i) => (
          <div
            key={i}
            style={{ display: "flex", alignItems: "center", gap: "12px" }}
          >
            <p style={{ paddingLeft: "12px" }}>{evolution?.word}</p>
            <div className="evo-arrow">{`>`}</div>
          </div>
        ))}
      </>
    );
  };

  const evolutionTabContentComponent = (evo) => {
    return (
      <GameSummaryLongestEvoListItem>
        {renderEvolutions(evo?.evolutions)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 12px",
          }}
        >
          {evo.word}
        </div>
      </GameSummaryLongestEvoListItem>
    );
  };

  const createLongestEvolutionsTabOptions = () => {
    // key = evolution.word
    // value = component
    let options = {};
    for (let evolution of longestEvolutionsDeets) {
      options[evolution?.word] = evolutionTabContentComponent(evolution);
    }
    return options;
  };

  return (
    <GameSummaryItemContainer>
      <h1>GAME SUMMARY</h1>
      <GameSummaryWinnerContainer>
        <h2>winner(s)</h2>
        <GameSummaryWinnersList>
          {winners.map((player, i) => (
            <GameSummaryWinnerItem key={i}>
              <p>{player.player_display_name}</p>
              <p className="player_total_score">
                {player.player_total_score} letters
              </p>
            </GameSummaryWinnerItem>
          ))}
        </GameSummaryWinnersList>
      </GameSummaryWinnerContainer>
      {longestEvolutionsDeets[0].evolutions_count > 0 && (
        <GameSummaryLongestEvolutions>
          <h2>longest evolution(s)</h2>
          <GameSummaryLongestEvoList>
            <TabBar options={createLongestEvolutionsTabOptions()} />
          </GameSummaryLongestEvoList>
        </GameSummaryLongestEvolutions>
      )}
      <GameSummaryLongestWords>
        <h2>longest word(s)</h2>
        <p>{longestWords()}</p>
      </GameSummaryLongestWords>
      {/* <GameSummaryViewFullStatsButton>
        view all game stats
      </GameSummaryViewFullStatsButton> */}
    </GameSummaryItemContainer>
  );
};

export default GameSummaryNotification;

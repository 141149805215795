import styled from "styled-components";
export const GameSummaryItemContainer = styled.div`
  border: 2px solid #969696;
  border-radius: 24px;
  padding: 8px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #e4e4db;
  font-size: 0.9em;

  h1,
  h2 {
    text-transform: uppercase;
    font-size: 1.3em;
    // letter-spacing: -1px;
    text-align: center;
    width: 100%;
    font-weight: 500;
  }

  h2 {
    font-size: 1em;
    text-transform: lowercase;
    margin-bottom: 6px;
  }
`;

export const GameSummaryWinnerContainer = styled.div`
  background-color: #ced0b4;
  width: 100%;
  //   height: 60px;
  border-radius: 16px;
  padding: 8px;
`;

export const GameSummaryWinnersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GameSummaryWinnerItem = styled.div`
  display: flex;

  .player_total_score {
    margin-left: auto;
  }
`;

export const GameSummaryLongestEvolutions = styled.div`
  background-color: #dccdb1;
  width: 100%;
  // height: 60px;
  border-radius: 16px;
  padding: 8px;
`;

export const GameSummaryLongestWords = styled.div`
  background-color: #dccdb1;
  width: 100%;
  // height: 60px;
  border-radius: 16px;
  padding: 8px;
  text-align: center;
`;

export const GameSummaryViewFullStatsButton = styled.div`
  font-style: italic;
  text-decoration: underline;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const GameSummaryLongestEvoList = styled.div``;

export const GameSummaryLongestEvoListItem = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.875em;
`;


// Header, Subheader, Options, Toggle, Button, Icon

import { createContext, useContext, useEffect, useState } from "react";

// Settings Context that wraps around Game Page

// Users can open a settings modal to toggle configurations

export const SettingsContext = createContext({
    showDefinitions: true,
    setShowDefinitions: () => {}
});

const SettingsContextProvider = ({ children }) => {
    const [ showDefinitions, setShowDefinitions ] = useState(true);
    // add more configs here

    const restoreSettings = () => {
        const settings = JSON.parse(localStorage.getItem("settingsDetails"));
        if (settings) {
            setShowDefinitions(settings.showDefinitions);
        }
    }

    useEffect(() => {
        restoreSettings();
    }, []);


    useEffect(() => {
        const settings = {
            showDefinitions: showDefinitions
        }
        localStorage.setItem("settingsDetails", JSON.stringify(settings));
    }, [showDefinitions])

    return (
        <>
        <SettingsContext.Provider value={{
            showDefinitions: showDefinitions, 
            setShowDefinitions: setShowDefinitions
        }}>
            { children }
        </SettingsContext.Provider>
        </>
    );
};

const useSettingsContext = () => useContext(SettingsContext);

export { useSettingsContext, SettingsContextProvider };
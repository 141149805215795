// import { useSelector } from "react-redux";
// import { getRoomCode } from "../../store/room";

// const CopyButton = ({type}) => {
//     const roomCode = useSelector(getRoomCode());

//     const handleCopy = (type) => {
//         console.log("need to implement copy function");
//         let textToCopy = "";
//         switch (type) {
//           case 'roomCode':
//             return textToCopy = roomCode;
//           default:
//             return textToCopy = `localhost:3000/join-room/${roomCode}`
//         }
//         navigator.clipboard.writeText(textToCopy)
//     };

//   return (
//     <div onClick={handleCopy} id="copy-button">
//         {type === 'roomCode' ? 'copy' : 'copy url'}
//     </div>
//   );
// };

// export default CopyButton;

import React, { useState } from 'react';

const CopyButton = ({ textToCopy, buttonText }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
      <button onClick={copyToClipboard} id="copy-button">
        {buttonText}
      </button>
  );
};

export default CopyButton;
import styled from "styled-components";
import { RiUserVoiceLine } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";
import { Edit } from "react-feather";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function FeedbackInGameButton() {
  const handleFeedback = () => {
    window.open("https://forms.gle/SnLGKGrNgDV5G2Ui8", "_blank");
  };
  const { width, height } = useWindowDimensions();

  return (
    <>
      <StyledFeedbackInGameButton
        onClick={handleFeedback}
        title="Experienced a bug? Have some feedback about LetterHeist? We'd love to know!"
        className="feedback-in-game-button"
      >
        <VscFeedback size={width > 1050 ? 32 : 24} strokeWidth={0} />
      </StyledFeedbackInGameButton>
    </>
  );
}

const StyledFeedbackInGameButton = styled.div`
  cursor: pointer;
  width: 56px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  // border: 2px solid black;
  padding: 8px;
  transition: 0.2s ease all;

  &:hover {
    background-color: rgba(214, 214, 204, 0.46);
    border-radius: 999px;
  }

  @media (max-width: 1050px) {
    width: 46px;
  }
`;

import { useSelector } from "react-redux";

import CopyButton from "../../roomComponents/CopyButton";
import { getRoomCode } from "../../../store/room";
import { Container } from "./styles/StyledRoomCodeInfo";

export default function RoomCodeInfo() {
    const roomCode = useSelector(getRoomCode);
    return (
        <>
        <Container>
            <p>room code:</p>
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                <h1>{roomCode}</h1>
                <CopyButton textToCopy={roomCode} buttonText="copy">copy</CopyButton>
            </div>
        </Container>
        </>
    )
}
import { disconnectSocket } from "../lib/socket/socketConnection";
import { clearLogs } from "./logs";
import { resetRoom } from "./room";
import { createSelector } from "@reduxjs/toolkit";
// const apiURL = process.env.REACT_APP_API_URL;

// SELECTORS
export const selectSession = (state) => state.session;

export const getCurrentUser = createSelector(selectSession, (session) => {
  return session.user;
});

export const getCurrentUserInfo = createSelector(getCurrentUser, (user) => {
  // console.log("retrieving user info");
  return {
    token: user ? user.token : null,
    currentUserId: user ? user.id : null,
  };
});

// CONSTANTS
export const SET_CURRENT_USER = "session/SET_CURRENT_USER";
export const REMOVE_CURRENT_USER = "session/REMOVE_CURRENT_USER";

// ACTION CREATORS
export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const removeCurrentUser = () => ({
  type: REMOVE_CURRENT_USER,
});

// HELPER FUNCTIONS
const storeCurrentUser = (user) => {
  if (user) {
    // sessionStorage.setItem("currentUser", JSON.stringify(user));
    localStorage.setItem("currentUser", JSON.stringify(user));
  } else {
    // sessionStorage.removeItem("currentUser");
    localStorage.removeItem("currentUser");
  }
};

// THUNK ACTION CREATORS
export const restoreSession = () => async (dispatch) => {
  // const user = JSON.parse(sessionStorage.getItem("currentUser"));
  const user = JSON.parse(localStorage.getItem('currentUser'));
  dispatch(setCurrentUser(user));
  return user;
};

export const login = (user) => async (dispatch) => {
  // let res = await fetch('/auth/login', {
  let res = await fetch(
    `https://letterheist-3b560da0eac4.herokuapp.com/auth/login`,
    // `http://127.0.0.1:5000/auth/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  );

  let data = await res.json();
  if (res.ok) {
    // console.log(data);
    storeCurrentUser(data);
    dispatch(setCurrentUser(data));
    return null;
  } else if (res.status <= 500) {
    // console.log("errors: ", data);
    if (data.errors) {
      return data.errors;
    }
  }
};

export const register = (user) => async (dispatch) => {
  if (user["display_name"].length < 3) {
    return ["Username must be at least three characters"];
  }

  if (user["password"].length < 6) {
    return ["Password must be at least six characters"];
  }

  // let res = await fetch('/auth/register', {
  let res = await fetch(
    `https://letterheist-3b560da0eac4.herokuapp.com/auth/register`,
    // `http://127.0.0.1:5000/auth/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  );

  const data = await res.json();
  if (res.ok) {
    // console.log(data);
    storeCurrentUser(data);
    dispatch(setCurrentUser(data));
    return null;
  } else if (res.status < 500) {
    // console.log("errors: ", data.errors);
    if (data.errors) {
      return data.errors;
    }
  }
};

export const logout = () => async (dispatch) => {
  // let res = await fetch('/auth/logout');
  let res = await fetch(
    `https://letterheist-3b560da0eac4.herokuapp.com/auth/logout`
    // `http://127.0.0.1:5000/auth/logout`
  );
  // let data = await res.json();
  if (res.ok) {
    storeCurrentUser(null);
    dispatch(removeCurrentUser());
    disconnectSocket();
    dispatch(resetRoom());
    dispatch(clearLogs());
  }
};

export const continueAsGuest = (guest) => async (dispatch) => {
  // doing frontend validations for this one
  if (guest["display_name"].length < 3) {
    return ["Please enter at least three characters"];
  }

  // let res = await fetch('/auth/continue-as-guest', {
  let res = await fetch(
    `https://letterheist-3b560da0eac4.herokuapp.com/auth/continue-as-guest`,
    // `http://127.0.0.1:5000/auth/continue-as-guest`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(guest),
    }
  );

  const data = await res.json();

  if (res.ok) {
    // console.log(data);
    storeCurrentUser(data);
    dispatch(setCurrentUser(data));
    return null;
  } else if (res.status < 500) {
    // console.log("errors: ", data.errors);
    if (data.errors) {
      return data.errors;
    }
  }
};

// REDUCER
const initialState = {
  // user: JSON.parse(sessionStorage.getItem("currentUser")),
  user: JSON.parse(localStorage.getItem("currentUser"))
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { user: action.payload };
    case REMOVE_CURRENT_USER:
      return { user: null };
    default:
      return state;
  }
};

export default sessionReducer;

import StartGameButton from "../../Buttons/StartGameButton";
import Logs from "../../Logs";
import LobbyPlayerList from "./LobbyPlayerList";
import PlayerReadyButton from "./PlayerReadyButton";
import RoomCodeInfo from "./RoomCodeInfo";
import SecondsBetweenLettersComponent from "./SecondsBetweenLettersComponent";
import { Container, Left, Right } from "./styles/StyledLobbyContent";

export default function LobbyContent() {
  return (
    <Container>
      <Left>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <RoomCodeInfo />
          <SecondsBetweenLettersComponent />
          <PlayerReadyButton />
        </div>
        <LobbyPlayerList />
        <StartGameButton />
      </Left>
      <Right>
        <Logs />
      </Right>
    </Container>
  );
}

import styled from "styled-components";

const RetroContainer = ({className, children, ...restProps}) => {
    return (
        <div className={className} {...restProps}>
            {children}
        </div>
    )
}

export const Container = styled(RetroContainer)`
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    border: 3px solid black;
    border-radius: 24px;
    padding: 16px;
    background-color: ${props => props.$backgroundColor};

    transition: 0.3s all ease;
`

export const ImageContainer = styled.div`
    border: 3px solid black;
    border-radius: 12px;
    width: 100%;
    background-color: #f5f5f5;
    min-height: 50px;
    height: 100%;

    img {

    }
`

export const CaptionContainer = styled.div`
    
`


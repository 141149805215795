import styled from 'styled-components';

export const Container = styled.div`
    // width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;

    p {
        text-transform: uppercase;
    }

    h1 {
        font-size: 2.5em;
        // margin-bottom: 10px;
    }

    @media (max-height: 800px) {
        h1 {
            font-size: 2em;
        }
    }
`
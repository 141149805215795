import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    width: 53px;
    height: 30px;
    justify-content: ${props => props.$show ? "flex-end" : "flex-start"};
    background-color: ${props => props.$show ? "#95B19B" : "gray"};
    // background-color: #95B19B;
    border: 2px solid black;
    border-radius: 999px;
    cursor: pointer;
    transition: 0.3s all linear;
`

export const Circle = styled.div`
    width: 17px;
    height: 17px;
    background-color: white;
    border: 2px solid black;
    border-radius: 999px;
    transition: 1s all linear;
`
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
   

    @media (max-width: 600px) {
        padding: 10px 0px;
    }
`

export const StyledGoalText = styled.h1`
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 1.25em;
    }
`

export const RuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 12px;
    text-wrap: wrap;
    
`

export const StyledRuleTitle = styled.h1`
    text-align: center;
    font-size: 1.25em;
    text-decoration: underline;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        font-size: 1em;
    }
`

export const StyledRuleContent = styled.div`
    font-size: 0.875em;

    span {
        font-weight: 700;
    }

    ol li {
        list-style-type: decimal;
        margin-left: 30px;
    }

    ul {
        margin-left: 16px;
    }

    ul li {
        list-style-type: circle;
    }

    .create-words-demo {
        display: flex;
        gap: 8px;

    }

    .create-words-section {
        width: 50%;

        h3 {
            font-weight: 600;
            padding: 4px 0px;
        }

        img {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        }
    }

    @media (max-width: 600px) {
        // font-size: 0.8em;

        .create-words-demo {
            flex-direction: column;
            align-items: center;

        }

        .create-words-section {
            width: 100%;
        }
    }
`
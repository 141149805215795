import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
`

export const StyledLogo = styled.div`
    font-size: 3em;
    
`



import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { Settings } from "react-feather";

import Logo from "../../generalComponents/Logo";
import GameSettingsModal from "../GameSettings/GameSettingsModal";
import SpectatorReturnToLobbyButton from "../../Buttons/SpectatorReturnToLobbyButton";
import { Container, SettingsButton, Text } from "./styles/StyledGameHeader";
import { Modal } from "../../../contexts/Modal";
import { getCurrentUser } from "../../../store/session";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import HowToPlayButton from "../../Buttons/HowToPlayButton";
import FeedbackInGameButton from "../../Buttons/FeedbackInGameButton";
import { Tooltip } from "react-tooltip";

export default function GameHeader({ isSpectator }) {
  const { roomCode: roomGameCode } = useParams();
  const currentPlayer = useSelector(getCurrentUser);

  return (
    <Container>
      <Logo disabled={true} />
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <CurrentPlayer>{currentPlayer.username}</CurrentPlayer>
        <HowToPlayButton />
        <Tooltip anchorSelect=".how-to-play-button" place="bottom">
          How to play
        </Tooltip>
        <FeedbackInGameButton />
        <Tooltip anchorSelect=".feedback-in-game-button" place="bottom">
          Got feedback?
        </Tooltip>
        {isSpectator ? (
          <SpectatorReturnToLobbyButton roomGameCode={roomGameCode} />
        ) : (
          <GameSettings />
        )}
      </div>
    </Container>
  );
}

function CurrentPlayer({ children }) {
  return <Text>{children}</Text>;
}

// export settings button that will open a config modal
export function GameSettings() {
  const [openSettings, setOpenSettings] = useState(false);
  const { width } = useWindowDimensions();
  return (
    <>
      <SettingsButton onClick={() => setOpenSettings(true)}>
        <Settings size={width > 1050 ? 40 : 36} strokeWidth={1} />
      </SettingsButton>
      {openSettings && (
        <Modal onClose={() => setOpenSettings(false)}>
          <GameSettingsModal onClose={() => setOpenSettings(false)} />
        </Modal>
      )}
    </>
  );
}

import { useState } from "react";
import { Info, BookOpen } from "react-feather";
import styled from "styled-components";
import { Modal } from "../../contexts/Modal";
import GameInstructions from "../GameInstructions";
import RetroWindowA from "../Retro/RetroWindowA/RetroWindowA";
import { PiGameControllerLight } from "react-icons/pi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function HowToPlayButton () {
    const [ openGameInstructions, setOpenGameInstructions ] = useState(false);
    const { width, height } = useWindowDimensions();

    const handleOpenGameInstructions = () => {
        setOpenGameInstructions(true);
    }

    return (
        <>
        <StyledHowToPlayButton 
            onClick={handleOpenGameInstructions}
            title="Instructions on how to play"
            className="how-to-play-button"
        >
            <PiGameControllerLight size={width > 1050 ? 40 : 30} strokeWidth={1}/>
        </StyledHowToPlayButton>
        {openGameInstructions && (
            <Modal onClose={() => setOpenGameInstructions(false)}>
                {/* <div style={{width: "1000px"}}> */}
                    <RetroWindowA content={{title: "How to Play", content: <GameInstructions isModal={true}/>}} />
                {/* </div> */}
            </Modal>
        )}
        </>
    )
}

const StyledHowToPlayButton = styled.div`
    cursor: pointer;
    width: max-content;
    padding: 8px;
    transition: 0.2s ease all;

    &:hover {
        background-color: rgba(214, 214, 204, 0.46);
        border-radius: 999px;
    }
`

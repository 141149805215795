import { PlayerName, Text } from "./styles/StyledRoomNotifications";

export function createRoomNotification(log) {
  if (!log?.roomNotificationType) return null;
  return (
    <>
    <div style={{textAlign: "center"}}>
    <PlayerName>{log.playerDisplayName}</PlayerName> <Text>{log.msg}</Text>
    </div>
    </>
  );
}

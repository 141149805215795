import Logo from "../../generalComponents/Logo";
import LeaveRoomButton from "../../Buttons/LeaveRoomButton";
import { Container } from "./StyledLobbyHeader";
import HowToPlayButton from "../../Buttons/HowToPlayButton";

export default function LobbyHeader() {
    return (
        <Container>
            <Logo disabled={true} />
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                <HowToPlayButton />
                <LeaveRoomButton />
            </div>
        </Container>
    )
}

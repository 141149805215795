import { useEffect, useState } from 'react';
import { socketJoinRoom } from '../../lib/socket/socketConnection';
import { useDispatch, useSelector } from 'react-redux';
import { clearRoomErrors, getJoinRoomErrors } from '../../store/room';
import { errorMessage } from '../../utils/errorsUtil';
import styled from 'styled-components';
import "./roomComponents.css"

const JoinRoomForm = () => {
    const dispatch = useDispatch();
    const errors = useSelector(getJoinRoomErrors());

    const [roomCode, setRoomCode] = useState("");
    

    const handleJoinRoom = (e) => {
        e.preventDefault();
        socketJoinRoom({'room_code': roomCode});
    }

    useEffect(() => {
        return () => {
            dispatch(clearRoomErrors())
        };
    }, [dispatch]);

  return (
    <>

        <form onSubmit={handleJoinRoom} id="join-room-form">
            <h1 className="">join an existing party</h1>
            <input
                required
                type="text"
                value={roomCode}
                onChange={(e) => setRoomCode(e.target.value)}
                placeholder="enter room code"
                className="form-input"
            />
            <JoinRoomButton>join</JoinRoomButton>
            {errors && errorMessage(errors)}
        </form>

    </>
  )
}

const JoinRoomButton = styled.button`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 0.7em 1em;
  background-color: #AAEFF4;
  font-size: 2em;
  border: 3px solid black;
  box-shadow: 0px 3px;

  &:active {
    transform: translateY(3px); 
    box-shadow: 0px 0px;
  }

  @media (max-width: 520px) {
    font-size: 1.5em;
  }
`

export default JoinRoomForm;
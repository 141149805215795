import styled from 'styled-components';
import { Colors } from '../../../../styles/Colors';

export const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 4px;
`

export const Item = styled.div`
    border: 2px solid ${props => PlayerColors[props.color]};
    color: ${props => props.selected ? "white" : PlayerColors[props.color]};
    background-color: ${props => props.selected ? "black" : "inherit"};
    border-radius: 999px;
    font-size: 1.3em;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s ease all;
    user-select: none;
    white-space:nowrap;
    display: flex;
    gap: 8px;
    align-items: center;

    .player-list_avatar {
        width: 30px;
        height: 30px;
        border-radius: 999px;
        border: 1px solid black;
        overflow: hidden;
    }

    &:hover {
        background-color: black;
        color: white;
    }

    @media (max-height: 834px) {
        font-size: 1.1em;
        padding: 6px;

        .player-list_avatar {
            width: 30px;
            height: 30px;
        }
    }
`

export const CurrentTurnBorder = styled.div`
    width: fit-content;
    border-radius: 999px;
    padding: 2px;
    border: 2px solid ${props => props.$currentTurn ? "black" : "transparent"};
`

const PlayerColors = {
    'view-all': Colors.BLACK,
    'player-red': "#F2A1A1",
    'player-pink': "#E7B4F0",
    'player-purple': "#C6C6E8",
    'player-blue': "#B4CFF0",
    'player-green': "#AFD2B5",
    'player-orange': "#F1B269"
}

import styled from "styled-components";

export const Container = styled.div`
  // border: 1px solid red;
  display: grid;
  width: 100%;
  height: 100%;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Item = styled.div`
  width: 100%;
  border: 1px solid black;
  // justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  // justify-content: center;
  min-height: 100px;
  height: 100%;

  gap: 20px;
  background-color: ${(props) => (props.readyStatus ? "#C8E1C2" : "#FCF2D0")};
`;

export const PlayerName = styled.h2`
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;

  @media (max-height: 800px) {
    font-size: 1em;
  }
`;

export const ReadyStatus = styled.p`
  @media (max-height: 800px) {
    font-size: 0.875em;
  }
`;

export const ReadyButton = styled.div`
  // border: 1px solid #4A6E61;
  background-color: ${(props) => (props.readyStatus ? "gray" : "green")};
  color: #fafafa;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  width: max-content;
  padding: 2px 12px;
  cursor: pointer;
`;

export const Filler = styled.div`
  width: 100%;
  justify-self: center;
  min-height: 195px;
  border-radius: 16px;
  background-color: #797979;
  opacity: 0.25;
`;

export const ItemImageContainer = styled.div`
  position: relative;
  width: 100%;
  // min-height: 100px;
  min-width: 100px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;
  // z-index: 5;

  .image-container {
    z-index: 1;
    width: 60%;
    // min-width: 50px;
    max-width: 100px;
    aspect-ratio: 1 / 1;
    // max-height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
`;

export const ItemImageColorUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100.5%;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  clip-path: polygon(100% 100%, 0 100%, 0 0);
  // z-index: 4;
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createStructuredSelector } from "reselect";
import { ProgressBar } from "react-loader-spinner";

import GameHeader from "../../components/Game/GameHeader";
import GameContent from "../../components/Game/GameContent";
import SmallScreenAlert from "../../components/SmallScreenAlert";
import "./Game.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  socketLeaveRoomGameSpectator,
  socketReconnectToRoom,
} from "../../lib/socket/socketConnection";

import { getRoomCode, restoreRoom } from "../../store/room";
import { getGameStatus, getIsCurrentPlayerSpectator} from "../../store/game";


const Game = () => {
  const dispatch = useDispatch();
  const { roomCode: currentUsersRoomGameCode } = useParams();

  const { gameStatus, isCurrentPlayerSpectator, roomCode } = useSelector(createStructuredSelector({
      gameStatus: getGameStatus,
      isCurrentPlayerSpectator: getIsCurrentPlayerSpectator,
      roomCode: getRoomCode
  }));

  const { width, height } = useWindowDimensions();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (gameStatus === "canceled" && isCurrentPlayerSpectator) {
      socketLeaveRoomGameSpectator({
        room_game_code: currentUsersRoomGameCode,
      });
    }
    dispatch(restoreRoom()).then((res) => {
      if (res && res.roomCode) {
        socketReconnectToRoom({ room_code: res.roomCode });
      }
    });
  }, [
    dispatch,
    currentUsersRoomGameCode,
    gameStatus,
    isCurrentPlayerSpectator,
  ]);

  if (width < 1000 || height < 730)
    return <SmallScreenAlert disableLogo={true} />;

  return (
    <div id="game-page-wrapper">
      {loading ? (
        <GameLoading />
      ) : (
        // <p>hi</p>
        <>
          <GameHeader isSpectator={isCurrentPlayerSpectator} />
          <GameContent isSpectator={isCurrentPlayerSpectator} />
        </>
      )}
    </div>
  );
};

function GameLoading() {
  return (
    <>
    <div className="game-loading">
      <h2>Entering game ... </h2>
      <ProgressBar
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
    </>
  );
}

export default Game;

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import LobbyHeader from "../../components/Lobby/LobbyHeader";
import LobbyContent from "../../components/Lobby/LobbyContent";
import SmallScreenAlert from "../../components/SmallScreenAlert";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { restoreRoom } from "../../store/room";
import { restoreLogs } from "../../store/logs";
import { socketReconnectToRoom } from "../../lib/socket/socketConnection";
import "./Lobby.css";
import FixedFeedbackLink from "../../components/FixedFeedbackLink";
import { getIsCurrentPlayerSpectator, restoreRoomGame } from "../../store/game";
import { getCurrentUser } from "../../store/session";

const Lobby = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

    const roomGameDetails = JSON.parse(localStorage.getItem("roomGameDetails"))
  const isSpectator = useSelector(getIsCurrentPlayerSpectator);
//   const roomGameDetails = JSON.parse(
//     sessionStorage.getItem("rooomGameDetails")
//   );

  useEffect(() => {
    dispatch(restoreRoom()).then((res) => {
      if (!res) {
        history.push("/join-room");
      } else if (res.roomCode) {
        console.log("BOOOOOP reconnecting to room");
        socketReconnectToRoom({ room_code: res.roomCode });
        // history.push(`/room/${res.roomCode}`)
      }
    });
    dispatch(restoreLogs());
    // dispatch(restoreRoomGame())
    //     .then((res) => {
    //         if (res ) {
    //             if (currentUser.id in res.startingPlayers) {
    //                 history.push(`/game/${res.roomGameCode}`)
    //             }
    //         }
    //     })
  }, [dispatch, history]);

//   useEffect(() => {
//     // console.log(isSpectator, "IS SPECTATOR")
//     if (roomGameDetails && !isSpectator) {
//       history.push(`/game/${roomGameDetails["roomGameCode"]}`);
//     }
//   }, [history, isSpectator, roomGameDetails]);

    // if (roomGameDetails) return history.push(`/game/${roomGameDetails["roomGameCode"]}`)
  if (width < 1000 || height < 730) {
    return <SmallScreenAlert disableLogo={true} />;
  }

  return (
    <>
      <div id="lobby-wrapper">
        <LobbyHeader />
        <LobbyContent />
        <FixedFeedbackLink />
      </div>
    </>
  );
};

export default Lobby;

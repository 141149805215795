import { Container, Circle } from "./StyledToggle";

export default function ToggleButton({show, ...props}) {
    return (
        <ToggleButton.Container show={show} {...props}>
            <Circle /> 
        </ToggleButton.Container>
    );
};

ToggleButton.Container = function ToggleButtonContainer({ show, children, ...restProps}) {
    return (
        <Container $show={show} {...restProps}>{children}</Container>
    );
};
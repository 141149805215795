import styled from 'styled-components';

const defaultStyleOptions = {
    "dot1": "#F24848",
    "dot2": "#8F6BF4",
    "titleBackground": "#FF7FB5",
    "contentBackground": "#D8F5D3"
}

export default function RetroWindowA ({ 
    content,
    styleOptions={ ...defaultStyleOptions }
}) {
    return (
        <Container style={{backgroundColor: `${styleOptions["contentBackground"]}`}}>
            <RetroWindowA.Title title={ content.title } styleOptions={ styleOptions }/>
            <RetroWindowA.Content content={ content.content }/>
        </Container>
    );
};

RetroWindowA.Title = function RetroWindowATitle({ title, styleOptions }) {
    return (
        // <TitleContainer backgroundColor={ styleOptions["titleBackground"] }>
        <TitleContainer style={{backgroundColor: `${styleOptions["titleBackground"]}`}} >
            <DotsContainer>
                {/* <Dot backgroundColor={ styleOptions["dot1"] }/> */}
                <Dot style={{backgroundColor: `${styleOptions["dot1"] }`}}/>
                <Dot style={{backgroundColor: `${styleOptions["dot2"] }`}}/>
                {/* <Dot backgroundColor={ styleOptions["dot2"] }/> */}
            </DotsContainer>
            { title }
        </TitleContainer>
    )
}

RetroWindowA.Content = function RetroWindowAContent({ content }) {
    return (
        <>
        { content }
        </>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    // background-color: ${props => props.backgroundColor};
    border: 3px solid black;
    border-radius: 24px;
    // min-height: 600px;
    text-transform: lowercase;
`

const TitleContainer = styled.div`
    width: 100%;
    height: 60px;
    text-transform: lowercase;
    border-radius: 21px 21px 0px 0px;
    border-bottom: 3px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    // background-color: ${props => props.backgroundColor};

    @media (max-width: 700px) {
        font-size: 1.5em;
    }

    @media (max-width: 500px) {
        font-size: 1.3em;
    }
`

const DotsContainer = styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    top: 15px;
    left: 16px;

    @media (max-width: 700px) {
        top: 17px;
    }
`

const Dot = styled.div`
    border-radius: 999px;
    width: 27px;
    height: 27px;
    border: 3px solid black;
    // background-color: ${props => props.backgroundColor};
    transition: 0.2s all linear;
    @media (max-width: 700px) {
        width: 20px;
        height: 20px;
    }
`



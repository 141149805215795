import React from "react";
import { Linkedin, Twitter } from "react-feather";
import { FaKickstarterK } from "react-icons/fa6";
import "./index.css";

const Footer = () => {
  return (
    <section id="footer-container">
      <div>
        <p>letterheist</p>
        <p style={{fontSize: "0.75em"}}>contact us: team@letterheist.com</p>
      </div>

      <div>
        <ul id="social-links-list">
          <li className="social-link-item-container">
            <a
              href="https://www.linkedin.com/company/letterheist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </li>
          <li className="social-link-item-container">
            <a
              href="https://www.twitter.com/letterheist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          </li>
          <li className="social-link-item-container">
            <a
              href="https://www.kickstarter.com/projects/shannonyhong/letterheist"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaKickstarterK style={{width: "24px", height: "24px"}}/>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;

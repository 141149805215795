import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const TabBarOptions = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
`

const TabBarOptionsItem = ({ className, children, ...restProps}) => {
    return (
        <div className={className} {...restProps}>
            {children}
        </div>
    )
}

export const StyledTabBarOptionsItem = styled(TabBarOptionsItem)`
    width: max-content;
    padding: 2px 8px;
    // background-color: ${props => props.currentOption === props.selectedOption ? "black" : "inherit"};
    // color: ${props => props.currentOption === props.selectedOption ? "white" : "black"};
    color: black;
    // margin: 0 auto;
    border: 1px solid black;
    border-radius: 999px;
    cursor: pointer;
    transition: 0.3s all ease;

    ${ props => props.$isSelected && css`
        background-color: black;
        color: white
    `}

`


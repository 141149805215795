import styled from "styled-components";
import { Colors } from "../../../styles/Colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    height: 100%;
    // max-height: 100%;
    // max-height: calc(100vh - 50px)
`

export const LogItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    // max-height: calc(100vh - 286px);
    // min-height: calc(100vh - 268px);
    // max-height: calc(100vh - 268px);
    width: 100%;
    overflow-y: auto;
    scrollbar-width: thin;


    > * {
        &:first-child {
            margin-top: auto !important;
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(201, 201, 201, 0.25);
        border-radius: 16px;

    }
`
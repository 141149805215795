import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  getRoomGameCode,
  getGameID,
  getRemainingLettersCount,
  getGameCurrentPlayer,
} from "../../../store/game";
import { getTimerData } from "../../../store/game";
import { socketAutoUncoverLetter } from "../../../lib/socket/socketConnection.js";
import { PlayerStyles } from "../../../data/styleTypes.js";
import { getSecondsBetweenLetters } from "../../../store/room.js";

const Timer = () => {
  // initialize timeLeft with the seconds prop
  const timerData = useSelector(getTimerData());
  const roomGameCode = useSelector(getRoomGameCode());
  const gameData = useSelector(getGameID());
  const remainingLettersCount = useSelector(getRemainingLettersCount());
  const currentPlayer = useSelector(getGameCurrentPlayer);
  const secondsBetweenLetters = useSelector(getSecondsBetweenLetters)
  const [timeLeft, setTimeLeft] = useState(secondsBetweenLetters);
  const [timerCode, setTimerCode] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [remainingLetters, setRemainingLetters] = useState(null);

  
  // console.log(currentPlayer)

  // const determineTimeAndSetState = (expirationUtc) => {
  //   const expiration = new Date(expirationUtc);
  //   const currentTime = new Date();
  //   const secondsLeft = Math.floor((expiration - currentTime) / 1000);
  //   setTimeLeft(secondsLeft);
  // };
  const determineTimeAndSetState = (expirationUtc) => {
    // Parse the UTC string to get a Date object
    const expirationUtcDate = new Date(expirationUtc).getTime();

    // Get the current time in the local time zone
    const currentTime = new Date();

    // // Get the time zone of the current locale
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // // Calculate the offset between UTC and the local time zone
    const timeZoneOffset = currentTime.getTimezoneOffset() * 60 * 1000;

    //local time utc
    const currentLocalTimeUTC = new Date().getTime();
    // const currentLocalTimeUTC = new Date();

    const secondsLeft = Math.floor(
      (expirationUtcDate - currentLocalTimeUTC - timeZoneOffset) / 1000
    );
    // Calculate the difference in seconds, considering the time zone
    // const secondsLeft = Math.floor(
    //   (expirationUtcDate - timeZoneOffset - currentTime) / 1000
    // );
    // console.log("utc", expirationUtcDate, secondsLeft);

    // Set the time left state
    setTimeLeft(secondsLeft);
    // console.log(
    //   "timer data",
    //   "curr",
    //   currentLocalTimeUTC,
    //   "exp",
    //   expirationUtcDate,
    //   "time left",
    //   secondsLeft
    // );
    // console.log("time left", secondsLeft);
  };

  useEffect(() => {
    if (timerData?.code) {
      setTimerCode(timerData?.code);
    }
    if (timerData?.expiration_utc) {
      //determine current time indicated by timerData?.get('expiration_utc') as expiration
      //determine seconds left until expiration and setTimeLeft
      // setTimeLeft(timerData.get("expiration_utc"));
      // determineTimeAndSetState(timerData.get('expiration_utc'))

      // const intervalId = setInterval(() => {
      //   // calculateTimeLeft();
      //   determineTimeAndSetState(timerData.get("expiration_utc"));
      // }, 1000);

      // // Cleanup interval on component unmount
      // return () => clearInterval(intervalId);

      const intervalId = setInterval(() => {
        determineTimeAndSetState(timerData?.expiration_utc);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timerData]);

  useEffect(() => {
    // exit early when we reach 0
    if (remainingLettersCount && timeLeft <= 0) {
      // need to add a letter
      // socketUncoverLetter({'room_code': roomCode});
      // console.log("time is up");
      socketAutoUncoverLetter({
        room_game_code: roomGameCode,
        timer_code: timerCode,
        game_id: gameId,
      });
    }
  }, [timeLeft, remainingLettersCount]);

  useEffect(() => {
    if (gameData) {
      // console.log("gameData", gameData);
      setGameId(gameData);
    }
  }, [gameData]);

  useEffect(() => {
    if (remainingLettersCount) {
      setRemainingLetters(remainingLettersCount);
    }
  }, [remainingLettersCount]);

  // save intervalId to clear the interval when the
  // component re-renders
  // const intervalId = setInterval(() => {
  //   setTimeLeft(timeLeft - 1);
  // }, 1000);

  // clear interval on re-render to avoid memory leaks
  //   return () => clearInterval(intervalId);
  //   // add timeLeft as a dependency to re-rerun the effect
  //   // when we update it
  // }, [timeLeft]);

  if (!remainingLettersCount || timeLeft <= 0)
    return (
      <h1>
        <br></br>{" "}
      </h1>
    );

  return <div 
    style={{ 
      backgroundColor: currentPlayer && PlayerStyles[currentPlayer?.style]["color"], 
      padding: "2px 8px", 
      borderRadius: "999px"
    }}>:{timeLeft}</div>;
};

export default Timer;

import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from 'react-redux';

import { createRoom, getRoomCode } from '../../store/room';
import { socketJoinRoom } from '../../lib/socket/socketConnection';



const CreateRoomForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const roomCode = useSelector(getRoomCode);

    const handleCreateRoom = (e) => {
        e.preventDefault();
        // console.log("creating room")
        dispatch(createRoom())
            .then((res) => {socketJoinRoom({'room_code': res.room_code})})
    }

    useEffect(() => {
      if (roomCode) {
        history.push(`/room/${roomCode}`)
      }
    }, [history, roomCode])

    return (
      <CreateRoomButton onClick={handleCreateRoom}>create a room</CreateRoomButton>
    )
}

const CreateRoomButton = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 0.7em 1em;
  background-color: #AACCF4;
  font-size: 2em;
  border: 3px solid black;
  box-shadow: 0px 3px;
  cursor: pointer;

  &:active {
    transform: translateY(3px); 
    box-shadow: 0px 0px;
  }

  @media (max-width: 520px) {
    font-size: 1.5em;
  }
`

export default CreateRoomForm;
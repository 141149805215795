import styled from "styled-components";
import { Colors } from "../../../../styles/Colors";

export const Container = styled.div`
  border: 2px solid ${Colors.BLACK};
  border-radius: 24px;
  // min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

export const LetterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-bottom: 3px;
  padding-top: 3px;
  gap: 8px;
  max-height: calc(100vh - 547px);
  overflow-y: auto;

  @media (max-height: 834px) {
    gap: 4px;
  }
  @media (max-width: 1050px) {
    gap: 4px;
  }
`;

export const Item = styled.div`
  font-size: 2em;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.GRAY_600};
  border: 2px solid ${Colors.GRAY_600};
  border-radius: 999px;
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dce4c4;
  user-select: none;

  @media (max-height: 834px) {
    font-size: 1.5em;
    width: 48px;
    height: 48px;
  }

  @media (max-width: 1050px) {
    font-size: 1.3em;
    width: 42px;
    height: 42px;
  }

  @media (max-height: 750px) {
    font-size: 1.3em;
    width: 42px;
    height: 42px;
  }
`;

export const AddLetterButton = styled.div`
  text-transform: uppercase;
  padding: 0.4em 1.3em;
  font-size: 1.4em;
  color: ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.25)" : Colors.BLACK)};
  border: 2px solid
    ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.25)" : Colors.BLACK)};
  border-radius: 999px;
  width: max-content;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.disabled ? "rgba(215, 215, 198, 0.25)" : "#D7D7C6"};
  user-select: none;

  &:active {
    transform: ${(props) => !props.disabled && "translateY(2px)"};
  }

  @media (max-height: 834px) {
    font-size: 1.2em;
  }

  @media (max-width: 1050px) {
    font-size: 1.2em;
  }
`;

export const LetterCount = styled.div`
  // margin-top: 20px;
  font-size: 0.875em;
`;

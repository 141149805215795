import Octopus from "../assets/images/avatar-icons/animals/octopus.png"
import Hen from "../assets/images/avatar-icons/animals/hen.png"
import Cat from "../assets/images/avatar-icons/animals/cat.png"
import Sheep from "../assets/images/avatar-icons/animals/sheep.png"
import Shark from "../assets/images/avatar-icons/animals/shark.png"
import Snail from "../assets/images/avatar-icons/animals/snail.png"

export const allAvatars = [
    Sheep,
    Cat,
    Octopus,
    Hen,
    Snail,
    Shark,
]
import { useState } from 'react';
import 'animate.css';
import { Menu } from 'react-feather';
import "./index.css"

const HamburgerSideNavigation = ({tabs, activeTab, setActiveTab}) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const handleMenuClick = () => {
        setIsOpen((prev) => !prev)
        // console.log(isOpen)
    }

    const handleNavItemClick = (i) => {
        setIsOpen((prev) => !prev)
        setActiveTab(i)
    }

    return (
        <>
            <div className="hamburger-menu-button" onClick={handleMenuClick}>
                <Menu />
            </div>
            {
                isOpen && 
                <HamburgerSideNavigation.NavBar closeNav={setIsOpen}>
                {Object.values(tabs).map((tab, i) => (
                    <div
                        style={{backgroundColor: `${activeTab === i && "#FFFFFF"}`}}
                        className={`hamburger-menu-side-nav_nav-item`}
                        onClick={() => handleNavItemClick(i)} 
                        key={i} 
                        id={i}
                        >
                            <div>{tab.icon}</div>
                            <div>{tab.name}</div>
                    </div>
                ))}
                </HamburgerSideNavigation.NavBar>
            }
        </>
    )
}

HamburgerSideNavigation.NavBar = function ({children, closeNav, isOpen}) {
    const handleCloseNav = () => {
        closeNav(false)
    }
    return (
        <>
        <div className="nav-wrapper animate__animated animate__slideInRight">
            <div className="open-menu-backdrop" onClick={handleCloseNav}>
            </div>
            <nav className="hamburger-menu-side-nav">
                {children}
            </nav>
        </div>
        
        </>
    )
}

export default HamburgerSideNavigation;
import styled from "styled-components";

export const Text = styled.p`
    text-align: center;
    // margin-bottom: 8px;
    line-height: 1.2;
    font-size: 0.875em;
    width: 300px;
    display: block;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
`



export const WarningText = styled.span`
    color: red;
    margin-top: 8px;
`
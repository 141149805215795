import "animate.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import FinalContemplation from "./FinalContemplation.js";
import Timer from "./Timer.js";
import { socketUncoverLetter } from "../../../lib/socket/socketConnection.js";
import { createStructuredSelector } from "reselect";
import { getCurrentUser } from "../../../store/session.js";
import {
  Container,
  Item,
  AddLetterButton,
  LetterContainer,
  LetterCount,
} from "./styles/StyledLetterBank.js";
import {
  selectUncoveredLetters,
  getFinalContemplationState,
  getCurrentPlayerTurn,
} from "../../../store/game.js";
import { useKeyboardShortcut } from "../../../hooks/useKeyboardShortcut.js";

export default function LetterBank({ roomGameCode, ...restProps }) {
  const [disabled, setDisabled] = useState(false);

  const {
    uncoveredLetters,
    isFinalContemplation,
    currentPlayer,
    currentPlayerTurn,
  } = useSelector(
    createStructuredSelector({
      uncoveredLetters: selectUncoveredLetters,
      isFinalContemplation: getFinalContemplationState,
      currentPlayer: getCurrentUser,
      currentPlayerTurn: getCurrentPlayerTurn,
    })
  );

  useEffect(() => {
    setDisabled(currentPlayer.id !== currentPlayerTurn || isFinalContemplation);
  }, [currentPlayer, currentPlayerTurn, isFinalContemplation]);

  return (
    <Container {...restProps}>
      <LetterContainer>
        {uncoveredLetters?.map((letter, index) => (
          <LetterBank.Item key={index}>{letter}</LetterBank.Item>
        ))}
      </LetterContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginTop: "20px",
            marginBottom: "8px",
          }}
        >
          <LetterBank.LetterCount />
          <Timer />
        </div>
        {isFinalContemplation ? (
          <FinalContemplation />
        ) : (
          <LetterBank.AddLetterButton
            roomGameCode={roomGameCode}
            disabled={disabled}
          />
        )}
      </div>
    </Container>
  );
}

LetterBank.Item = function LetterBankItem({ children, ...restProps }) {
  return (
    <Item className="animate__animated animate__bounceIn" {...restProps}>
      {children}
    </Item>
  );
};

LetterBank.LetterCount = function LetterBankCount() {
  const remainingCount = useSelector(
    (state) => state.game.remainingLettersCount
  );
  return <LetterCount>{remainingCount} letters remaining</LetterCount>;
};

LetterBank.AddLetterButton = function LetterBankAddLetterButton({
  roomGameCode,
  disabled,
}) {
  const handleAddNewLetter = () => {
    if (!disabled) {
      socketUncoverLetter({ room_game_code: roomGameCode });
      document.getElementById("word-entry-input-box").focus()
    }
  };

  useKeyboardShortcut(handleAddNewLetter, ["ShiftLeft", "ShiftRight"]);

  return (
    <AddLetterButton disabled={disabled} onClick={handleAddNewLetter}>
      Add New Letter
    </AddLetterButton>
  );
};

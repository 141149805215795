import { useSelector } from "react-redux";
import { AiOutlineSmile } from "react-icons/ai";

import {
    Container,
    Filler,
    Text
} from "./styles/StyledWordSteal.js";
import { getCurrentUser } from "../../../store/session.js";
import { PlayerStyles } from "../../../data/styleTypes.js";

export default function WordSteal({steal}) {
    const players = useSelector(state => state.game.startingPlayers);
    const currentUser = useSelector(getCurrentUser)

    const currentOwner = () => {
        return players[`${steal.owner_id}`].displayName;
    }

    const wordColor = (id) => {
        return PlayerStyles[players[`${id}`].style]["color"]
    }

    const displayText = () => {
        if (currentUser.id === steal.owner_id){
            return <Text>{`[ upgrade `} <span className="steal-word" style={{backgroundColor: wordColor(currentUser.id)}}>{steal.current}</span> {` ]`}</Text>
        } else {
            return <Text>{` [ stealing `} <span className="steal-word" style={{backgroundColor: wordColor(steal.owner_id)}}>{steal.current}</span> {`from ${currentOwner()} ]`}</Text>
        }
    }
    return (
        <Container>
            {steal 
            ? displayText()
            : <Filler>[ <AiOutlineSmile /> ]</Filler>
        }
        </Container>
    );
};


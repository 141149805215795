// SELECTORS

import { createSelector } from "@reduxjs/toolkit";
// const apiURL = process.env.REACT_APP_API_URL;

// Root Selector
const selectRoomEntity = (state) => state.room;
export const selectRoomPlayers = (state) => state.room.players;
// const selectRoomScorecards = state => state.room.scorecards ? state.room.scorecards : {};
export const selectRoomScorecardId = (state, scorecardId) => scorecardId;
export const getPlayersInActiveGame = (state) => state.room.playersInActiveGame;
export const getRoomPlayers = () => (state) =>
  state.room.players ? Object.values(state.room.players) : [];

export const getRoomCode = (state) => state.room.roomCode;
export const getSecondsBetweenLetters = (state) => state.room.secondsBetweenLetters;
export const getJoinRoomErrors = () => (state) =>
  state.errors.room ? state.errors.room.join : null;

const getCurrentUser = createSelector(
  (state) => state.session,
  (session) => {
    return session.user;
  }
);
export const getScorecards = createSelector(selectRoomEntity, (room) => {
  return room.scorecards;
});

export const getCurrentGameScorecard = createSelector(
  [getScorecards, selectRoomScorecardId, getCurrentUser],
  (scorecards, scorecardId, currentUser) => {
    // console.log("scorecards", scorecards);
    const scorecard = scorecards[scorecardId];
    const currentUserVictoryStatus =
      scorecard?.finalRankingAndPlayerScores[currentUser.id]?.is_winner;
    // return scorecard;
    return {
      scorecard: scorecard,
      currentUser: currentUser,
      currentUserVictoryStatus: currentUserVictoryStatus,
    };
  }
);

// export const getLobbyPlayers = createSelector(
//   [selectRoomPlayers, getPlayersInActiveGame],
//   (playersInLobby, playersInActiveGame) => {
//     let lobbyPlayers = {};
//     for (let playerId in playersInLobby) {
//       lobbyPlayers[playerId] = {
//         inLobby: playersInLobby[playerId].inLobby,
//         readyToPlay: playersInLobby[playerId].readyToPlay,
//         inActiveGame: false,
//         displayName: playersInLobby[playerId].displayName,
//         style: playersInLobby[playerId].style
//       };
//       if (playerId in playersInActiveGame)
//         lobbyPlayers[playerId].inActiveGame = true;
//     }
//     return lobbyPlayers;
//   }
// );



export const getHasActiveGame = createSelector(selectRoomEntity, (room) => {
  return room.hasActiveGame;
});

export const getCurrentUserReadyState = createSelector(
  getCurrentUser,
  selectRoomPlayers,
  (currentUser, roomPlayers) => {
    // expensive computation
    // console.log(roomPlayers, currentUser);
    const currentUserData = roomPlayers[String(currentUser.id)];
    // console.log("inside createSelector, currentUserData:", currentUserData);
    return currentUserData?.readyToPlay;
  }
);
// export const getScorecards = () => state => (state.room.scorecards ? state.room.scorecards : [])

// CONSTANTS
const SET_ROOM = "room/SET_ROOM";
const UPDATE_ROOM_STATE = "room/UPDATE_ROOM_STATE";
const CLEAR_ROOM = "room/CLEAR_ROOM";

const RECEIVE_JOIN_ROOM_ERRORS = "room/RECEIVE_JOIN_ROOM_ERRORS";
const RECEIVE_LEAVE_ROOM_ERRORS = "room/RECEIVE_LEAVE_ROOM_ERRORS";
const CLEAR_ROOM_ERRORS = "room/CLEAR_ROOM_ERRORS";

// const RECEIVE_ROOM_SCORECARDS = 'room/RECEIVE_ROOM_SCORECARDS';

// ACTION CREATORS
export const setRoom = (payload) => ({
  type: SET_ROOM,
  payload,
});

export const updateRoom = (payload) => ({
  type: UPDATE_ROOM_STATE,
  payload,
});

export const clearRoom = () => ({
  type: CLEAR_ROOM,
});

// export const receiveRoomScorecards = (payload) => ({
//     type: RECEIVE_ROOM_SCORECARDS,
//     payload
// })

// ACTION CREATORS FOR ERRORS
export const setJoinRoomErrors = (payload) => ({
  type: RECEIVE_JOIN_ROOM_ERRORS,
  payload,
});

export const setLeaveRoomErrors = (payload) => ({
  type: RECEIVE_LEAVE_ROOM_ERRORS,
  payload,
});

export const clearRoomErrors = () => ({
  type: CLEAR_ROOM_ERRORS,
});

// HELPER FUNCTIONS

export const storeCurrentRoom = (roomDetails) => {
  // console.log("store room being hit", roomDetails);
  if (roomDetails) {
    // sessionStorage.setItem("roomDetails", JSON.stringify(roomDetails));
    localStorage.setItem("roomDetails", JSON.stringify(roomDetails));
  } else {
    // sessionStorage.removeItem("roomDetails");
    localStorage.removeItem("roomDetails");
  }
};

export const restoreRoom = () => async (dispatch) => {
  // console.log("restore room being hit");
  // const room = JSON.parse(sessionStorage.getItem("roomDetails"));
  const room = JSON.parse(localStorage.getItem("roomDetails"));
  // console.log(room);
  if (room) {
    // console.log("in here", room);
    dispatch(updateRoom(room));
    // console.log("hello");
  }
  // console.log(room, "this is the room info");
  return room;
};

export const resetRoom = () => async (dispatch) => {
  // console.log("reset room being hit");
  dispatch(clearRoom());
  storeCurrentRoom(null);
};

// THUNK ACTION CREATORS
export const createRoom = () => async (dispatch) => {
  // console.log("creating room");
  // let res = await fetch("/room/create", {
  let res = await fetch(
    `https://letterheist-3b560da0eac4.herokuapp.com/room/create`,
    // `http://127.0.0.1:5000/room/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const data = await res.json();
  if (res.ok) {
    // console.log(data.new_room);
    return data.new_room;
  } else if (res.status < 500) {
    // console.log("errors: ", data.errors);
    if (data.errors) return data.errors;
  }
};

const initialRoomState = {
  roomCode: null,
  roomId: null,
  players: {},
  isActive: false,
  atCapacity: false,
  isEmpty: true,
  hasActiveGame: false,
  hasUnstartedGame: true,
  scorecards: {},
  secondsBetweenLetters: 10,
};

const roomReducer = (state = initialRoomState, action) => {
  switch (action.type) {
    case SET_ROOM:
      return { ...state, ...action.payload };
    case UPDATE_ROOM_STATE:
      return { ...state, ...action.payload };
    case CLEAR_ROOM:
      return initialRoomState;
    // case RECEIVE_ROOM_SCORECARDS:
    //     return { ...state, scorecards: action.payload }
    default:
      return state;
  }
};

const nullErrors = null;
export const roomErrorsReducer = (state = nullErrors, action) => {
  switch (action.type) {
    case RECEIVE_JOIN_ROOM_ERRORS:
      return { join: action.payload };
    case RECEIVE_LEAVE_ROOM_ERRORS:
      return { leave: action.errors };
    case CLEAR_ROOM_ERRORS:
      return nullErrors;
    default:
      return state;
  }
};

export default roomReducer;

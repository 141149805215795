import styled from "styled-components";
import { Colors } from "../../../../styles/Colors";

export const Container = styled.div`
  width: 100%;
  // border: 1px solid red;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.GRAY_600};
`;

export const Text = styled.p`
  font-size: 1.2em;

  .steal-word {
    width: max-content;
    padding: 4px 12px;
    border-radius: 999px;
    text-transform: uppercase;
    color: ${Colors.BLACK};
  }
`;

export const Filler = styled.div`
  width: 100%;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  white-space: pre-wrap;

  @media (max-height: 834px) {
    font-size: 1.5em;
  }
`;

import { socketLeaveRoomGameSpectator } from "../../lib/socket/socketConnection";
import { StyledLeaveRoomButton } from "./StyledButtons";

const SpectatorReturnToLobbyButton = ({roomGameCode}) => {

    const handleReturnToLobbyAsSpectator = () => {
        socketLeaveRoomGameSpectator({room_game_code: roomGameCode});
    }

    return (
        <StyledLeaveRoomButton onClick={handleReturnToLobbyAsSpectator}>
            Return to lobby
        </StyledLeaveRoomButton>
    )
};

export default SpectatorReturnToLobbyButton;
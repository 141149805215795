import { createSelector, current } from "@reduxjs/toolkit";
const getCurrentUser = createSelector(
  state => state.session,
  (session) => {
    return session.user
  }
)
// SELECTORS
export const getPlayers = () => (state) =>
  state.game.players ? Object.values(state.game.players) : null;
export const getWordsFormed = () => (state) =>
  state.game.wordsFormed ? Object.values(state.game.wordsFormed) : [];
export const getGameStatus = (state) => state.game.gameStatus;
export const getUncoveredLetters = () => (state) => state.game.uncoveredLetters;
export const getFinalContemplation = () => (state) =>
  state.game.inFinalContemplation;
export const getWordEntryErrors = () => (state) =>
  state.errors.game.wordEntry ? state.errors.game.wordEntry : [];
export const getRoomGameCode = () => (state) => state.game.roomGameCode;
export const getFinalResults = () => (state) => state.game.finalGameResults;
export const getTimerData = () => (state) => state.game.timer;
export const getGameID = () => (state) => state.game.gameId;
export const getRemainingLettersCount = () => (state) =>
  state.game.remainingLettersCount;

export const selectRoomGameCode = state => state.game.roomGameCode;
export const selectStartingPlayers = state => state.game.startingPlayers;
export const selectWordsFormed = state => state.game.wordsFormed;
export const selectUncoveredLetters = state => state.game.uncoveredLetters;
export const getFinalContemplationState = state => state.game.inFinalContemplation;
export const getGamePlayers = state => state.game.players ? state.game.players : {};
export const getCurrentPlayerTurn = state => state.game.currentPlayerId;

export const getIsCurrentPlayerSpectator = createSelector(
  [getCurrentUser, getGamePlayers],
  (currentUser, gamePlayers) => {
    return !(currentUser.id in gamePlayers)
  }
)

export const getGameCurrentPlayer = createSelector(
  [getCurrentPlayerTurn, getGamePlayers],
  (currentPlayerId, gamePlayers) => {
    return gamePlayers[currentPlayerId]
  }
)

// CONSTANTS
const SET_GAME_STATE = "game/SET_GAME_STATE";
const UPDATE_GAME_STATE = "game/UPDATE_GAME_STATE";
const CLEAR_GAME_STATE = "game/CLEAR_GAME_STATE";
const ADD_NEW_LETTER = "game/ADD_NEW_LETTER";
const REMOVE_LETTERS_AFTER_GUESS = "game/REMOVE_LETTERS_AFTER_GUESS";
const UPDATE_FINAL_CONTEMPLATION = "game/UPDATE_FINAL_CONTEMPLATION";
const SET_ROOM_GAME_CODE = "game/SET_ROOM_GAME_CODE";
const END_GAME_STATE = "game/END_GAME_STATE";
const STORE_STARTING_PLAYERS = "game/STORE_STARTING_PLAYERS";
const CLEAR_ROOM_GAME_CODE = "game/CLEAR_ROOM_GAME_CODE";
const CLEAR_STARTING_PLAYERS = "game/CLEAR_STARTING_PLAYERS"

const RECEIVE_START_GAME_ERRORS = "game/RECEIVE_START_GAME_ERRORS";
const RECEIVE_CREATE_GAME_ERRORS = "game/RECEIVE_CREATE_GAME_ERRORS";
const RECEIVE_GET_GAME_STATE_ERRORS = "game/RECEIVE_GET_GAME_STATE_ERRORS";
const RECEIVE_UNCOVER_LETTER_ERRORS = "game/RECEIVE_UNCOVER_LETTER_ERRORS";
const RECEIVE_WORD_ENTRY_ERRORS = "game/RECEIVE_WORD_ENTRY_ERRORS";
const CLEAR_GAME_ERRORS = "game/CLEAR_GAME_ERRORS";

// ACTION CREATORS
export const setGameState = (payload) => ({
  type: SET_GAME_STATE,
  payload,
});

export const updateGameState = (payload) => ({
  type: UPDATE_GAME_STATE,
  payload,
});

export const endGameState = (payload) => ({
  type: END_GAME_STATE,
  payload,
});

export const clearGameState = () => ({
  type: CLEAR_GAME_STATE,
});

export const addNewLetter = (payload) => ({
  type: ADD_NEW_LETTER,
  payload,
});

export const removeLettersAfterGuess = (payload) => ({
  type: REMOVE_LETTERS_AFTER_GUESS,
  payload,
});

export const setRoomGameCode = (payload) => ({
  type: SET_ROOM_GAME_CODE,
  payload,
});

export const clearRoomGameCode = () => ({
  type: CLEAR_ROOM_GAME_CODE
})

export const clearStartingPlayers = () => ({
  type: CLEAR_STARTING_PLAYERS
})

export const updateFinalContemplation = (payload) => ({
  type: UPDATE_FINAL_CONTEMPLATION,
  payload,
});

export const storeStartingPlayers = (payload) => ({
  type: STORE_STARTING_PLAYERS,
  payload
})

// ACTION CREATORS FOR ERRORS
export const receiveStartGameErrors = (payload) => ({
  type: RECEIVE_START_GAME_ERRORS,
  payload,
});

export const receiveCreateGameErrors = (payload) => ({
  type: RECEIVE_CREATE_GAME_ERRORS,
  payload,
});

export const receiveGetGameStateErrors = (payload) => ({
  type: RECEIVE_GET_GAME_STATE_ERRORS,
  payload,
});

export const receiveUncoverLetterErrors = (payload) => ({
  type: RECEIVE_UNCOVER_LETTER_ERRORS,
  payload,
});

export const receiveWordEntryErrors = (payload) => ({
  type: RECEIVE_WORD_ENTRY_ERRORS,
  payload,
});

export const clearGameErrors = () => ({
  type: CLEAR_GAME_ERRORS,
});



// HELPER FUNCTIONS

export const storeCurrentRoomGame = (roomGameDetails) => {
  // console.log("store room game being hit,", roomGameDetails);
  if (roomGameDetails) {
    localStorage.setItem("roomGameDetails", JSON.stringify(roomGameDetails));
    // sessionStorage.setItem("roomGameDetails", JSON.stringify(roomGameDetails));
  } else {
    localStorage.removeItem("roomGameDetails");
    // sessionStorage.removeItem("roomGameDetails");
  }
};

export const restoreRoomGame = () => async (dispatch) => {
  // console.log("restoring room game details");
  const roomGameDetails = JSON.parse(localStorage.getItem("roomGameDetails"));
  // const roomGameDetails = JSON.parse(sessionStorage.getItem("roomGameDetails"));
  // console.log("room game details:", roomGameDetails);
  if (roomGameDetails) {
    dispatch(setRoomGameCode(roomGameDetails.roomGameCode));
    dispatch(storeStartingPlayers(roomGameDetails.startingPlayers))
  }
  return roomGameDetails;
};

export const clearRoomGame = () => async (dispatch) => {
  storeCurrentRoomGame(null);
  dispatch(clearRoomGameCode());
  dispatch(clearStartingPlayers());
}

// THUNK ACTION CREATORS

// REDUCER
const initialGameState = {
  roomId: null,
  gameId: null,
  roomGameCode: null,
  gameStatus: null, 
  currentPlayer: null,
  players: null,
  uncoveredLetters: [],
  wordsFormed: {}, 
  remainingLettersCount: 0,
  letters: [],
  inFinalContemplation: false,
  timer: {},
};

const gameReducer = (state = initialGameState, action) => {
  switch (action.type) {
    case SET_GAME_STATE:
      return { ...state, ...action.payload };
    case UPDATE_GAME_STATE:
      return { ...state, ...action.payload };
    case END_GAME_STATE:
      return { ...state, ...action.payload };
    case CLEAR_GAME_STATE:
      return initialGameState;
    case ADD_NEW_LETTER:
      return { ...state, letters: [...state.letters, action.payload] };
    case REMOVE_LETTERS_AFTER_GUESS:
      return { ...state, letters: action.payload };
    case STORE_STARTING_PLAYERS:
      return { ...state, startingPlayers: action.payload};
    case CLEAR_STARTING_PLAYERS:
      return { ...state, startingPlayers: {}}
    case SET_ROOM_GAME_CODE:
      return { ...state, roomGameCode: action.payload };
    case CLEAR_ROOM_GAME_CODE: 
      return { ...state, roomGameCode: null}
    case UPDATE_FINAL_CONTEMPLATION:
      return { ...state, inFinalContemplation: action.payload };
    default:
      return state;
  }
};

const initialErrorsState = {
  startGame: null,
  createGame: null,
  uncoverLetter: null,
  getGameState: null,
  wordEntry: null,
};
export const gameErrorsReducer = (state = initialErrorsState, action) => {
  switch (action.type) {
    case RECEIVE_START_GAME_ERRORS:
      return { startGame: action.payload };
    case RECEIVE_CREATE_GAME_ERRORS:
      return { createGame: action.payload };
    case RECEIVE_UNCOVER_LETTER_ERRORS:
      return { uncoverLetter: action.payload };
    case RECEIVE_GET_GAME_STATE_ERRORS:
      return { getGameState: action.payload };
    case RECEIVE_WORD_ENTRY_ERRORS:
      return { wordEntry: action.payload };
    case CLEAR_GAME_ERRORS:
      return initialErrorsState;
    default:
      return state;
  }
};

export default gameReducer;

import styled from "styled-components";

const PAGE_COLORS = {
    defeat: {
        "red-200": "#F6C5C5",
        "red-400": "#F17171",
        "red-500": "#AF2525",
        "red-700": "#9B3636",
        "red-900": "#400E0E",
    },
    victory: {
        "green-200": "#E3F0E1",
        "green-400": "#BCDAC2",
        "green-500": "#316139",
        "green-700": "#68A067",
        "green-900": "#0D3B15"
    },
    grays: {
        "gray-100": "#F2E9E3",
        "gray-200": "#C3B8B8"
    }
}

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    border: 2px solid black;
    border-radius: 24px;
    background-color: #CA865E;
    background-color: ${props => props.$isWinner ? PAGE_COLORS.victory["green-200"] : PAGE_COLORS.defeat["red-200"]};
    padding: 10px 24px;
    gap: 10px;

    flex-direction: column;
`

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const RightContent = styled.div`
`

export const Title = styled.h1`
    font-size: 2.3em;
    text-align: center;
`

export const Subheader = styled.h2`
    text-align: center;
    font-size: 1.35em;
    margin-top: 16px;
    text-transform: uppercase;
`

export const PlayerScoresList = styled.div`
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 436px);
    min-height: calc(100vh - 436px);
    overflow-y: auto;
    

    > div:not(:last-child) {
        border-bottom: 1px solid ${PAGE_COLORS.grays["gray-200"]};
    }

    @media (max-width: 1050px) {
        max-height: calc(100vh - 416px);
        min-height: calc(100vh - 416px);
    }
`

export const PlayerScoresListItem = styled.div`
    display: flex;
    // flex-direction: column;
    // align-items: center;
    gap: 8px;
    // border-bottom: 1px solid ${PAGE_COLORS.grays["gray-200"]};
    padding: 8px 4px;

    section {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // gap: 8px;
    }

    .word-list {
        font-size: 0.875em;
        color: #979797;
    }

`

export const LongestEvolutionsList = styled.div`
    display: flex;
    flex-direction: column;
    // margin: 20px 0px;
`

export const LongestEvolutionListItem = styled.div`
    display: flex;
    justify-content: center;
    // border: 1px solid red;

`

export const LongestWordsList = styled.div`
    margin: 0px auto;
    text-align: center;
    
`

export const ButtonsContainer = styled.div`
    margin: 10px auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    gap: 12px;
`
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { continueAsGuest } from '../../store/session';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { errorMessage } from '../../utils/errorsUtil';
import styled from 'styled-components';

const ContinueAsGuestForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [nickname, setNickname] = useState("");
    const [errors, setErrors] = useState([]);

    const handleContinueAsGuest = (e) => {
        e.preventDefault();
        dispatch(continueAsGuest({'display_name': nickname}))
            .then((res) => {
                if (res) {
                    setErrors(res);
                } else {
                    history.push('/join-room');
                }
            });
    };

    return (
        <>
        <LoginForm onSubmit={handleContinueAsGuest} >
            <label>nickname</label>
            <input
                type="text"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)} 
                placeholder="nickname"/>
            <SubmitButton>continue</SubmitButton>
        </LoginForm>
        {errors && errorMessage(errors)}
        </>
    )
};

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-top: 1px solid #A59B9B;
    margin: 16px 0px;
    padding-top: 16px;


    input {
        width: 90%;
        padding: 0.5em 1em;
        border-radius: 12px;
        margin-bottom: 12px;
        margin-top: 4px;
        transition: box-shadow 0.3s ease;
        background-color: #E4DCDC;
        font-size: 0.7em;
    }

    input:focus {
        outline: none;
        box-shadow: 0 0 0 3px #979090;
    }

    input::placeholder {
        color: #A59B9B;
    }

    @media (max-width: 500px) {
        width: 95%;
    }
`

const SubmitButton = styled.button`
    border-radius: 12px;
    background-color: #55A659;
    text-transform: lowercase;
    text-align: center;
    padding: 0.5em 1em;
    font-size: 0.7em;
    width: 90%;
    cursor: pointer;
    transition: 0.3s all ease;

    &:hover {
        background-color: #84D98C;
    }
`

export default ContinueAsGuestForm;
import Cat from "../assets/images/avatar-icons/animals/cat.png";
import Hen from "../assets/images/avatar-icons/animals/hen.png";
import Octopus from "../assets/images/avatar-icons/animals/octopus.png";
import Shark from "../assets/images/avatar-icons/animals/shark.png";
import Sheep from "../assets/images/avatar-icons/animals/sheep.png";
import Snail from "../assets/images/avatar-icons/animals/snail.png";

import SmCat from "../assets/images/avatar-icons/animals/sm-cat.png";
import SmHen from "../assets/images/avatar-icons/animals/sm-hen.png";
import SmOctopus from "../assets/images/avatar-icons/animals/sm-octopus.png";
import SmShark from "../assets/images/avatar-icons/animals/sm-shark.png";
import SmSheep from "../assets/images/avatar-icons/animals/sm-sheep.png";
import SmSnail from "../assets/images/avatar-icons/animals/sm-snail.png";


export const PlayerStyles = {
	"player-green": {
		"color": "#AFD2B5",
		"icon": Sheep,
		"sm-icon": SmSheep
	},
	"player-pink": {
		"color": "#E7B4F0",
		"icon": Hen,
		"sm-icon": SmHen
	},
	"player-red": {
		"color": "#F2A1A1",
		"icon": Octopus,
		"sm-icon": SmOctopus
	},
	"player-orange": {
		"color": "#F1B269",
		"icon": Cat,
		"sm-icon": SmCat
	},
	"player-blue": {
		"color": "#B4CFF0",
		"icon": Snail,
		"sm-icon": SmSnail
	},
	"player-purple": {
		"color": "#C6C6E8",
		"icon": Shark,
		"sm-icon": SmShark
	}
}
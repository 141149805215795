import io from "socket.io-client";
import {
  setRoom,
  storeCurrentRoom,
  resetRoom,
  setJoinRoomErrors,
  updateRoom,
  setLeaveRoomErrors,
} from "../../store/room";
import {
  clearGameState,
  receiveCreateGameErrors,
  receiveStartGameErrors,
  receiveUncoverLetterErrors,
  setGameState,
  updateGameState,
  receiveWordEntryErrors,
  addNewLetter,
  removeLettersAfterGuess,
  updateFinalContemplation,
  storeCurrentRoomGame,
  endGameState,
  storeStartingPlayers,
  clearRoomGame,
} from "../../store/game";
import {
  formatGameState,
  formatRoomState,
  formatLogNotification,
  formatScorecard,
  // formatTimerData,
} from "../../utils/formatUtil";
import { clearLogs, updateLogs } from "../../store/logs";

// const apiURL = process.env.REACT_APP_API_URL;

let socket;

export const ConnectSocket = (dispatch, token, history, currentUserId) => {
  socket = io(
    "https://letterheist-3b560da0eac4.herokuapp.com/",
    // "http://127.0.0.1:5000",
    {
      // socket = io("http://127.0.0.1:5000", {
      extraHeaders: {
        token: token,
      },
    }
  );

  // <-------------------- LISTENERS -------------------->

  // listener test - delete later
  socket.on("connection_response", (data) => {
    // console.log("socket connected", data);
  });

  socket.on("disconnect", (data) => {
    // console.log("disconnected");
  });

  socket.on("response", (data) => {
    // console.log(data);
  });

  // ---- Room event listeners
  // socket.on('join_response', data => {
  //     console.log(data, 'response from backend upon attempting to join a room');
  // });

  socket.on("join_response", (data) => {
    // console.log("Server response:", data);
    if (data.error) {
      dispatch(setJoinRoomErrors(data.error));
    } else {
      const room = formatRoomState(data);
      storeCurrentRoom(room);
      dispatch(setRoom(room));
    }
  });

  socket.on("leave_room_response", (data) => {
    // console.log(data, "response to current user who left the room");
    if (data.error) {
      dispatch(setLeaveRoomErrors(data.error));
    }
    if (data.successful) {
      // console.log("hi");
      dispatch(resetRoom());
      dispatch(clearGameState());
      dispatch(clearLogs());
      dispatch(clearRoomGame());
      history.push("/join-room");
    }
  });

  socket.on("update_room_response", (data) => {
    // console.log("TO PEOPLE IN ROOM, NEW ROOM STATE:", data);
    const room = formatRoomState(data);
    dispatch(updateRoom(room));
    storeCurrentRoom(room);
  });

  socket.on("log_notification_response", (data) => {
    // console.log("logger data:", data);
    const logNotification = formatLogNotification(data);
    dispatch(updateLogs(logNotification));
    // storeCurrentLog(logNotification);
  });

  // socket.on("start_timer", (data) => {
  //   console.log("timer started", data);
  //   const timerData = formatTimerData(data);
  //   console.log(timerData, "timer data");
  //   dispatch(updateTimer(timerData));
  // });

  // ---- Game event listeners

  socket.on("leave_room_game_response", (data) => {
    // console.log(data, "response to current user who left the room game");
    if (data.error) {
      dispatch(setLeaveRoomErrors(data.error));
    }
    if (data.successful) {
      // console.log("RESETTTTTTTTINGGGGG", data);
      // dispatch(storeCurrentRoomGame(null))
      //   .then(() => dispatch(clearGameState()))
      //   .then(() => history.push(`/room/${data.roomCode}`));
      history.push(`/room/${data.roomCode}`);
      dispatch(clearRoomGame());
    }
  });

  socket.on("leave_room_game_spectator_response", (data) => {
    // console.log(data, "response to current user who left the room game");
    if (data.error) {
      dispatch(setLeaveRoomErrors(data.error));
    }
    if (data.successful) {
      // console.log("RESETTTTTTTTINGGGGG", data);
      // dispatch(storeCurrentRoomGame(null))
      // .then(() => dispatch(clearGameState()))
      // .then(() => history.push(`/room/${data.roomCode}`));
      history.push(`/room/${data.roomCode}`);
    }
  });

  socket.on("create_game_response", (data) => {
    // console.log(data, "backend res to creating a game");
    if (data.error) {
      dispatch(receiveCreateGameErrors(data.error));
    } else {
      const beforeGameStartState = formatGameState(data);
      const roomGameCode =
        beforeGameStartState.roomCode + beforeGameStartState.gameId;
      beforeGameStartState["roomGameCode"] = roomGameCode;
      dispatch(setGameState(beforeGameStartState));
      socketStartGame({ room_code: beforeGameStartState.roomCode });
    }
  });

  socket.on("start_game_response", (data) => {
    // console.log(data, "backend res to starting a game");
    if (data.error) {
      // console.log("error !");
      dispatch(receiveStartGameErrors(data.error));
    } else {
      const gameStartedState = formatGameState(data);
      const roomGameCode = gameStartedState.roomCode + gameStartedState.gameId;
      gameStartedState["roomGameCode"] = roomGameCode;
      // console.log(
      //   "within start game response:",
      //   gameStartedState,
      //   "currentUserId",
      //   currentUserId
      // );
      dispatch(updateGameState(gameStartedState));
      storeCurrentRoomGame({
        roomGameCode: roomGameCode,
        startingPlayers: gameStartedState.players,
      });
      dispatch(storeStartingPlayers(gameStartedState.players));
      if (currentUserId in gameStartedState.players) {
        socketJoinRoomGameSocket({ room_game_code: roomGameCode });
        // history.push(`/game/${roomGameCode}`);
        // track current user's game number -- use room entities scorecards length
      }
    }
  });

  socket.on("join_room_game_response", (data) => {
    if (data.error) {
      // console.log("error");
    } else {
      history.push(`/game/${data["room_game_code"]}`);
    }
  });

  socket.on("get_game_state_response", (data) => {
    // console.log(data, "game_state");
    if (data.error) {
      dispatch(setLeaveRoomErrors(data.error));
    } else {
      const gameState = formatGameState(data);
      const roomGameCode = gameState.roomCode + gameState.gameId;
      gameState["roomGameCode"] = roomGameCode;
      dispatch(setGameState(gameState));
    }
  });

  socket.on("uncover_letter_response", (data) => {
    // console.log(data, "backend res to uncovering a letter");
    if (data.error) {
      dispatch(receiveUncoverLetterErrors(data.error));
    } else {
      const newGameState = formatGameState(data);
      dispatch(updateGameState(newGameState));
      // console.log("BOOOOOP", data);
      dispatch(addNewLetter(data["letter"]));
    }
  });

  socket.on("guess_response", (data) => {
    // console.log(
    //   data,
    //   "backend res to attempting to form a word from letter bank"
    // );
    if (data.error) {
      dispatch(receiveWordEntryErrors(data.error));
    } else {
      const newGameState = formatGameState(data);
      // console.log(newGameState, "new game state");
      dispatch(updateGameState(newGameState));
      dispatch(removeLettersAfterGuess(data["uncovered_letters"]));
    }
  });

  socket.on("steal_response", (data) => {
    // console.log(data, "backend res to attemping to steal a word");
    if (data.error) {
      dispatch(receiveWordEntryErrors(data.error));
    } else {
      const newGameState = formatGameState(data);
      // console.log(newGameState, "new game state");
      dispatch(updateGameState(newGameState));
      dispatch(removeLettersAfterGuess(data["uncovered_letters"]));
    }
  });

  socket.on("current_player_info", (data) => {
    // console.log(data, "current player has been updated");
  });

  socket.on("cancel_game_response", (data) => {
    // console.log(data, "backend res to canceling a game");
  });

  socket.on("remove_spectators_from_game_room", (data) => {
    // console.log(data, "backend res to removing spectators from game room");
    //remove anyone still in the game
    // socketLeaveRoomGameSpectator(data);
  });

  socket.on("trigger_final_contemplation", (data) => {
    // console.log(data, "game is in final contemplation");
    if (!data.error) {
      const newGameState = formatGameState(data);
      // console.log(newGameState, "new game state in contemplation");
      dispatch(updateGameState(newGameState));
      dispatch(updateFinalContemplation(true));
      dispatch(removeLettersAfterGuess(data["uncovered_letters"]));
    }
    //show end game button
  });

  socket.on("game_over_info", (data) => {
    // console.log(data, "backend res to game over conditions");
    if (!data.error) {
      const scorecard = formatScorecard(data);
      // console.log(scorecard, "scorecard");
      history.push(`/game/results/${data["room_game_code"]}`);
      dispatch(endGameState(scorecard));

      // dispatch(clearRoomGame());

      // also want to clear roomGameDetails in sessionStorage
    }
    //show info in scorecard
  });

  socket.on("return_to_lobby_response", (data) => {
    if (!data.error) {
      // const roomCode = data["room_code"];
      history.push(`/room/${data}`)
    }
  })

  return socket;
};

// <-------------------- EVENT HANDLERS -------------------->

// Connection/predefined event handlers
export const socketSendMessage = (data) => {
  // console.log("sending a message");
  socket.send(data.message);
};

export const disconnectSocket = () => {
  // console.log("disconnecting socket");
  socket.disconnect();
};

// ---- Room event handlers

export const socketSendChatMessage = (data) => {
  // console.log("sending socket message");
  socket.emit("send_chat_message", data);
};

// JOIN ROOM data = { 'room_code': roomId }
// export const socketJoinRoom = (data) => {
//     console.log(data, 'emitting joining room');
//     socket.emit('join', data);
// }; // Response = 'join_response'
export const socketJoinRoom = (data) => {
  // console.log(data, "emitting joining room");
  socket.emit("join_room", data);
}; // Response = 'join_response'

export const socketReconnectToRoom = (data) => {
  // console.log(data, "emitting reconnecting to room");
  socket.emit("reconnect_to_room", data);
};

// LEAVE ROOM data = { 'room_code': roomId }
export const socketLeaveRoom = (data) => {
  // console.log(data, "emitting leaving room");
  socket.emit("leave", data);
}; // Response = 'leave_response'

export const socketCancelReady = (data) => {
  // console.log(data, "emitting canceling ready");
  socket.emit("cancel_ready", data);
};

export const socketAcceptReady = (data) => {
  // console.log(data, "emitting accepting ready");
  socket.emit("accept_ready", data);
};

export const socketReturnToLobby = (data) => {
  // console.log(data, "emitting return to lobby");
  socket.emit("return_to_lobby", data);
};

export const socketSetSecondsBetweenLetters = (data) => {
  socket.emit("set_seconds_between_letters", data); 
};

export const socketAutoUncoverLetter = (data) => {
  // console.log(data, "emitting auto uncover letter");
  socket.emit("auto_uncover_letter", data);
};

// // RECEIVE room's games' scorecards
// socket.on("room_scorecards", (data) => {
//   console.log(data, "room's games' scorecards");
//   if (!data.error) {
//     const newScorecardsState = formatScorecardsState(data);
//     console.log(newScorecardsState, "new scorecards state");
//     dispatch(updateScorecardsState(newScorecardsState));
//   }
// });

// ---- Game event handlers

export const socketLeaveRoomGame = (data) => {
  // console.log(data, "emitting leaving room game");
  socket.emit("leave_room_game", data);
};

export const socketLeaveRoomGameSpectator = (data) => {
  // console.log(data, "emitting leaving room game spectator");
  socket.emit("leave_room_game_spectator", data);
};

export const socketReconnectToRoomGame = (data) => {
  // console.log(data, "emitting reconnecting to room");
  socket.emit("reconnect_to_room_game", data);
};

export const socketGetGameState = (data) => {
  // console.log(data, "getting game state");
  socket.emit("get_game_state", data);
};

// (Player_Move) LETTER BANK ENTRY data = { 'room_game_code': roomCode + gameId, 'word': wordEntry }
export const socketLetterBankEntry = (data) => {
  // console.log(data, "emitting letter bank entry");
  socket.emit("guess", data);
}; // Response = 'guess_response'

// (Player_Move) STEAL ENTRY data = { 'room_game_code': roomCode + gameId, 'word': wordEntry, 'steal_word': steal }
export const socketStealEntry = (data) => {
  // console.log(data, "emitting steal entry");
  socket.emit("steal", data);
}; // Response = 'steal_response'

// (Player_Move) UNCOVER LETTER data = data = { 'room_game_code': roomCode + gameId}
export const socketUncoverLetter = (data) => {
  // console.log(data, "emitting uncover letter");
  socket.emit("uncover_letter", data);
}; // Response = 'uncover_letter_response'

// GAME OVER data = data = { 'room_game_code': roomCode + gameId}
export const socketGameOver = (data) => {
  // console.log(data, "emitting game over");
  socket.emit("game_over", data);
}; // Response = 'game_over_info'

// CREATE GAME data = { 'room_code': roomId }
export const socketCreateGame = (data) => {
  // console.log(data, "emitting create game");
  socket.emit("create_game", data);
}; // Response = 'create_game_response'

// JOIN GAME ROOM SOCKET data = { 'room_game_code': roomCode + gameId}
export const socketJoinRoomGameSocket = (data) => {
  // console.log(data, "emitting joining room game socket");
  socket.emit("join_game_room_socket", data);
};

// START GAME data = data = { 'room_game_code': roomCode + gameId}
export const socketStartGame = (data) => {
  // console.log(data, "emitting start game");
  socket.emit("start_game", data);
}; // Response = 'start_game_response'

// CANCEL GAME data = data = { 'room_game_code': roomCode + gameId}
export const socketCancelGame = (data) => {
  // console.log(data, "emitting cancel game");
  socket.emit("cancel_game", data);
}; // Response = 'cancel_game_response'

import { useState } from "react";
import { Form, Input, Button, PlayerName, MessageContainer } from "./styles/StyledChat";
import { socketSendChatMessage } from "../../lib/socket/socketConnection";
import { useSelector } from "react-redux";
import { getRoomCode } from "../../store/room";

export function ChatMessage(log, players) {
    const playerActive = () => {
        if (players?.[log.playerId]){
            return true
        } else {
            return false
        }
    }

    const color = () => {
        if (playerActive()) {
            return players?.[log.playerId].style
        } 
    }
    return (
        <>
        <MessageContainer>
            <PlayerName color={color()}>
                {log.playerDisplayName}</PlayerName>: {log.msg}
        </MessageContainer>
        </>
    )
}

export function ChatForm() {
    const roomCode = useSelector(getRoomCode);
    const [message, setMessage] = useState("");

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (message) {
            socketSendChatMessage({
                room_code: roomCode,
                message: message
            });
            setMessage("");
        }
    }
    
    return (
        <ChatForm.Form onSubmit={handleSendMessage}> 
            <ChatForm.Input message={message} setMessage={setMessage}/>
            <ChatForm.Button />
        </ChatForm.Form>
    )
}

ChatForm.Form = function ChatFormContainer({children, ...restProps}) {
    return (
        <Form {...restProps}>{children}</Form>
    )
}

ChatForm.Input = function ChatFormInputField({message, setMessage}) {
    return (
        <Input 
            type="text" 
            placeholder="send a message" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)}
        />
    )
}

ChatForm.Button = function ChatFormButton({children, ...restProps}) {
    return (
        <Button {...restProps}>ent</Button>
    )
}
import styled from "styled-components"
import Cat from "../../assets/images/avatar-icons/animals/cat.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const PageNotFound = () => {
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        history.push("/");
    }
    return (
        <>
        <PageContainer>
            <p className="error-code">404</p>
            <p>Oops! This page was not found...</p>

            <img src={Cat} alt="stealthy-cat" /> 
            <button onClick={handleClick}>GO TO HOMEPAGE</button>
        </PageContainer>
        </>
    )
}

const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        height: 30%;
        padding: 30px;
    }

    .error-code {
        font-size: 5em;
    }

    button {
        padding: 1em 2em;
        border: 2px solid black;
        border-radius: 999px;
        transition: 0.3s all ease;

        &:hover {
            background-color: black;
            color: #F2F2E9;
        }
    }


`

export default PageNotFound;
import { useSelector } from "react-redux";
import { socketLeaveRoom } from "../../lib/socket/socketConnection";
import { StyledExitGameButton } from "./StyledButtons";
import { getRoomCode } from "../../store/room";
import { Home } from "react-feather";

export default function ExitGameButton() {
  const roomCode = useSelector(getRoomCode);

  const handleExitGame = () => {
    socketLeaveRoom({
      room_code: roomCode,
    });
  };

  return (
    <>
      <StyledExitGameButton
        onClick={handleExitGame}
        className="exit-game-button"
      >
        EXIT
      </StyledExitGameButton>
    </>
  );
}

import { useState, useEffect } from "react";
import { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { ConnectSocket } from "../lib/socket/socketConnection";
import { getCurrentUserInfo } from "../store/session";

const SocketContext = createContext(null);

const SocketProvider = ({children}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [socket, setSocket] = useState();
    const { token, currentUserId } = useSelector(getCurrentUserInfo)

    useEffect(() => {
        if (token) {
            const newSocket = ConnectSocket(dispatch, token, history, currentUserId);
            setSocket(newSocket)
        }
    }, [dispatch, token, history, currentUserId])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext).socket
};

export default SocketProvider;
import styled from "styled-components";
import ThinkingBubble from "../../assets/images/thinking-bubble.png"

export default function FixedFeedbackLink () {
    return (
        <>
        <FixedContainer>
            <a href="https://forms.gle/SnLGKGrNgDV5G2Ui8" target="_blank" rel="noopener noreferrer">
            Experienced a bug? Have some feedback about LetterHeist? We'd love to know! 
            </a>
            <div style={{minWidth: "50px", minHeight: "50px"}}>
                <img style={{width: "100%", height: "100%"}}src={ThinkingBubble} alt="thinking bubble" />
            </div>
        </FixedContainer>
        
        </>
    )
}

const FixedContainer = styled.div`
    position: fixed;
    bottom: 40px;
    left: 0px;
    width: 406px;
    height: 70px;
    border-radius: 0px 12px 12px 0px;
    background-color: #B0D0E7;
    border: 2px solid black;
    border-left: none;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all .7s ease-out;
    transform: translateX(-336px);
    z-index: 52;
    font-size: 0.9em;

    a{
        text-decoration: underline;
    }

    &:hover {
        transform: translateX(0px);
    }
`
import { useSelector } from "react-redux";
import {
  socketCreateGame,
  socketJoinRoomGameSocket,
} from "../../lib/socket/socketConnection";
import { StyledStartGameButton } from "./StyledButtons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getHasActiveGame, selectRoomPlayers } from "../../store/room";
import { createStructuredSelector } from "reselect";
import { getCurrentUser } from "../../store/session";
import { selectRoomGameCode } from "../../store/game";

const styleOptions = {
  startGame: {
    backgroundColor: "#5363F6",
    textColor: "#AFE7FF",
  },
  spectateGame: {
    backgroundColor: "#FF8B1F",
    textColor: "#FBF0E8",
  },
  disabled: {
    backgroundColor: "",
    textColor: "lightgrey",
  },
};
const StartGameButton = () => {
  const history = useHistory();
  const { roomCode } = useParams();

  // const {players, currentUser, hasActiveGame, roomGameCode} = useSelector(state => ({
  //   players: state.room.players,
  //   currentUser: state.session.user ? state.session.user : null,
  //   hasActiveGame: state.room.hasActiveGame,
  //   roomGameCode: state.game.roomGameCode ? state.game.roomGameCode : null
  // }))
  const { players, currentUser, hasActiveGame, roomGameCode } = useSelector(
    createStructuredSelector({
      players: selectRoomPlayers,
      currentUser: getCurrentUser,
      hasActiveGame: getHasActiveGame,
      roomGameCode: selectRoomGameCode,
    })
  );

  const handleSpectateGame = () => {
    socketJoinRoomGameSocket({ room_game_code: roomGameCode });
    history.push(`/game/${roomGameCode}`);
  };

  // if current user is in lobby and not in active game -- spectate game
  if (hasActiveGame) {
    return (
      // SPECTACTE GAME BUTTON
      // <StyledStartGameButton
      //   style={{backgroundColor: `${styleOptions["spectateGame"].backgroundColor}`,color: `${styleOptions["spectateGame"].textColor}`}}
      //   // styleOptions={styleOptions["spectateGame"]}
      //   onClick={handleSpectateGame}>
      //   Spectate Game
      // </StyledStartGameButton>
      <StyledStartGameButton
      style={{
        backgroundColor: `${styleOptions["disabled"].backgroundColor}`,
        color: `${styleOptions["disabled"].textColor}`,
      }}
      disabled={true}>game currently in progress</StyledStartGameButton>
    );
  } else if (
    players &&
    currentUser &&
    !players[String(currentUser?.id)]?.readyToPlay
  ) {
    return (
      // DISABLED START GAME BUTTON
      <StyledStartGameButton
        // styleOptions={styleOptions["disabled"]}
        style={{
          backgroundColor: `${styleOptions["disabled"].backgroundColor}`,
          color: `${styleOptions["disabled"].textColor}`,
        }}
        disabled={true}
      >
        you must be ready to start
      </StyledStartGameButton>
    );
  }

  const handleStartGame = () => {
    // console.log("HITTING START GAME", roomCode);
    socketCreateGame({
      room_code: roomCode,
    });
  };

  return (
    // ENABLED START GAME BUTTON
    <StyledStartGameButton
      onClick={handleStartGame}
      // styleOptions={styleOptions["startGame"]}
      style={{
        backgroundColor: `${styleOptions["startGame"].backgroundColor}`,
        color: `${styleOptions["startGame"].textColor}`,
      }}
    >
      Start Game
    </StyledStartGameButton>
  );
};

export default StartGameButton;

import { Colors } from "./Colors";

export const Themes = {
    dark: {
        backgroundColor: Colors.GRAY_900, // '#121212',
        playerItemBackgroundColor: Colors.GRAY_100, //'#3B3B3B',
        textColor: Colors.GRAY_100, //'#E4E7EB',
        iconColor: Colors.GRAY_100, //'#E4E7EB',
        wordBankItemBackgroundColor: Colors.GRAY_900, // '#121212',
        buttonBackgroundColor: Colors.GRAY_600, // '#3B3B3B' or #121212 
        buttonBoxShadow: `-8px ${Colors.GRAY_100}`, //#E4E7EB,
        buttonBorder: `2px solid ${Colors.GRAY_100}`, //#3B3B3B,

        // player-dependent variables
        playerRed: Colors.PLAYER_RED,
        playerPink: Colors.PLAYER_PINK,
        playerPurple: Colors.PLAYER_PURPLE,
        playerBlue: Colors.PLAYER_BLUE,
        playerGreen: Colors.PLAYER_GREEN,
        playerYellow: Colors.PLAYER_YELLOW
    },
    light: {
        backgroundColor: Colors.TAUPE, 
        playerItemBackgroundColor: Colors.TAUPE, 
        textColor: Colors.GRAY_900, 
        iconColor: Colors.GRAY_900,
        buttonBackgroundColor: Colors.TAUPE,
        buttonBoxShadow: `-8px ${Colors.GRAY_900}`,
        buttonBorder: `2px solid ${Colors.GRAY_900}`,
        wordBankItemTextColor: Colors.GRAY_900,
        wordBankItemBorder: `2px solid ${Colors.GRAY_900}`,

        // player-dependent variables
        playerRed: Colors.PLAYER_RED,
        playerPink: Colors.PLAYER_PINK,
        playerPurple: Colors.PLAYER_PURPLE,
        playerBlue: Colors.PLAYER_BLUE,
        playerGreen: Colors.PLAYER_GREEN,
        playerYellow: Colors.PLAYER_YELLOW
    }

    
    // soup: {
    //     backgroundColor: Colors.TAUPE,
    //     bigButtonBgColor: Colors.YELLOW,
    //     smallButtonBgColor: 'transparent',
    //     smallButtonBorderColor: Colors.BLACK,
    // },
    // dark: {
    //     backgroundColor: Colors.GRAY_900,
    //     textColor: Colors.GRAY_100,
    //     iconColor: Colors.GRAY_100,
    //     wordItemBackgroundColor: Colors.GRAY_900,

    // }
}

/*
    Main properties:
        - text
        - background colors
        - border 

        - secondary buttons
        - links
*/


import styled, { css } from "styled-components";
import { Colors } from "../../../../styles/Colors";

export const Container = styled.div`
  border: 2px solid ${Colors.BLACK};
  border-radius: 24px;
  min-height: 150px;
  height: max-content;
  padding: 10px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  height: max-content;
`;

export const Item = styled.div`
  font-size: 1.3em;
  padding: 4px 12px;
  border: 2px solid ${Colors.BLACK};
  width: max-content;
  height: max-content;
  border-radius: 999px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? Colors.BLACK : PlayerColors[props.color]};
  color: ${(props) => (props.selected ? "white" : "")};
  text-transform: uppercase;
  font-weight: 300;
  transition: 0.3s ease all;
  user-select: none;
  font-weight: 300;
  transition: transform 0.2s;

  ${(props) =>
    props.$isSpectator &&
    css`
      pointer-events: none;
    `}

  &:hover {
    background-color: #fce198;
    border: 2px solid ${Colors.BLACK};
    color: ${Colors.BLACK};
    transform: scale(1.05);
  }

  @media (max-height: 834px) {
    font-size: 1.3em;
    padding: 2px 8px;
  }

  @media (max-width: 1050px) {
    font-size: 1.075em;
    padding: 2px 8px;
  }

  @media (max-height: 750px) {
    font-size: 1.075em;
    padding: 2px 8px;
  }
`;

const PlayerColors = {
  "view-all": Colors.BLACK,
  "player-red": "#F2A1A1",
  "player-pink": "#E7B4F0",
  "player-purple": "#C6C6E8",
  "player-blue": "#B4CFF0",
  "player-green": "#AFD2B5",
  "player-orange": "#F1B269",
};

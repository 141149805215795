
import styled from "styled-components";
import { Colors } from "../../../styles/Colors";

const PlayerColors = {
    'view-all': Colors.BLACK,
    'player-red': "#F2A1A1",
    'player-pink': "#E7B4F0",
    'player-purple': "#C6C6E8",
    'player-blue': "#B4CFF0",
    'player-green': "#AFD2B5",
    'player-orange': "#F1B269"
}

// styling elements for game log
export const ItemContainer = styled.div`
    border: 2px solid #969696;
    border-radius: 24px;
    padding: 8px 8px;
    width: 100%;
    display: flex;
    gap: 4px;
    background-color: #E4E4DB;
    font-size: 0.9em;
`

export const Icon = styled.div`
    min-width: 40px;
    min-height: 40px;
    font-size: 2em;
    
    
`

export const ItemContent = styled.div`
    text-wrap: wrap;
`

export const Player = styled.div`
    background-color: ${props => props.$playerActive ? PlayerColors[props.color] : "gray"};
    width: max-content;
    color: ${props => props.$playerActive ? TEXT_BORDER_COLORS[props.color] : "#f2f2e9"};
    // color: #F2F2E9;
    font-weight: 500;
    border-radius: 999px;
    padding: 4px 12px;
`

export const FailedContent = styled.p`
    color: ${Colors.RED};
`
export const Text = styled.p`
    
`

// styling for game log definitions
export const DefinitionsContainer = styled.div`
`

// gray
export const ToggleTitle = styled.div`
    color: gray;
    cursor: pointer;
    margin-top: 15px;
`

export const DefinitionsList = styled.ol`
    padding-left: 17px;
    line-height: 100%;

    li {
        margin-top: 10px;
        list-style-type: decimal;
        font-size: 0.9em
    }
`


const TEXT_BORDER_COLORS = {
    'player-red': "#713636",
    'player-pink': "#57325D",
    'player-purple': "#421B50",
    'player-blue': "#113871",
    'player-green': "#37473A",
    'player-orange': "#6B3B04"
}
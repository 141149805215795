import { ProtectedRoute } from "./utils/routeUtil";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import JoinRoom from "./pages/JoinRoom";
import Lobby from "./pages/Lobby";
import Splash from "./pages/Splash";
import Game from "./pages/Game";
import JoinRoomViaUrl from "./pages/JoinRoom/JoinRoomViaUrl";
import GameOverPage from "./pages/Game/GameOverPage";
import PageNotFound from "./pages/PageNotFound";


function App() {
  return (
    <Switch>
      <Route exact path="/error"><PageNotFound /></Route>
      <ProtectedRoute exact path="/join-room" comp={<JoinRoom />} />
      <ProtectedRoute path="/room/:roomCode" comp={<Lobby />} />
      {/* <Route path="/join-room/:roomCode"><JoinRoomViaUrl /></Route> */}
      {/* <Route path="/join-room/:roomCode" comp={<JoinRoomViaUrl />} /> */}
      <ProtectedRoute path="/game/results/:roomGameCode" comp={<GameOverPage />} />
      <ProtectedRoute path="/game/:roomCode" comp={<Game />} />
      <Route exact path="/login"><Login /></Route>
      <Route exact path="/"><Splash /></Route>
      <Redirect to='/error' /> 
    </Switch>
  );
}

export default App;

export const formatGameState = (data) => {
  const gameState = {
    roomCode: data["room_code"],
    roomId: data["room_id"],
    gameId: data["game_id"],
    gameStatus: data["game_status"],
    players: data["players"],
    currentPlayer: data["current_player_id"],
    currentPlayerId: data["current_player_id"],
    uncoveredLetters: data["uncovered_letters"],
    wordsFormed: data["guessed_word_deets"],
    remainingLettersCount: data["remaining_letters_count"],
    inFinalContemplation: data["in_final_contemplation"],
    timer: data["timer"],
  };
  return gameState;
};

export const formatRoomState = (data) => {
  const roomState = {
    roomCode: data.room_code,
    roomId: data.room_id,
    players: data.people_in_room,
    isActive: data.is_active,
    hasCapacity: data.has_capacity,
    isEmpty: data.is_empty,
    hasActiveGame: data.has_active_game,
    hasUnstartedGame: data.has_unstarted_game,
    // scorecards: data.scorecards,
    scorecards: formatScorecards(data.scorecards),
    activeGameId: data.active_game_id,
    playersInActiveGame: data.players_in_active_game,
    secondsBetweenLetters: data.seconds_between_letters,
  };
  return roomState;
};

const formatScorecards = (scorecards) => {
  // console.log("in room format scorecards", scorecards)
  let formattedScorecards = {};
  for (let scorecard of scorecards) {
    formattedScorecards[scorecard['room_game_id']] = formatRoomScorecard(scorecard);
  }
  return formattedScorecards;
}

export const formatLogNotification = (data) => {
  const logNotification = {
    logType: data.log_type,
    roomNotificationType: data.log_details.room_notification_type || null,
    gameNotificationType: data.log_details.game_notification_type || null,
    roomCode: data.log_details.room_code,
    roomId: data.log_details.room_id,
    gameId: data.log_details.game_id || null,
    playerId: data.log_details.player_id,
    playerDisplayName: data.log_details.player_display_name,
    msg: data.log_details.msg,
    word: data.log_details.word || null,
    definitions: data.log_details.definitions || null,
    ownerId: data.log_details.owner_id || null,
    ownerDisplayName: data.log_details.owner_display_name || null,
    rootWord: data.log_details.root_word || null,
    rootWordId: data.log_details.root_word_id || null,
    playerScores: data.log_details.player_scores || null,
    longestWordsDeets: data.log_details.longest_words_deets || null,
    longestEvolutionsDeets: data.log_details.longest_evolutions_deets || null
  };
  return logNotification;
};

export const formatScorecard = (data) => {
  const scorecard = formatGameState(data);
  scorecard["finalGameResults"] = {};
  scorecard["finalGameResults"]["finalRankingAndPlayerScores"] =
    data.final_game_results?.final_ranking_and_player_scores;
  scorecard["finalGameResults"]["longestWordsDeets"] =
    data.final_game_results?.general_game_results?.longest_words_deets;
  scorecard["finalGameResults"]["wordsWithMostEvolutions"] =
    data.final_game_results?.general_game_results?.words_with_most_evolutions;
  scorecard["finalGameResults"]["incorrectWordAttempts"] = data.final_game_results?.general_game_results?.incorrect_word_attempts
  return scorecard;
};

export const formatRoomScorecard = (scorecard) => {
  // console.log("this is the scorecard:", scorecard)
  let formattedScorecard = {};
  formattedScorecard["finalRankingAndPlayerScores"] = scorecard.final_ranking_and_player_scores;
  // console.log("formatted scorecard after finalranking and player scores", formattedScorecard)
  formattedScorecard["longestWordsDeets"] = scorecard.general_game_results.longest_words_deets;
  formattedScorecard["wordsWithMostEvolutions"] = scorecard.general_game_results.words_with_most_evolutions;
  formattedScorecard["incorrectWordAttempts"] = scorecard.general_game_results.incorrect_word_attempts
  return formattedScorecard;
}

// export const formatTimerData = (data) => {
//   const timerData = {
//     timeLimitSec: data.time_limit_sec,
//     timeNow: new Date(data.time_now),
//     timerCode: data.timer_code,
//     deadline: new Date(
//       new Date(data.time_now).getTime() + data.time_limit_sec * 1000
//     ),
//   };
//   console.log("timerData", timerData);
//   return timerData;
// };

import styled from 'styled-components';

export const FormContainer = styled.form`
    width: 100%;
`

export const Input = styled.input`
    width: 100%;
    padding: 5px 20px;
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    border: 1px solid gray;
    border-radius: 24px;
    outline: none;

    @media (max-height: 834px) {
        font-size: 1.7em;
    }
`

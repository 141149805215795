import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createStructuredSelector } from "reselect";

import { Colors } from "../../../styles/Colors";
import {
  socketAcceptReady,
  socketCancelReady,
} from "../../../lib/socket/socketConnection";
import {
  getCurrentUserReadyState,
  getHasActiveGame,
} from "../../../store/room";
import { getCurrentUser } from "../../../store/session";

export default function PlayerReadyButton() {
  const [disabled, setDisabled] = useState(false);
  const { roomCode } = useParams();

  const { isReady, hasActiveGame, currentUser } = useSelector(
    createStructuredSelector({
      isReady: getCurrentUserReadyState,
      hasActiveGame: getHasActiveGame,
      currentUser: getCurrentUser,
    })
  );

  useEffect(() => {
    setDisabled(hasActiveGame);
  }, [hasActiveGame]);

  const handleReadyClick = () => {
    const data = {
      room_code: roomCode,
      player_id: currentUser.id,
    };
    if (isReady) {
      socketCancelReady(data);
    } else {
      socketAcceptReady(data);
    }
  };

  return (
    // <StyledPlayerReadyButton onClick={handleReadyClick} disabled={disabled} $isReady={players[String(currentUser?.id)]?.readyToPlay}>
    <StyledPlayerReadyButton
      onClick={handleReadyClick}
      disabled={disabled}
      $isReady={isReady}
    >
      {/* { players[String(currentUser?.id)]?.readyToPlay ? "cancel ready" : "i'm ready "} */}
      {isReady ? "cancel ready" : "i'm ready "}
    </StyledPlayerReadyButton>
  );
}

const StyledPlayerReadyButton = styled.div`
  color: ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.25)" : Colors.BLACK)};
  background-color: ${(props) => (props.$isReady ? "#F76262" : "#9DDF8D")};
  background-color: ${(props) => props.disabled && "rgba(215, 215, 198, 0.25)"};
  border: 2px solid
    ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.25)" : Colors.BLACK)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 24px;
  box-shadow: 0px 3px;
  text-align: center;
  font-size: 1.3em;
  text-transform: uppercase;
  padding: 12px 16px;
  width: 180px;
  height: 60px;
  text-wrap: nowrap;
  pointer-events: ${(props) => props.disabled && "none"};

  &:active {
    transform: ${(props) => !props.disabled && "translateY(3px)"};
    box-shadow: ${(props) => !props.disabled && "0px 0px"};
  }
`;

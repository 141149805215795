import styled from "styled-components";

export const StyledLeaveRoomButton = styled.div`
  text-transform: uppercase;
  display: flex;
  gap: 4px;
  padding: 0.4em 1em;
  font-size: 1em;
  border: 2px solid black;
  width: max-content;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 2px;

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 0px;
  }
`;

export const TransparentButtonA = styled.div`
  text-transform: uppercase;
  display: flex;
  gap: 4px;
  padding: 0.4em 1em;
  font-size: 1em;
  border: 2px solid black;
  width: max-content;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 2px;

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 0px;
  }
`

export const StyledCantStartGameButton = styled.div`
  text-transform: uppercase;
  width: 100%;
  padding: 0.7em 1em;
  font-size: 1.8em;
  border: 2px solid lightgrey; /* Set border color to light grey */
  width: max-content;
  border-radius: 24px;
  box-shadow: 0px 3px;
  color: lightgrey; /* Set text color to light grey */
  pointer-events: none; /* Make the button not clickable */

  &:active {
    transform: translateY(3px);
    box-shadow: 0px 0px;
  }
`;

export const StyledEndGameButton = styled.div`
  text-transform: uppercase;
  padding: 0.4em 1.3em;
  font-size: 1.2em;
  border: 2px solid black;
  width: max-content;
  border-radius: 999px;
  cursor: pointer;
  box-shadow: 0px 3px;
  background-color: #F58E8E;


  &:active {
    transform: translateY(3px);
    box-shadow: 0px 0px;
  }
`;

export const StyledReturnToLobbyButton = styled.div`
  text-transform: uppercase;
  width: 100%;
  padding: 0.6em 0px;
  border: 2px solid black;
  text-align: center;
  border-radius: 24px;
  box-shadow: 0px 3px;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #f6c453;

  &:active {
    transform: translateY(3px);
    box-shadow: 0px 0px;
  }
`;

export const StyledStartGameButton = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 0.7em 1em;
  font-size: 2em;
  border: 3px solid ${props => props.disabled ? "lightgrey" : "black"};
  box-shadow: 0px 3px ${props => props.disabled ? "lightgrey" : "black"};
  cursor: ${props => !props?.disabled && "pointer"};
  pointer-events: ${props => props.disabled && "none"}; /* Make the button not clickable */
  

  &:active {
    transform: translateY(3px); 
    box-shadow: 0px 0px;
}
`

export const StyledExitGameButton = styled.div`
text-transform: uppercase;
width: 20%;
padding: 0.6em 0px;
border: 2px solid black;
text-align: center;
border-radius: 24px;
box-shadow: 0px 3px;
font-size: 1.5em;
cursor: pointer;
background-color: #ED7D7D;

&:active {
  transform: translateY(3px);
  box-shadow: 0px 0px;
}
`
import styled from "styled-components";

export const PlayerName = styled.p`
  color: gray;
  font-weight: 700;
    display: inline-block;
    font-size: 0.875em;
`;

export const Text = styled.p`
  color: gray;
  display: inline-block;
    font-size: 0.875em;
`;

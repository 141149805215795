import {
  FaRegFaceFlushed,
  FaRegFaceGrinTongue,
} from "react-icons/fa6";
import { BsLightning } from "react-icons/bs";
import { BsArrowUpCircle } from "react-icons/bs";
import {
  ItemContainer,
  Icon,
  ItemContent,
  Player,
  Text,
  DefinitionsContainer,
  ToggleTitle,
  DefinitionsList,
} from "./styles/StyledGameNotifications";
import { useSettingsContext } from "../../contexts/GameSettingsContext";
import { TfiFaceSad } from "react-icons/tfi";
import { useState } from "react";
import { Frown, Zap, ArrowUpCircle, Meh, MinusCircle } from "react-feather";
import { PlayerStyles } from "../../data/styleTypes";

const ICONS = {
  failedWordAttempt: <Frown size={36} strokeWidth={1}/>,
  failedStealAttempt: <MinusCircle size={36} strokeWidth={1}/>,
  successfulSteal: <Meh size={36} strokeWidth={1}/>,
  successfulNewWord: <Zap size={36} strokeWidth={1}/>,
  successfulUpgrade: <ArrowUpCircle size={36} strokeWidth={1}/>,
};

export function createGameNotification(log, players) {
    if (!log?.gameNotificationType) return null;
    

    const playerActive = () => {
        if (players?.[log.playerId]){
            return true
        } else {
            return false
        }
    }

    const color = () => {
        if (playerActive()) {
            return players?.[log.playerId].style
        } 
    }
        
    return (
        <GameNotification>
            <GameNotification.Icon>{ICONS[log.gameNotificationType]}</GameNotification.Icon>
            <GameNotification.Content>
                <GameNotification.Player playerActive={playerActive()} color={color()}>{log.playerDisplayName}</GameNotification.Player>
                <GameNotification.Message >{log.msg}</GameNotification.Message>
                {log.definitions && <GameNotification.Definitions definitions={log.definitions}/>}
            </GameNotification.Content>
        </GameNotification>
    );
}

function GameNotification({children, ...restProps}) {
    return (
        <ItemContainer {...restProps}>
            {children}
        </ItemContainer>
    )
}

GameNotification.Icon = function GameNotificationIcon ({ children, ...restProps }) {
    return (
        <Icon {...restProps}>{children}</Icon>
    );
};

GameNotification.Content = function GameNotificationContent ({ children, ...restProps }) {
    return (
        <ItemContent {...restProps}>{children}</ItemContent>
    )
}

GameNotification.Player = function GameNotificationPlayer ({ playerActive, children, ...restProps }) {
    return (
        <Player $playerActive={playerActive} {...restProps}>{children}</Player>
    )
}

GameNotification.Message = function GameNotificationMessage ({ children, ...restProps }) {
    return (
        <Text {...restProps}>{children}</Text>
    )
}

GameNotification.Definitions = function GameNotificationDefinitions ({ definitions }) {
    const { showDefinitions } = useSettingsContext();
    const [viewDefinitions, setViewDefinitions] = useState(true);
    const [viewMore, setViewMore] = useState(false);

    const handleViewDefinitions = () => {
        viewDefinitions ? setViewDefinitions(false) : setViewDefinitions(true)
    }

    const handleViewMore = () => {
        // console.log("view more state", viewMore)
        viewMore ? setViewMore(false) : setViewMore(true)
    }

    if (!showDefinitions) return null;

    return (
        <>
        <DefinitionsContainer>
            <ToggleTitle onClick={handleViewDefinitions}>
                {viewDefinitions ? "< hide definitions" : "> view definitions"}
            </ToggleTitle>
            {viewDefinitions && 
                <DefinitionsList>
                    {viewMore 
                        ? definitions.map((definition, index) => (
                            <li key={index}>{definition}</li>
                        ))
                        : <li>{definitions[0]}</li>
                    }
                    { definitions.length > 1 &&
                        <ToggleTitle onClick={handleViewMore}>{viewMore ? "< view less" : "> view more"}</ToggleTitle>
                    }
                </DefinitionsList>
            }           
        </DefinitionsContainer>
        </>
    )
}

import { useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { socketSetSecondsBetweenLetters } from "../../../lib/socket/socketConnection.js";

import { getSecondsBetweenLetters } from "../../../store/room";
import { getCurrentUser } from "../../../store/session";
import { HelpCircle } from "react-feather";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";

export default function SecondsBetweenLettersComponent() {
  const { secondsBetweenLetters, currentUser } = useSelector(
    createStructuredSelector({
      secondsBetweenLetters: getSecondsBetweenLetters,
      currentUser: getCurrentUser,
    })
  );
  const { roomCode } = useParams();
  const [selectedOption, setSelectedOption] = useState(secondsBetweenLetters);

  useEffect(() => {
    if (secondsBetweenLetters === 3) {
      setSelectedOption("speed");
    } else if (secondsBetweenLetters === 10) {
      setSelectedOption("normal");
    } else if (secondsBetweenLetters === 30) {
      setSelectedOption("slow");
    }
  }, [secondsBetweenLetters]);

  const handleSecondsBetweenLettersChange = (option) => {
    // e.preventDefault();
    let seconds_code;

    if (option === "speed") seconds_code = 1;
    if (option === "normal") seconds_code = 2;
    if (option === "slow") seconds_code = 3;

    console.log(seconds_code);
    // selectedOption === "speed"
    //   ? 1
    //   : selectedOption === "normal"
    //   ? 2
    //   : selectedOption === "slow"
    //   ? 3
    //   : 2; // Determine seconds based on selected option
    const data = {
      room_code: roomCode,
      player_id: currentUser.id,
      seconds_code: seconds_code,
    };
    socketSetSecondsBetweenLetters(data);
  };

  return (
    <>
      <div className="flex flex-col items-center gap-1">
        <div className="flex w-full justify-between">
          <p>Game mode: </p>
          <HelpCircle
            strokeWidth={1.5}
            className="game-mode-info cursor-pointer"
          />
          <Tooltip anchorSelect=".game-mode-info" place="bottom">
            <p className="w-[300px] text-sm">
              Change the pace of the game by deciding how quickly new letters
              pop out
            </p>
          </Tooltip>
        </div>
        <div className="text-xs flex border border-gray-500 rounded-md overflow-hidden">
          <div
            className={`flex flex-col items-center border-r border-gray-500 py-1 px-2 cursor-pointer ${
              selectedOption === "speed" && "bg-[#FFB783]"
            }`}
            onClick={() => {
              setSelectedOption("speed");
              handleSecondsBetweenLettersChange("speed");
            }}
          >
            <p>Fast</p>
            <p>3s</p>
          </div>
          <div
            className={`flex flex-col items-center border-r border-gray-500 py-1 px-2 cursor-pointer ${
              selectedOption === "normal" && "bg-[#FFB783]"
            }`}
            onClick={() => {
              setSelectedOption("normal");
              handleSecondsBetweenLettersChange("normal");
            }}
          >
            <p>Normal</p>
            <p>10s</p>
          </div>
          <div
            className={`flex flex-col items-center py-1 px-2 cursor-pointer ${
              selectedOption === "slow" && "bg-[#FFB783]"
            }`}
            onClick={() => {
              setSelectedOption("slow");
              handleSecondsBetweenLettersChange("slow");
            }}
          >
            <p>Slow</p>
            <p>30s</p>
          </div>
        </div>
        {/* <form>
        <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
          <option value="">Select speed</option>
          <option value="speed">Speed round</option>
          <option value="normal">Normal</option>
          <option value="slow">Slow</option>
        </select>
        <button onClick={handleSecondsBetweenLettersChange}>Apply</button>
      </form> */}
      </div>
    </>
  );
}

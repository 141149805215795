import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { socketLetterBankEntry, socketStealEntry } from "../../../lib/socket/socketConnection.js";
import { getCurrentUser } from "../../../store/session.js";
import { clearGameErrors } from "../../../store/game.js";
import {
    FormContainer,
    Input
} from "./styles/StyledWordEntry.js";
import { useKeyboardShortcut } from "../../../hooks/useKeyboardShortcut.js";

export default function WordEntry({ steal, roomGameCode, setSteal, isSpectator }) {
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser); 

    const [wordEntry, setWordEntry] = useState(""); 

    const handleWordEntry = (e) => {
        e.preventDefault();
        if (steal) {
          // console.log("stealing")
          socketStealEntry({
            room_game_code: roomGameCode,
            word_entry: wordEntry,
            steal_word: steal,
            root_word_id: steal.id,
            current_player: currentUser,
          });
          setSteal("");
        } else {
          socketLetterBankEntry({
            room_game_code: roomGameCode,
            word_entry: wordEntry,
            current_player: currentUser,
          });
        }
        setWordEntry("");
        dispatch(clearGameErrors()); 
      };

      // useKeyboardShortcut(
      //   () => document.getElementById("word-entry-input-box").focus(),
      //   ["Space"]
      // )
      
    return (
      <>
      {!isSpectator && <FormContainer onSubmit={handleWordEntry}>
          <Input 
              id="word-entry-input-box"
              type="text" 
              placeholder="enter a word" 
              value={wordEntry} 
              onChange={(e) => setWordEntry(e.target.value)}
              autoComplete="off"
          />
      </FormContainer>}
      </>
    );
};
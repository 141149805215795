import styled from "styled-components"
import Logo from "../generalComponents/Logo"

export default function SmallScreenAlert ({disableLogo=false}) {
    return (
        <Container>
            <Header>
                <Logo disabled={disableLogo}/>
            </Header>
            <Message>
                <p>
                LetterHeist is currently not available to play on smaller screen sizes. For the best experience, we recommend playing LetterHeist on a large device at full screen. Thank you for your understanding!
                </p>
                <br></br>
                <p>
                - LetterHeist Team
                </p>
                <br></br>
                <iframe src="https://giphy.com/embed/idQdu0VjsGTMQ" width="480" height="204" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
            </Message>
        </Container>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Header = styled.div`
    width: 100%;
    text-align: center;
`

const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: center;
    flex-grow: 1;
    font-size: 1.3em;
    overflow: hidden;

    @media (max-width: 413px) {
        font-size: 1em;
    }

    @media (max-width: 700px) {
        width: 80%;
    }
`
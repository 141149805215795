import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { restoreSession } from '../store/session';

export const ProtectedRoute = ({ path, comp }) => {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => !!state.session.user)

    useEffect(() => {
        if (loggedIn) dispatch(restoreSession());
    }, [dispatch, loggedIn ]);

    return (
        <>
            {loggedIn 
                ? <Route path={path}>{comp}</Route>
                : <Redirect to="/login" />
            }
        </>
    );
};
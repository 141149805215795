import { CaptionContainer, Container, ImageContainer } from "./StyledRetroCardA"

const defaultStyleOptions = {
    "cardBackground": "#AAA493"
}

const fillerContent = {
    image: <div>image</div>,
    caption: <p>caption</p>
}

export default function RetroCardA({
    content={...fillerContent},
    styleOptions={...defaultStyleOptions},
    ...restProps
}) {
    return (
        // <Container style={{backgroundColor: styleOptions["cardBackground"]}}>
        <Container $backgroundColor={styleOptions["cardBackground"]}>
            <RetroCardA.Image {...restProps}>
                {content.image}
            </RetroCardA.Image>
            <RetroCardA.Caption {...restProps}>
                {content.caption}
            </RetroCardA.Caption>

        </Container>
    )
}

RetroCardA.Image = function CardImage ({children, ...restProps}) {
    return (
        <ImageContainer {...restProps}>{children}</ImageContainer>
    )
}

RetroCardA.Caption = function CardCaption ({children, ...restProps}) {
    return (
        <CaptionContainer {...restProps}>
            {children}
        </CaptionContainer>
    )
}
import Carousel from "../Carousel"
import RetroWindowB from "../Retro/RetroWindowB/RetroWindowB"
import { Container, RuleContainer, StyledGoalText, StyledRuleContent, StyledRuleTitle } from "./StyledGameInstructions"
import FreeLetter from "../../assets/images/previews/free-letters.png";
import Steal from "../../assets/images/previews/steal-preview.png";
export default function GameInstructions ({isModal}) {

    return (
        <Container style={{  maxHeight: isModal && "500px", overflowY: isModal && "auto" }}>
            <GameInstructions.Goal>WIN BY ACQUIRING THE MOST LETTERS</GameInstructions.Goal>
            <GameInstructions.Rule>
                <GameInstructions.RuleTitle>Gain Letters by Creating Words</GameInstructions.RuleTitle>
                <GameInstructions.RuleContent>
                    <ol>
                        <li>Create words by:</li>
                        <ul>
                            <li><span>Forming new words: </span>create new words using available letters from the letter bank</li>
                            <li><span>Stealing/Upgrading: </span>select an existing word from the word bank and add to the word using available letters from the letter bank to form a new word</li>
                        </ul>
                        <li>Any player can create words at any time</li>
                    </ol>
                    {/* <div className="create-words-demo">
                        <div className="create-words-section">
                            <img src={FreeLetter} alt="free letters demo" />
                            <h3>Free Letters</h3>
                            <p>Create new words using available letters from the letter bank</p>
                        </div>
                        <div className="create-words-section">
                            <img src={Steal} alt="free letters demo" />
                            <h3>Stealing/Upgrading</h3>
                            <p>Select existing words and add to the word using available letters from the letter bank to form a new word</p>
                        </div>
                    </div> */}
                </GameInstructions.RuleContent>
            </GameInstructions.Rule>
            <GameInstructions.Rule>
                <GameInstructions.RuleTitle>Valid Word Rules</GameInstructions.RuleTitle>
                <GameInstructions.RuleContent>
                    <ol>
                        <li>Word must be in the LetterHeist dictionary, which uses the Merriam-Webster dictionary</li>
                        <li>If stealing: words <span>must not be derivative</span> of the selected word</li>
                        <ul>
                            <li>Ex: if stealing the word “heat”, submitting word variations like “re-heat”, “heat-ed”, “heat-ing”, “heat-s”, and “heat-er” are not valid</li>
                        </ul>
                    </ol>
                </GameInstructions.RuleContent>
            </GameInstructions.Rule>
            <GameInstructions.Rule>
                <GameInstructions.RuleTitle>Player Turn</GameInstructions.RuleTitle>
                <GameInstructions.RuleContent>
                    <ol>
                        <li>Current player has either 3, 10, or 30 seconds (depending on the speed selected at the start of the game) to add a new letter to the letter bank. At the end of 3, 10, or 30 seconds, a new letter is automatically added and new current player turn is assigned</li>
                        <li>After a player creates a new word, it becomes their turn</li>
                        <li>A player can only Add New Letter to the letter bank when it’s their turn</li>
                    </ol>
                </GameInstructions.RuleContent>
            </GameInstructions.Rule>
            <GameInstructions.Rule>
                <GameInstructions.RuleTitle>Ending the Game</GameInstructions.RuleTitle>
                <GameInstructions.RuleContent>
                    <p>Game enters final deliberation when there are no remaining letters left. If players decide no more words can be created, the game ends! The player with the most letters wins! :)</p>
                </GameInstructions.RuleContent>
            </GameInstructions.Rule>
            {/* <GameInstructions.Goal>
                <div style={{width: "80%", margin:"20px auto"}}>
                    <RetroWindowB 
                        content={{
                            title: "GOAL", 
                            content: <p style={{margin: "20px auto", textAlign: "center", fontSize: "1.3em"}}>Acquire the most cumulative letters by creating 3+ letter words</p>}}
                        styleOptions={{
                            "box1": "#F00F0F",
                            "box2": "#F00F0F",
                            "titleBackground": "#F1AF61",
                            "contentBackground": "#F5F5F5"
                        }}
                    />
                        
                </div>
            </GameInstructions.Goal>
            <Carousel>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>3 WAYS TO CLAIM A WORD</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li><span>Free letters: </span>combine three or more letters available in letter bank to form a word</li>
                                <li><span>Stealing: </span>steal another player's word by selecting a word from the word bank and enter a new word using 1 or more available letters from the letter bank</li>
                                <li><span>Expand your own words:</span> add onto your own word by selecting your word from the word bank and enter a new word using 1 or more available letters from the letter bank</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>VALID WORD RULES</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li>Word must be in the LetterHeist dictionary, which uses the Merriam-Webster dictionary</li>
                                <li>If stealing: words <span>must not be derivative</span> of the selected word</li>
                                <li>Ex: if stealing the word “heat”, submitting word variations like “re-heat”, “heat-ed”, “heat-ing”, “heat-s”, and “heat-er” are not valid</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>PLAYER TURN</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li>Current player has 30 seconds to add a new letter to the letter bank. Upon 30 seconds, a new letter is automatically added and new current player turn is assigned.</li>
                                <li>If a player successfully claims a new word, that player is assigned the current turn to add a new letter.</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>GAME END</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <p>Game enters final deliberation when there are no remaining letters left. If players decide no more words can be created, the game ends! The player with the most letters wins! :)</p>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
            </Carousel> */}
        </Container>
    )

}

GameInstructions.Goal = function GameInstructionsGoal({children, ...restProps}) {
    return (
        <>
        <StyledGoalText> 
            {children}
        </StyledGoalText>
        </>
    )
}

GameInstructions.Rule = function GameInstructionsRule({children}) {
    return (
        <RuleContainer>
            {children}
        </RuleContainer>
    )
}

GameInstructions.RuleTitle = function GameInstructionsRuleTitle({children}) {
    return (
        <>
        <StyledRuleTitle>
            {children}
        </StyledRuleTitle>
        </>
    )
}

GameInstructions.RuleContent = function GameInstructionsRuleContent({children}) {
    return (
        <>
        <StyledRuleContent>
            {children}
        </StyledRuleContent>
        </>
    )
}




{/* <GameInstructions.Goal>
                <div style={{width: "80%", margin:"20px auto"}}>
                    <RetroWindowB 
                        content={{
                            title: "GOAL", 
                            content: <p style={{margin: "20px auto", textAlign: "center", fontSize: "1.3em"}}>Acquire the most cumulative letters by creating 3+ letter words</p>}}
                        styleOptions={{
                            "box1": "#F00F0F",
                            "box2": "#F00F0F",
                            "titleBackground": "#F1AF61",
                            "contentBackground": "#F5F5F5"
                        }}
                    />
                        
                </div>
            </GameInstructions.Goal>
            <Carousel>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>3 WAYS TO CLAIM A WORD</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li><span>Free letters: </span>combine three or more letters available in letter bank to form a word</li>
                                <li><span>Stealing: </span>steal another player's word by selecting a word from the word bank and enter a new word using 1 or more available letters from the letter bank</li>
                                <li><span>Expand your own words:</span> add onto your own word by selecting your word from the word bank and enter a new word using 1 or more available letters from the letter bank</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>VALID WORD RULES</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li>Word must be in the LetterHeist dictionary, which uses the Merriam-Webster dictionary</li>
                                <li>If stealing: words <span>must not be derivative</span> of the selected word</li>
                                <li>Ex: if stealing the word “heat”, submitting word variations like “re-heat”, “heat-ed”, “heat-ing”, “heat-s”, and “heat-er” are not valid</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>PLAYER TURN</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <ol>
                                <li>Current player has 30 seconds to add a new letter to the letter bank. Upon 30 seconds, a new letter is automatically added and new current player turn is assigned.</li>
                                <li>If a player successfully claims a new word, that player is assigned the current turn to add a new letter.</li>
                            </ol>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
                <Carousel.Item>
                    <GameInstructions.Rule>
                        <GameInstructions.RuleTitle>GAME END</GameInstructions.RuleTitle>
                        <GameInstructions.RuleContent>
                            <p>Game enters final deliberation when there are no remaining letters left. If players decide no more words can be created, the game ends! The player with the most letters wins! :)</p>
                        </GameInstructions.RuleContent>
                    </GameInstructions.Rule>
                </Carousel.Item>
            </Carousel> */}
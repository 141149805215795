import styled from 'styled-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import AuthForm from "../../components/generalComponents/AuthForm"
import ContinueAsGuestForm from '../../components/generalComponents/ContinueAsGuestForm';
import Logo from '../../components/generalComponents/Logo';
import RetroWindowB from '../../components/Retro/RetroWindowB/RetroWindowB';
import { getCurrentUser } from '../../store/session';
import "./Login.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SmallScreenAlert from '../../components/SmallScreenAlert';

const Login = () => {
    const user = useSelector(getCurrentUser);

    const { width, height } = useWindowDimensions();
    
    const [authType, setAuthType] = useState("login");
    
    const handleSwitchAuthType = () => {
        authType === "login" ? setAuthType("signup") : setAuthType("login");
    };
    
    if (user) return <Redirect to="/join-room" />;

    if (width < 1000 || height < 730) return <SmallScreenAlert />

    
    return (
        <>
            <div id="login-wrapper">
                <div className="login-header">
                    <Logo />
                </div>
                <RetroWindowB content={{title: "", content: <Login.Content authType={authType} handleSwitchAuthType={handleSwitchAuthType}/>}}/>
            </div>
        </>
    )
}

Login.Content = function LoginContent ({...restProps}) {
    return (
        <ContentContainer>
            <Login.AccountLogin {...restProps}/>
            <Login.GuestLogin {...restProps}/>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    height: calc(100% - 60px);
    padding: 24px;
    font-size: 2.5vw;

    h1 {
        font-size: 2.7vw;
        text-align: center;
    }

    @media (max-width: 720px) {
        flex-direction: column;

        h1 {
            font-size
        }
    }

    @media (max-width: 940px) {
        h1 {
            font-size: 27px;
        }
    }

    @media (max-height: 614px) {
        flex-direction: column;
    }
`
Login.AccountLogin = function AccountLogin({ authType, handleSwitchAuthType }) {
    return (
        <>
        <LoginContainer>
            <h1>{ authType === "login" ? "LOGIN" : "REGISTER" }</h1>
            <AuthForm type={authType} handleSwitchAuthType={handleSwitchAuthType}/>
        </LoginContainer>
        </>
    );
};

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 3px solid black;
    width: 100%;
    height: 100%;
    background-color: #FAF5F5;
    font-size: 0.7em;
    padding: 20px;

    @media (max-width: 1120px) {
        font-size: 20px;
    }

    
`

Login.GuestLogin = function GuestLogin() {
    return (
        <GuestLoginContainer>
            <h1>PLAY AS GUEST</h1>
            <ContinueAsGuestForm />
        </GuestLoginContainer>
    );
};

const GuestLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 3px solid black;
    width: 70%;
    background-color: #FAF5F5;
    font-size: 0.7em;

    @media (max-width: 1120px) {
        font-size: 20px;
    }

    @media (max-width: 720px) {
        width: 100%;
        padding: 20px;
    }

    @media (max-height: 614px) {
        width: 100%;
        padding: 20px;
    }
`


export default Login;
const RECEIVE_LOG = "logs/RECEIVE_LOG";
const RECEIVE_LOGS = "logs/RECEIVE_LOGS";
const EMPTY_LOGS = "logs/EMPTY_LOGS";

// SELECTORS
export const getLogs = (state) => (state.logs ? state.logs : null);

export const receiveLog = (payload) => ({
  type: RECEIVE_LOG,
  payload,
});
export const receiveLogs = (payload) => ({
  type: RECEIVE_LOGS,
  payload,
});

export const emptyLogs = () => ({
  type: EMPTY_LOGS
})

// clear logs after leaving a room/game
export const clearLogs = () => async dispatch =>  {
  // sessionStorage.removeItem("logDetails");
  localStorage.removeItem("logDetails");
  dispatch(emptyLogs());
}

// restore logs upon refresh
export const restoreLogs = () => async dispatch => {
  // const logs = JSON.parse(sessionStorage.getItem("logDetails"));
  const logs = JSON.parse(localStorage.getItem("logDetails"));
  if (logs) {
    dispatch(receiveLogs(logs));
  }
}

// first create a log to store in sessionStorage
export const storeCurrentLog = (logDetails) => {
  if (logDetails) {
    // sessionStorage.setItem("logDetails", JSON.stringify(logDetails));
    localStorage.setItem("logDetails", JSON.stringify(logDetails));
  } else {
    // sessionStorage.removeItem("logDetails");
    localStorage.removeItem("logDetails");
  }
};

export const updateLogs = (newLog) => async (dispatch) => {
  // const currentLogs = JSON.parse(sessionStorage.getItem("logDetails")) || [];
  const currentLogs = JSON.parse(localStorage.getItem("logDetails")) || [];
  // add to current logs
  const newLogs = [...currentLogs, newLog];
  dispatch(receiveLogs(newLogs));
  storeCurrentLog(newLogs);
};

const logsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LOG:
      return [...state, action.payload];
    case RECEIVE_LOGS:
      return action.payload;
    case EMPTY_LOGS:
      return [];
    default:
      return state;
  }
};

export default logsReducer;

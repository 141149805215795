import styled from "styled-components";
import { Colors } from "../../../../styles/Colors";

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  // height: 100%;
  //   border: 1px solid red;
  // margin-bottom: 30px;
  gap: 20px;
  padding: 0px 20px;
`;

export const Left = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 12px;
  align-items: center;
  //   max-height: calc(100vh - 124px);
  //   min-height: 598px;
  flex-grow: 1;

  @media (max-height: 834px) {
    // min-height: 600px;
    // max-height: calc(100vh - 184px);
  }

  @media (max-width: 1050px) {
    // min-height: 600px;
  }
`;
export const Right = styled.div`
  width: 35%;
  border: 2px solid ${Colors.BLACK};
  border-radius: 24px;
  padding: 20px;
  max-height: calc(100vh - 124px);
  min-height: 598px;
`;

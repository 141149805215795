import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

import Logo from "../../components/generalComponents/Logo";
import CreateRoomForm from "../../components/roomComponents/CreateRoomForm";
import JoinRoomForm from "../../components/roomComponents/JoinRoomForm"
import LogoutButton from "../../components/Buttons/LogoutButton";
import RetroWindowB from "../../components/Retro/RetroWindowB/RetroWindowB";
import SmallScreenAlert from "../../components/SmallScreenAlert";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { clearRoomErrors } from "../../store/room";
import "./JoinRoom.css";

// A user can either create a room or join an existing room with room pin
const JoinRoom = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {width, height} = useWindowDimensions();
    const currentUser = useSelector(state => state.session.user.username)
    const roomDetails = JSON.parse(localStorage.getItem("roomDetails"))
    const roomGameDetails = JSON.parse(localStorage.getItem("roomGameDetails"))

    useEffect(() => {
        return () => {
            dispatch(clearRoomErrors())
            // console.log("cleaning up")
        };
    }, [dispatch]);

    if (!currentUser) return <Redirect to="/login" />
    if (roomGameDetails) return history.push(`/game/${roomGameDetails["roomGameCode"]}`)
    if (roomDetails) return history.push(`/room/${roomDetails["roomCode"]}`)
    // if (roomRoomDetails) return history.push(`/game/${roomGameDetails["roomGameCode"]}`);

    if (width < 1000 || height < 730) return <SmallScreenAlert />
    
    return (
        <>
            <div id="join-room-wrapper">
                <div id="join-room-header">
                    <Logo />
                    <LogoutButton />
                </div>
                <RetroWindowB 
                    content={{
                        title: "",
                        content: <JoinRoom.Content />
                    }}
                    styleOptions={{
                        "box1": "#FFFFFF",
                        "box2": "#FFFFFF",
                        "titleBackground": "#FF4D35",
                        "contentBackground": "#F5D4D4",
                    }}/>
            </div>
        </>
    );
};

JoinRoom.Content = function () {
    return (
        <Container>
            <JoinRoom.CreateRoom />
            <JoinRoom.JoinRoom />
        </Container>
    )
}

const Container = styled.div`
    width: 80%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    
    @media (max-width: 520px) {
        width: 100%;
    }
`

JoinRoom.CreateRoom = function () {
    return (
        <CreateRoomForm />
    )
}

JoinRoom.JoinRoom = function () {
    return (
        <JoinRoomForm />
    )
}


export default JoinRoom;
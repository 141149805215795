import GameHeader from "../../components/Game/GameHeader";
import GameResults from "../../components/Game/GameResults";
import "./Game.css"

const GameOverPage = () => {
    return (
        <div id="game-page-wrapper">
            <GameHeader />
            <GameResults/>
        </div>
    )
}

export default GameOverPage;
import styled from "styled-components";
import { Colors } from "../../../../styles/Colors";

export const Container = styled.div`
    width: 100%;
    // height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'JetBrains Mono', monospace;
    color: ${Colors.GRAY_600};
`



export const SettingsButton = styled.div`
    font-size: 50px;
    font-weight: 300;
    cursor: pointer;
`

export const Text = styled.div`
    font-size: 1.3em;
`
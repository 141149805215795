import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom';
import configureStore from './store';
import SocketProvider from "./contexts/SocketProvider";
import { ThemeContextProvider } from './contexts/ThemeContext';
import { ModalProvider } from './contexts/Modal';
import { SettingsContextProvider } from './contexts/GameSettingsContext';
import 'react-tooltip/dist/react-tooltip.css'

const root = createRoot(document.getElementById('root'));
export const store = configureStore();

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ModalProvider> 
          <SettingsContextProvider>
            {/* <ThemeContextProvider> */}
              <SocketProvider>
                  <App />
              </SocketProvider>
            {/* </ThemeContextProvider> */}
          </SettingsContextProvider>
        </ModalProvider>
      </BrowserRouter>
    </Provider>
  )
}

const renderApplication = () => {
  root.render(
    <React.StrictMode>
      <Root />
    </React.StrictMode>
  )
}

renderApplication();
import styled from "styled-components";

// header, options, subheader, toggle, icon, button

export const Container = styled.div`
  font-family: "JetBrains Mono", monospace;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  padding: 16px;
  margin: 20px 20px;
  border: 3px solid black;
  background-color: white;
  border-radius: 16px;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5em;
  text-transform: uppercase;
  //   margin-bottom: 20px;
  // margin-right: 30px;
`;

export const Subheader = styled.h2`
  font-size: 1.3em;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const Button = styled.div`
  border: 2px solid black;
  box-shadow: 0px 3px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  text-transform: capitalize;
  padding: 0.2em 1em;
  cursor: pointer;
  white-space: pre-wrap;
  margin-top: auto;
  margin-bottom: 20px;
  background-color: #e89f9f;
  transition: 0.3s all ease;

  &:active {
    transform: translateY(3px);
    box-shadow: 0px 0px;
    background-color: #ed7d7d;
  }
`;

export const ExitIcon = styled.div`
  font-size: 2em;
  cursor: pointer;
`;

export const GameConfigOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 40px;
`;

export const GameConfigItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  max-width: 210px;
  min-width: 210px;
`;

export const ThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ThemeItem = styled.div`
  border-radius: 24px;
  border: 1px solid black;
  width: 32.5%;
  height: 125px;
`;

export const KeyboardShortcutsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #f7f6f6;
  border: 2px solid #f7f6f6;
  border-radius: 12px;
  padding: 20px;
  width: 80%;
`;

export const KeyboardShortcutsItemContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
`;

export const KeyboardShortcutsItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const KeyCap = styled.div`
  width: max-content;
  padding: 4px 12px;
  text-transform: uppercase;
  border-radius: 12px;
  background-color: #fbfbfb;
  border: 1.5px solid #9e9e9e;
  box-shadow: 0px 3px gray;
  font-size: 14px;
`;

export const ShortcutDescription = styled.p`
  font-size: 12px;
`;

export const Colors = {
	BLACK: '#121212',
	WHITE: '#FFF821',
	OFF_WHITE: '#F9F5F5',
	GRAY: '#ACACAC',
	TAUPE: '#F2F2E9', //light, soup
	YELLOW: '#FFC75A',
	GREEN: '#10BA0C',
	RED: '#F35555',

	GRAY_100: '#E4E7EB',
	GRAY_600: '#3B3B3B',
	GRAY_900: '#121212',

	// PLAYER COLORS
	PLAYER_RED: '#F2A1A1',
	PLAYER_PINK: '#E7B4F0',
	PLAYER_PURPLE: '#C6C6E8',
	PLAYER_BLUE: '#B4CFF0',
	PLAYER_GREEN: '#AFD2B5',
	PLAYER_YELLOW: '#F6DAA3',

}

export const FINAL_GAME_RESULTS_COLORS = {
    defeat: {
        "red-200": "#FF4848",
        "red-400": "#F17171",
        "red-500": "#AF2525",
        "red-700": "#9B3636",
        "red-900": "#400E0E",
    },
    victory: {
        "green-200": "#CFE4CB",
        "green-400": "#BCDAC2",
        "green-500": "#316139",
        "green-700": "#68A067",
        "green-900": "#0D3B15"
    },
    grays: {
        "gray-100": "#F2E9E3",
        "gray-200": "#C3B8B8"
    }
}
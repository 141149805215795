import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./generalComponents.css"
import styled from "styled-components";

const Logo = ({
    disabled=false
}) => {
    const history = useHistory();

    const handleClickHome = (e) => {
        e.preventDefault();
        if (!disabled) {
            history.push('/');
        }
    }
    return (
        <StyledLogo onClick={handleClickHome} disabled={disabled}>LETTERHEIST</StyledLogo>
    )
}
const StyledLogo = styled.h1`
    font-size: 3em;
    cursor: ${props => props.disabled ? "default" : "pointer"};
    transition: all .3s linear;

    @media (max-width: 1050px) {
        font-size: 2.2em;
    }
    @media (max-width: 520px) {
        font-size: 1.7em;
    }

    @media (max-height: 834px) {
        font-size: 2.2em;
    }
`

export default Logo;
import { useDispatch } from "react-redux"
import { logout } from "../../store/session";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TransparentButtonA } from "./StyledButtons";
import { LogOut } from "react-feather";

const LogoutButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogout = () => {
        // console.log("logging out")
        dispatch(logout())
            .then(() => history.push('/login'))
    }

    return (
        <TransparentButtonA onClick={handleLogout}>
            <LogOut />
            logout
        </TransparentButtonA>
    )
}

export default LogoutButton;
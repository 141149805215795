import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import RetroCardA from "../../Retro/RetroCardA";
import { PlayerStyles } from "../../../data/styleTypes";
import { getHasActiveGame, getPlayersInActiveGame, selectRoomPlayers } from "../../../store/room";
import { getCurrentUser } from "../../../store/session";
import {
  Container,
  Filler,
  ItemImageColorUnderlay,
  ItemImageContainer,
  PlayerName,
  ReadyStatus,
} from "./styles/StyledLobbyPlayerList";
import { useEffect } from "react";

const LOBBY_STATUS_DETAILS = {
  waiting: {
    color: "#FFDC83",
    text: "waiting",
  },
  inGame: {
    color: "#A2CCFD",
    text: "IN GAME",
  },
  notInLobby: {
    color: "#AAA493",
    text: "not in lobby",
  },
  ready: {
    color: "#ABD598",
    text: "READY",
  },
  notReady: {
    color: "#FFDC83",
    text: "not ready",
  },
};

export default function LobbyPlayerList() {
  const { players, currentUser, hasActiveGame, playersInActiveGame} = useSelector(
    createStructuredSelector({
      players: selectRoomPlayers,
      currentUser: getCurrentUser,
      hasActiveGame: getHasActiveGame,
      playersInActiveGame: getPlayersInActiveGame
      // lobbyPlayers: getLobbyPlayers
      // gamePlayers: getGamePlayers
    })
  );

  // useEffect(() => {console.log(lobbyPlayers)}, [lobbyPlayers]);

  const determineLobbyStatus = ({ inLobby, isReady, playerId, inActiveGame }) => {
    // console.log(playersInActiveGame)
    // console.log(playerId, inActiveGame)
    // console.log(hasActiveGame)
    if (hasActiveGame) {
      if (inLobby) {
        return "waiting";
      } else {
        // const gamePlayers = JSON.parse(
        //   sessionStorage.getItem("roomGameDetails")
        // )?.startingPlayers;
        // if (gamePlayers && playerId in gamePlayers) {
        if (inActiveGame) {
        // const gamePlayers = JSON.parse(sessionStorage.getItem("roomGameDetails"))?.startingPlayers
        // const gamePlayers = JSON.parse(localStorage.getItem("roomGameDetails"))?.startingPlayers

        // if (gamePlayers && playerId in gamePlayers) {
          return "inGame";
        } else {
          return "notInLobby";
        }
      }
    } else {
      if (inLobby) {
        if (isReady) {
          return "ready";
        } else {
          return "notReady";
        }
      } else {
        return "notInLobby";
      }
    }
  };

  return (
    <LobbyPlayerList.Container>
      {/* map through players */}
      {Object.values(players).map((player, index) => (
        <RetroCardA
          key={index}
          content={{
            image: <LobbyPlayerList.ItemImage style={player?.style} />,
            caption: (
              <LobbyPlayerList.ItemCaption>
                <LobbyPlayerList.PlayerLobbyStatus>
                  {
                    LOBBY_STATUS_DETAILS[
                      determineLobbyStatus({
                        inLobby: player?.inLobby,
                        isReady: player?.readyToPlay,
                        playerId: player.id,
                        inActiveGame: player.id in playersInActiveGame
                      })
                    ]?.text
                  }
                </LobbyPlayerList.PlayerLobbyStatus>
                <LobbyPlayerList.PlayerName>
                  {player?.displayName}{" "}
                  {currentUser?.id === player?.id && "(you)"}
                </LobbyPlayerList.PlayerName>
              </LobbyPlayerList.ItemCaption>
            ),
          }}
          styleOptions={{
            cardBackground:
              LOBBY_STATUS_DETAILS[
                determineLobbyStatus({
                  inLobby: player.inLobby,
                  isReady: player.readyToPlay,
                  playerId: player.id,
                  inActiveGame: player.id in playersInActiveGame
                })
              ]?.color,
          }}
        />
      ))}
      <LobbyPlayerList.EmptyPositions players={Object.values(players)} />
    </LobbyPlayerList.Container>
  );
}

LobbyPlayerList.Container = function LobbyPlayerListContainer({
  children,
  ...restProps
}) {
  return <Container {...restProps}>{children}</Container>;
};

LobbyPlayerList.ItemImage = function LobbyPlayerListItemImage({ style }) {
  return (
    <ItemImageContainer>
      <ItemImageColorUnderlay color={PlayerStyles[style]["color"]} />
      <div className="image-container">
        <img src={PlayerStyles[style]["icon"]} alt="avatar" />
      </div>
    </ItemImageContainer>
  );
};

LobbyPlayerList.ItemCaption = function ItemCaption({ children, ...restProps }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "6px",
      }}
    >
      {children}
    </div>
  );
};

LobbyPlayerList.PlayerName = function LobbyPlayerName({
  children,
  ...restProps
}) {
  return <PlayerName {...restProps}>{children}</PlayerName>;
};

/**
 * Player Lobby Status
 */

LobbyPlayerList.PlayerLobbyStatus = function PlayerLobbyStatus({ children }) {
  return <ReadyStatus>-{children}-</ReadyStatus>;
};

/**
 * Empty positions in Lobby List
 */

LobbyPlayerList.EmptyPositions = function LobbyEmptyPositions({ players }) {
  const fillEmptyPositions = () => {
    const numAvailableSpotsLeft = 6 - players.length;
    const fillerSpots = Array.apply(null, Array(numAvailableSpotsLeft));

    return (
      <>
        {fillerSpots.map((spot, index) => (
          <Filler key={index}></Filler>
        ))}
      </>
    );
  };

  return <>{fillEmptyPositions()}</>;
};

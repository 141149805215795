import { creatorsData } from "../../../../data/splashData";
import { useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { Linkedin, Mail, Globe } from "react-feather";
import { Tooltip } from "react-tooltip";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import "./index.css"

const CreatorsPanel = () => {
    

    return (
        <section id="creators-list">
            {Object.values(creatorsData).map((creator, index) => (
                <CreatorsPanel.Card creator={creator} key={index}/>
            ))}

        </section>
    )
}

CreatorsPanel.Card = function CreatorCard({creator}) {
    const [ flip, setFlip ] = useState(false);
    const componentRef = useRef();
    const { width, height } = useContainerDimensions(componentRef)

    return (
        <ReactCardFlip isFlipped={flip}
            flipDirection="horizontal">
                <div className="creators-list_list-item" ref={componentRef}>
                    <div className="creator-image">
                        <img src={creator.photo} alt={`${creator.name}'s portrait`} />
                    </div>
                    <h3 className="creator-name">{creator.name}</h3>
                    <h4 className="creator-role">{creator.role}</h4>
                    
                    <div className="social-links" >
                        <a href={creator.linkedIn} target="__blank" rel="noopener noreferrer" data-tooltip-id="my-tooltip" data-tooltip-content={"Linked In"} data-tooltip-place="bottom"><Linkedin /></a>
                        <a href={`mailto:${creator.email}`} target="_blank" rel="noopener noreferrer" data-tooltip-id="my-tooltip" data-tooltip-content={creator.email} data-tooltip-place="bottom"><Mail /></a>
                        {creator.personalWebsite && 
                            <a href={creator.personalWebsite} target="_blank" rel="noopener noreferrer" data-tooltip-id="my-tooltip" data-tooltip-content={"Personal Website"} data-tooltip-place="bottom"><Globe /></a>
                        }
                        <Tooltip id="my-tooltip" />
                    </div>
                    <p onClick={() => setFlip(!flip)} style={{ 
                        cursor: "pointer", 
                        paddingBottom: "12px", 
                        marginRight: "12px", 
                        marginLeft: "auto", 
                        fontSize: "0.875em",
                        color: "gray"
                    }}>
                        read more {`-->`}
                    </p>
                    
                </div>
                <div style={{width: width, height: height}} className="creators-list_list-item back" onClick={() => setFlip(!flip)}>
                    <div className="creator-summary">{creator.summary}</div>
                </div>
        </ReactCardFlip>

    )

}
export default CreatorsPanel;
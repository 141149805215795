import { useState } from "react"
import { 
    Container, 
    Content, 
    ContentContainer, 
    ContentHeader, 
    TabContainer, 
    TabItem } from "./StyledRetroNavigation";

const defaultStyleOptions = {
    "activeTabTextColor": "#68213C",
    "inactiveTabTextColor": "#FFC0D2",
    "activeTabBgColor": "#FFC0D2",
    "inactiveTabBgColor": "#DD7A9B",
    "contentBgColor": "#FFC0D2",
    "borderColor": "#68213C"
}

export default function RetroNavigation ({
    tabs, 
    styleOptions={...defaultStyleOptions}
}) {
    const [ activeTab, setActiveTab ] = useState(Object.keys(tabs)[0]);
    // console.log(tabs)
    return (
        <>
        <Container>
            <TabContainer>
                {Object.keys(tabs).map((tabName, i) => (
                    <TabItem
                        key={i}
                        onClick={() => setActiveTab(tabName)}
                        $activeTab={activeTab === tabName}
                        $borderColor={styleOptions["borderColor"]}
                        $inactiveTabBgColor={styleOptions["inactiveTabBgColor"]}
                        $inactiveTabTextColor={styleOptions["inactiveTabTextColor"]}
                        $activeTabBgColor={styleOptions["activeTabBgColor"]}
                        $activeTabTextColor={styleOptions["activeTabTextColor"]}
                    >
                        {tabName}
                    </TabItem>
                ))}
            </TabContainer>
            <RetroNavigation.Content 
                tabs={tabs}
                activeTab={activeTab}
                styleOptions={styleOptions}/>
        </Container>
        </>
    )
}


RetroNavigation.Content = function RetroNavContent ({tabs, activeTab, styleOptions}) {
    return (
        <>
        <ContentContainer
            borderColor={styleOptions["borderColor"]} 
            contentBgColor={styleOptions["contentBgColor"]}>
            <ContentHeader>
                {tabs[activeTab]["tabContentHeader"]}
            </ContentHeader>
            <Content>
                {tabs[activeTab]["tabContent"]}
            </Content>
        </ContentContainer>
        </>
    )
}



import styled from 'styled-components';
import { Colors } from '../../../../styles/Colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'JetBrains Mono', monospace;
    flex-grow: 1;
    // margin-bottom: 20px;
    height: 100%;
`

export const Left = styled.div`
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(100vh - 188px);
    @media (max-width: 1050px) {
        // max-height: calc(100vh - 84px);
        max-height: calc(100vh - 160px);
    }

    @media (max-height: 834px) {
        max-height: calc(100vh - 160px);
    }
`
export const Right = styled.div`
    width: 35%;
    border: 2px solid ${Colors.BLACK};
    border-radius: 24px;
    padding: 20px;
    // max-height: calc(100vh - 104px);
    max-height: calc(100vh - 188px);
    @media (max-width: 1050px) {
        // max-height: calc(100vh - 84px);
        max-height: calc(100vh - 160px);
    }
    @media (max-height: 834px) {
        max-height: calc(100vh - 160px);
    }
`
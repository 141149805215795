import styled from "styled-components";
import { Colors } from "../../../styles/Colors";

export const Form = styled.form`
    height: 40px;
    width: 100%;
    display: flex;
    gap: 5px;
`

export const Input = styled.input`
    border: 1px solid gray;
    border-radius: 999px;
    padding: 2px 15px;
    outline: none;
    display: flex;
    flex-grow: 1;
    
`

export const Button = styled.button`
    border: 1px solid gray;
    border-radius: 999px;
    padding: 2px 10px;

    @media (max-width: 950px) {
        display: none;
    }
`

export const MessageContainer = styled.div`
overflow-wrap: break-word;
    
    // p {
    //     display: inline-block;
    //     text-wrap: wrap;
    // }
`

export const PlayerName = styled.div`
    background-color: ${props => PlayerColors[props.color]};

    // color: ${props => TEXT_BORDER_COLORS[props.color]};
    color: ${props =>  TEXT_BORDER_COLORS[props.color]};
    border-radius: 999px;
    padding: 4px 12px;

    font-weight: 500;
    display: inline-block;
`

const PlayerColors = {
    'view-all': Colors.BLACK,
    'player-red': "#F2A1A1",
    'player-pink': "#E7B4F0",
    'player-purple': "#C6C6E8",
    'player-blue': "#B4CFF0",
    'player-green': "#AFD2B5",
    'player-orange': "#F1B269"
}

const TEXT_BORDER_COLORS = {
    'player-red': "#713636",
    'player-pink': "#57325D",
    'player-purple': "#421B50",
    'player-blue': "#113871",
    'player-green': "#37473A",
    'player-orange': "#6B3B04"
}


import { useSelector } from "react-redux";
import { useContext } from "react";
import { createStructuredSelector } from "reselect";

import { Container, FlexContainer, Item } from "./styles/StyledWordBank.js";
import { ToggleWordBankContext } from "./index.js";
import { selectStartingPlayers, selectWordsFormed } from "../../../store/game.js";
import { useKeyboardShortcut } from "../../../hooks/useKeyboardShortcut.js";

WordBank.Item = function WordBankItem({ children, isSpectator, ...restProps }) {
  return <Item $isSpectator={isSpectator} {...restProps}>{children}</Item>;
};

export default function WordBank({ steal, setSteal, isSpectator }) {
  const { players, words } = useSelector(createStructuredSelector({
    players: selectStartingPlayers,
    words: selectWordsFormed,
  }));
  const { wordBankFilter } = useContext(ToggleWordBankContext);

  useKeyboardShortcut(
    () => setSteal(""),
    ["Escape"]
  );


  const handleSelectSteal = (word) => {
    word === steal ? setSteal("") : setSteal(word);
    document.getElementById("word-entry-input-box").focus()
  };

  const color = (word) => {
    return players[`${word.owner_id}`]?.style;
  };

  const selected = (word) => {
    return steal.id === word.id;
  };

  const filterWordBank = () => {
    if (wordBankFilter?.displayName === "view all") {
      return (
        <>
          {Object.values(words).map((word, index) => (
              <WordBank.Item
                key={word.id}
                selected={selected(word)}
                onClick={() => handleSelectSteal(word)}
                color={color(word)}
                isSpectator={isSpectator}
              >
                {word.current}
              </WordBank.Item>
          ))}
        </>
      );
    } else {
      const selectedPlayer = wordBankFilter.id;
      const currentWordBank = Object.values(words).filter(
        (word) => word.owner_id === selectedPlayer
      );
      return (
        <>
          {currentWordBank.map((word, index) => (
            <WordBank.Item
              key={word.id}
              selected={selected(word)}
              onClick={() => handleSelectSteal(word)}
              color={color(word)}
              isSpectator={isSpectator}
            >
              {word.current}
            </WordBank.Item>
          ))}
        </>
      );
    }
  };

  return (
    <Container>
      <FlexContainer>
        {filterWordBank()}
      </FlexContainer>
    </Container>
  )
}

import { useSelector } from "react-redux";
import { socketLeaveRoom} from "../../lib/socket/socketConnection";
import { StyledLeaveRoomButton } from "./StyledButtons";
import { LogOut } from "react-feather";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getCurrentUser } from "../../store/session";
const LeaveRoomButton = () => {
    const { roomCode } = useParams();
    const currentUser = useSelector(getCurrentUser)

    const handleLeaveRoom = () => {
      socketLeaveRoom({
        room_code: roomCode,
        current_player: currentUser,
      });
    
    };

    return (
      <StyledLeaveRoomButton
        onClick={handleLeaveRoom}
      >
        <LogOut />
        leave room
      </StyledLeaveRoomButton>
    );
};

export default LeaveRoomButton;
